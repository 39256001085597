import { appConstants, betsSlipConstants, prematchConstants, betBuilderConstants } from '../actions/constants';
import { betsSlipPrematchEvalTicket, betsSlipComputeStakeTax, betsSlipComputeWinTax } from '../actions/betsSlip';
import {
  appEvaluateBonusRequest,
  appEvaluateWinnerFunRequest,
  appEvaluateFreeBetRequest,
  appEvaluateTournamentRequest,
} from '../actions/app';
import { produce } from 'immer';
import { getOutcomeValue, getBetBuilderOutcomeValue } from '../../utils/betUtils';
import { cloneDeep } from 'lodash-es';
import { uuidv4, debug, comb, measureDuration } from '../../utils';
import { TaxType } from '../../utils/taxes/defs';
import { calculateTax, calculateDigitainProgressiveBonusAmount } from '../../utils/taxes';
import { formatTotalOddsValue } from '../../utils/formatters';
//import { evaluateBonuses } from "../../utils/bonusEvaluation";
import { pushUnique } from '../../utils';
// import { prematchFetchMatches } from '../actions/prematch';
import { betBuilderStartUpdateBBF } from '../actions/betBuilder';

import { processBonusData, normalizeBets } from '../evaluate/data';
import { evaluateBonuses } from '../evaluate/bonus';
import { digitainBonusEvaluation } from '../evaluate/digitain';
import { evaluateFreeBets } from '../evaluate/free_bet';

import { fetchEventsWithMarkets } from '../../utils/eventsFetcher';

const stakeThresholds = [
  {
    min: 0,
    max: 30,
    increment: 5,
  },
  {
    min: 30,
    max: 100,
    increment: 10,
  },
  {
    min: 100,
    max: 10000,
    increment: 50,
  },
];

export const limits = {
  //betCount: 30,
  //systemBetCount: 30,
  betCount: parseFloat(window.config.betsBetCount ?? 30), // 30
  systemBetCount: parseFloat(window.config.betsSystemBetCount ?? 30), // 30
  minAmount: parseFloat(window.config.betsMinAmount ?? 2), // 2
  maxAmount: parseFloat(window.config.betsMaxAmount ?? 50000), // 50000
  maxPayout: parseFloat(window.config.betsMaxPayAmount ?? 150000), // 150000
  maxLines: parseFloat(window.config.betsMaxLines ?? 200000), // 150000
  //maxLines: 200000,
};

const TAX_PERCENT = 5;
const WP_EVENTS_NEEDED = 5;
const INITIAL_AMOUNT = parseFloat(window.config.betsInitialAmount ?? 10);

const initialTicketState = {
  amount: INITIAL_AMOUNT,
  stake: INITIAL_AMOUNT,
  realMoneyStake: INITIAL_AMOUNT,
  tax: 0,
  taxPercent: 0,
  stakeTaxCalculation: {},

  totalOdds: 0,
  totalOddsMin: 0,
  totalOddsMax: 0,
  totalMinWinAmount: 0,
  totalTaxedMinWinAmount: 0,
  totalMinWinTax: 0,
  totalMaxWinAmount: 0,
  totalTaxedMaxWinAmount: 0,
  totalMaxWinTax: 0,
  cOddsStr: '',
  totalLines: 0,
  lastError: '',
  systems: [],
  winTaxCalculation: {},

  ticketType: 'single',
  ticketOnline: true,
  ticketCode: '',

  winnerPlus: false,
  winnerPlusNeededEvents: 0,

  prematch: {
    totalOdds: 0,
    totalOddsMin: 0,
    totalOddsMax: 0,
    cOddsStr: '',
    totalLines: 0,
    minWinAmount: 0,
    maxWinAmount: 0,
    selected: [],
    lastError: '',
    systems: [],
    requestUuid: '',
  },

  live: {
    totalOdds: 0,
    totalOddsMin: 0,
    totalOddsMax: 0,
    cOddsStr: '',
    totalLines: 0,
    minWinAmount: 0,
    maxWinAmount: 0,
    selected: [],
    lastError: '',
    systems: [],
    requestUuid: '',
  },

  ticketCreateStatus: 'pending',

  autoAcceptOddChange: true,
  allowTicketPlaceWithInvalidEvents: false,
  errorMessage: '',
  errorDetails: [],
  placeTicketEnabled: false,
  placeTicketErrorMessage: '',

  liveTicketCreateSuccess: false,
  prematchTicketCreateSuccess: true,

  evalStr: '',
  bDataStr: '',
  bRequestId: '',

  wfDataStr: '',

  bonus: {
    ticketAppliedBonus: null,
    ticketBonusEligibles: [],
    digitainAppliedBonus: null,
  },

  bonusEvaluate: null,
  winnerFunEvaluate: null,
  useWinnerFunBoost: false,
  freeBetsEvaluate: null,

  tournamentEvaluateInProgress: false,
  tournamentEvaluate: null,

  // digitain multi bet of the day
  dayMultiBetNumber: 0,

  shareTicketNumber: null,
};

const initialState = {
  // we don't know what the initial page load will be
  isWinnerFun: null,

  loaded: false,
  prematchLoadRequestId: null,
  totalStake: 10,

  /*
  mType: "",
  selected: [],
  lastError: "",
  ticketType: "single",
  systems: [],
  */

  globalAutoAcceptOddChange: true,
  globalErrorMessage: '',
  globalPlaceTicketEnabled: false,

  currentTicket: 0,

  tickets: [
    {
      ...initialTicketState,
    },
    {
      ...initialTicketState,
    },
    {
      ...initialTicketState,
    },
    {
      ...initialTicketState,
    },
    {
      ...initialTicketState,
    },
  ],

  lottoTicket: null,

  liveTicketCreateSuccess: false,
  prematchTicketCreateSuccess: true,
  ticketCreateStatus: 'pending',

  copyStatus: {
    status: false,
    from: 0,
    to: 0,
  },

  selectError: 0,

  multiTicketEnabled: false, // switch to true to enable multiple tickets
  multiTicket: false, // if true will show the ticket switcher

  freeBets: [],
  selectedFreeBet: -1,
  selectedFreeBetSubIndex: -1,
  selectedFreeBetData: null,

  forceShowBetslip: false,
};

const round2 = (v) => {
  return Math.round((v + Number.EPSILON) * 100) / 100;
};

const getMatchBet = (m, idMb) => {
  if (idMb.indexOf(m.idMatch) === 0) {
    if (!m.matchBets) {
      return [m, null];
    }

    let bet = m.matchBets.find((mb) => mb.idMb === idMb);

    if (bet) {
      return [m, bet];
    }

    return [m, null];
  }

  if (!(m.periods && Array.isArray(m.periods))) {
    return [m, null];
  }

  for (const p of m.periods) {
    if (idMb.indexOf(p.idMatch) !== 0) {
      continue;
    }

    if (!p.matchBets) {
      return [p, null];
    }

    let bet = p.matchBets.find((mb) => mb.idMb === idMb);

    if (bet) {
      return [p, bet];
    }
  }

  return [m, null];
};

const saveBetslip = (draft) => {
  const td = cloneDeep(draft.tickets);

  td.forEach((t) => {
    t.prematch.selected.forEach((s) => {
      s.match = null;
    });

    t.live.selected.forEach((s) => {
      s.match = null;
    });
  });

  const jtd = JSON.stringify(td);

  let key = 'betSlip';
  if (draft.isWinnerFun) {
    key = 'wfBetSlip';
  }

  localStorage.setItem(key, jtd);
};

const loadBetslipEvents = (draft, action) => {
  let key = 'betSlip';
  if (draft.isWinnerFun) {
    key = 'wfBetSlip';
  }

  debug(`loading saved prematch matches for betslip ${key}`);

  const jtd = localStorage.getItem(key);

  if (jtd === null) return;

  const td = JSON.parse(jtd);

  // const matches = [];

  // td.forEach((t, i) => {
  //   t.prematch.selected.forEach((s) => {
  //     let needsLoad = false;

  //     if (!(s.idMatch in action.matches['prematch'])) {
  //       needsLoad = true;
  //     } else {
  //       if (!(action.matches['prematch'][s.idMatch] && action.matches['prematch'][s.idMatch]['_loaded'])) {
  //         needsLoad = true;
  //       }
  //     }

  //     if (needsLoad) {
  //       pushUnique(matches, s.idMatch);
  //     }
  //   });
  // });

  const matches = {};

  td.forEach((t, i) => {
    t.prematch.selected.forEach((s) => {
      let ev;
      if (s.idMatch in matches) {
        ev = matches[s.idMatch];
      } else {
        ev = {
          id: s.idMatch,
          mType: 'prematch',
          marketIds: [],
          periods: [],
        };
        matches[s.idMatch] = ev;
      }

      // maine event?
      if (s.idMb.indexOf(s.idMatch) === 0) {
        ev.marketIds.push(s.idBet);
      } else {
        // period event
        let pId = s.idMb.split('/')[0];
        let p = ev.periods.find((p) => p.id === pId);
        if (!p) {
          p = {
            id: pId,
            marketIds: [],
          };
          ev.periods.push(p);
        }
        p.marketIds.push(s.idBet);
      }
    });
  });

  const events = Object.values(matches);

  debug(`prematch matches for betslip ${key}`, events);

  if (events.length > 0) {
    draft.prematchLoadRequestId = 'betslip_' + uuidv4();
    // action.asyncDispatch(prematchFetchMatches(matches, draft.prematchLoadRequestId));
    fetchEventsWithMarkets({
      events,
      requestId: draft.prematchLoadRequestId,
    });
  }

  return events.length;
};

const loadBetslip = (draft, action) => {
  // check if we loaded the betslip
  if (draft.loaded) {
    return;
  }

  // only load it once
  draft.loaded = true;

  let key = 'betSlip';
  if (draft.isWinnerFun) {
    key = 'wfBetSlip';
  }

  const jtd = localStorage.getItem(key);

  if (jtd === null) return;

  const td = JSON.parse(jtd);

  //debug("saved bets", td);

  td.forEach((t, i) => {
    //debug("handle ticket", i, t);

    // setup ticket
    const ct = draft.tickets[i];

    ct.totalOdds = 0;
    ct.totalOddsMin = 0;
    ct.totalOddsMax = 0;

    ct.totalMinWinAmount = 0;
    ct.totalTaxedMinWinAmount = 0;
    ct.totalMinWinTax = 0;
    ct.totalMaxWinAmount = 0;
    ct.totalTaxedMaxWinAmount = 0;
    ct.totalMaxWinTax = 0;

    ct.cOddsStr = '';
    ct.totalLines = '';
    ct.lastError = '';
    ct.systems = [];

    ct.ticketCode = '';

    ct.winnerPlus = false;
    ct.winnerPlusNeededEvents = 0;

    ct.ticketCreateStatus = 'pending';

    ct.errorMessage = '';
    ct.errorDetails = [];
    ct.placeTicketEnabled = false;
    ct.placeTicketErrorMessage = '';

    ct.liveTicketCreateSuccess = false;
    ct.prematchTicketCreateSuccess = true;

    ct.evalStr = '';
    ct.wfDataStr = '';
    ct.bDataStr = '';

    ct.bonus = {
      ticketAppliedBonus: null,
      ticketBonusEligibles: [],
      digitainAppliedBonus: null,
    };

    ct.bonusEvaluate = null;
    ct.winnerFunEvaluate = null;
    ct.useWinnerFunBoost = false;

    ct.freeBetsEvaluate = null;

    ct.tournamentEvaluateInProgress = false;
    ct.tournamentEvaluate = null;

    ct.dayMultiBetNumber = 0;

    ct.shareTicketNumber = null;

    //debug("load prematch bets", t.prematch.selected.length);

    // load prematch saved bets
    t.prematch.selected.forEach((s) => {
      debug('loadBetslip: handle prematch bet', s);

      // check that the match still exists
      if (!(s.idMatch in action.matches['prematch'])) {
        // expired. skip it
        debug('loadBetslip: match expired');

        return;
      }

      let m = action.matches['prematch'][s.idMatch];
      let bet = null;

      // check that the bet still exists
      [m, bet] = getMatchBet(m, s.idMb);

      // bet no longer exists. skip it
      if (!bet) {
        debug('loadBetslip :bet expired');
        return;
      }

      // check if the odd still exists
      const odd = bet.mbOutcomes.find((o) => o.idMbo === s.idMbo);

      // odd no longer exists. skip it
      if (!odd) {
        debug('loadBetslip: odd expired');
        return;
      }

      // check if the match already exists on betslip
      let bf = ct.prematch.selected.find((b) => b.idMatch === s.idMatch);

      if (bf) {
        debug('prematch bet with this match already in betslip', s);
        return;
      }

      // at this point we have a valid bet. add it

      // set winner plus flag if needed
      if (bet && bet.winnerPlus) {
        ct.winnerPlus = true;
      }

      debug('loadBetslip: bet ok', ct.prematch.selected.length);

      // add bet
      ct.prematch.selected.push({
        idSport: s.idSport,
        idMatch: s.idMatch,
        idBet: s.idBet,
        idMb: s.idMb,
        idBo: s.idBo,
        idMbo: s.idMbo,
        match: cloneDeep(m),
        fixed: s.fixed,
        oddValueChanged: false,
        odd: getOutcomeValue(m, s),
        reofferedOdd: 0,
        reoffered: false,
      });
    });

    //debug("load live bets");

    // load live saved bets
    t.live.selected.forEach((s) => {
      // check that the match still exists
      if (!(s.idMatch in action.matches['live'])) {
        // expired. skip it
        return;
      }

      let m = action.matches['live'][s.idMatch];
      let bet = null;

      // check that the bet still exists
      [m, bet] = getMatchBet(m, s.idMb);

      // bet no longer exists. skip it
      if (!bet) return;

      // check if the odd still exists
      const odd = bet.mbOutcomes.find((o) => o.idMbo === s.idMbo);

      // odd no longer exists. skip it
      if (!odd) return;

      // check if the match already exists on betslip
      let bf = ct.live.selected.find((b) => b.idMatch === s.idMatch);

      if (bf) {
        debug('live bet with this match already in betslip', s);
        return;
      }

      // at this point we have a valid bet. add it

      // add bet
      ct.live.selected.push({
        idSport: s.idSport,
        idMatch: s.idMatch,
        idBet: s.idBet,
        idMb: s.idMb,
        idBo: s.idBo,
        idMbo: s.idMbo,
        match: cloneDeep(m),
        fixed: s.fixed,
        oddValueChanged: false,
        odd: getOutcomeValue(m, s),
        reofferedOdd: 0,
        reoffered: false,
      });
    });
  });

  let hasMultiTickets = 0;
  draft.tickets.forEach((t) => {
    if (t.live.selected.length + t.prematch.selected.length) {
      hasMultiTickets += 1;
    }
  });

  if (hasMultiTickets > 1) {
    draft.multiTicket = true;
  }
  // compute winning for the current ticket
  computeWinAmounts(draft, action);
};

const computeWinAmounts = (draft, action) => {
  //draft.tickets.forEach(t => computeTicketWinAmounts(t, action));
  //console.log("draft.currentTicket prematch", draft.tickets[draft.currentTicket].prematch.selected.length);
  //console.log("draft.currentTicket live", draft.tickets[draft.currentTicket].live.selected.length);
  if (
    draft.tickets[draft.currentTicket].prematch.selected.length +
      draft.tickets[draft.currentTicket].live.selected.length ===
    0
  ) {
    clearTicket(draft.tickets[draft.currentTicket]);
    return;
  }
  computeTicketWinAmounts(draft, draft.currentTicket, draft.tickets[draft.currentTicket], action);
};

const computeTicketWinAmounts = (draft, index, t, action) => {
  t.placeTicketEnabled = true;
  if (!action.dontResetError) {
    t.errorMessage = '';
  }
  t.placeTicketErrorMessage = '';

  if (t.amount === '') {
    t.totalMinWinAmount = 0;
    t.totalTaxedMinWinAmount = 0;
    t.totalMinWinTax = 0;
    t.totalMaxWinAmount = 0;
    t.totalTaxedMaxWinAmount = 0;
    t.totalMaxWinTax = 0;
    t.totalOdds = 0;
    t.totalOddsMin = 0;
    t.totalOddsMax = 0;
    t.placeTicketEnabled = false;

    return;
  }

  t.taxPercent = t.ticketOnline ? 0 : TAX_PERCENT;

  if (window.config.taxationEnabled === '1') {
    // for free bet we don't need to compute tax, all money is bonus
    if (draft.selectedFreeBet !== -1) {
      t.stake = t.amount;
      t.tax = 0;
      t.realMoneyStake = 0;
    }
    // check if they are using a bonus
    else if (t.bonusEvaluate && t.bonusEvaluate.data) {
      const er = t.bonusEvaluate.data;

      debug('bonus evaluate free money used', er);

      let amount = 0;

      if (er.free_money_used) {
        amount += parseFloat(er.free_money_used);
      }

      if (er.ring_fence && Array.isArray(er.ring_fence) && er.ring_fence.length > 0) {
        for (const r of er.ring_fence) {
          amount += parseFloat(r.balance_used);
        }
      }

      debug(`bonus evaluate free money used ${amount}`);

      amount = round2(amount);

      debug(`bonus evaluate free money used rounded ${amount}`);

      // compute tax locally if a tax code is provided
      if (window.config.taxCode) {
        const stakeTaxResults = calculateTax([
          {
            key: 'stake',
            taxType: TaxType.SPORT_STAKE,
            totalAmount: amount * 100,
          },
        ]);

        if (stakeTaxResults && stakeTaxResults.stake) {
          t.realMoneyStake = round2(amount - stakeTaxResults.stake.taxAmount / 100);
          t.stake = round2(t.amount - stakeTaxResults.stake.taxAmount / 100);
          t.tax = round2(stakeTaxResults.stake.taxAmount / 100);
        } else {
          console.error('failed to compute stake tax', amount);
        }

        debug('local stake tax results', amount, t.stake, t.tax);
      }
      // calculate stake tax using APIs
      else {
        debug('compute stake tax', t.stakeTaxCalculation, t.amount, amount);

        if (
          !(
            t.stakeTaxCalculation &&
            t.stakeTaxCalculation.totalAmount === t.amount &&
            t.stakeTaxCalculation.taxableAmount === amount
          )
        ) {
          if (!t.stakeTaxCalculation.inProgress) {
            debug(`compute stake tax for ${amount}`);

            t.stakeTaxCalculation.inProgress = true;
            action.asyncDispatch(betsSlipComputeStakeTax(t.amount, amount));
          } else {
            debug('stake tax request in progress');
          }
          return;
        } else {
          debug('stake tax already computed', amount, t.stake, t.tax);
        }
      }
    }
  } else {
    t.stake = t.amount;
  }

  /*
  t.tax = t.amount * (t.taxPercent / 100);
  t.tax = Math.round((t.tax + Number.EPSILON) * 100) / 100;
  t.stake = t.amount - t.tax;
  */

  // if (t.taxPercent === 0) {
  //   t.tax = 0;
  //   t.stake = t.amount;
  // } else {
  //   t.stake = round2(t.amount / (1 + t.taxPercent / 100));
  //   t.tax = round2(t.amount - t.stake);
  // }

  /*
  debug("t", JSON.parse(JSON.stringify(t)));
  debug("amount", t.amount, "tax", t.tax, "stake", t.stake);
  debug("betslip action", action, `evalstr = ${t.evalStr}`);
  */

  if (t.ticketType === 'single') {
    // computeSimpleWin(t, { ...action, mType: "live" });
    // computeSimpleWin(t, { ...action, mType: "prematch" });
    computeCombinedSimpleWin(t, action);
  } else {
    // computeSystemWin(t, { ...action, mType: "live" });
    // computeSystemWin(t, { ...action, mType: "prematch" });
    computeCombinedSystemWin(t, action);

    if (t.totalLines > limits.maxLines) {
      t.totalMinWinAmount = 0;
      t.totalTaxedMinWinAmount = 0;
      t.totalMinWinTax = 0;
      t.totalMaxWinAmount = 0;
      t.totalTaxedMaxWinAmount = 0;
      t.totalMaxWinTax = 0;
      t.totalOdds = 0;
      t.totalOddsMin = 0;
      t.totalOddsMax = 0;
      t.placeTicketEnabled = false;
      t.evalStr = '';

      // t("Maximum number of lines exceeded (200,000)")
      t.errorMessage = 'Maximum number of lines exceeded (200,000)';
      t.placeTicketEnabled = false;
      return;
    }
  }

  // let betType = "prematch";

  // if (t["prematch"].selected.length > 0) {
  if (t.ticketType === 'system' && t.systems.length === 0) {
    // t("You must select at least one system");
    t.placeTicketErrorMessage = 'You must select at least one system';
    t.placeTicketEnabled = false;

    t.totalMinWinAmount = 0;
    t.totalTaxedMinWinAmount = 0;
    t.totalMinWinTax = 0;
    t.totalMaxWinAmount = 0;
    t.totalTaxedMaxWinAmount = 0;
    t.totalMaxWinTax = 0;
    t.totalOdds = 0;
    t.totalOddsMin = 0;
    t.totalOddsMax = 0;

    t.evalStr = '';

    return;
  }

  // if (t.live.minWinAmount > 0 && t.prematch.minWinAmount > 0) {
  //   t.totalMinWinAmount = (t.live.minWinAmount * t.prematch.minWinAmount) / t.amount;
  // } else {
  //   t.totalMinWinAmount =
  //     (t.live.minWinAmount ? t.live.minWinAmount : 1) * (t.prematch.minWinAmount ? t.prematch.minWinAmount : 1);
  // }

  // if (t.live.maxWinAmount > 0 && t.prematch.maxWinAmount > 0) {
  //   t.totalMaxWinAmount = (t.live.maxWinAmount * t.prematch.maxWinAmount) / t.amount;
  // } else {
  //   t.totalMaxWinAmount =
  //     (t.live.maxWinAmount ? t.live.maxWinAmount : 1) * (t.prematch.maxWinAmount ? t.prematch.maxWinAmount : 1);
  // }

  let db = null;

  if (
    t.bonus &&
    t.bonus.digitainAppliedBonus &&
    t.bonus.digitainAppliedBonus.bonuses &&
    t.bonus.digitainAppliedBonus.bonuses.length > 0 &&
    t.ticketType === 'single'
  ) {
    db = t.bonus.digitainAppliedBonus.bonuses.find((b) => b.type === 'Express');
    if (db) {
      let factor = db.factor ?? 0;

      if (import.meta.env.MODE === 'development' || window.config.environment === 'staging') {
        if (window.customTaxSetup && window.customTaxSetup.digitainProgressiveFactor) {
          factor = window.customTaxSetup.digitainProgressiveFactor;
        }
      }

      const r = calculateDigitainProgressiveBonusAmount(t.totalMaxWinAmount, t.totalMinWinAmount, t.amount, factor);

      debug('progressive bonus results', r);

      db.maxAmount = round2(r.maxAmount);
      t.totalMaxWinAmount = round2(r.totalMaxWinAmount);
      db.minAmount = round2(r.minAmount);
      t.totalMinWinAmount = round2(r.totalMinWinAmount);
    }
  }

  if (t.totalMaxWinAmount > limits.maxPayout) {
    t.totalMaxWinAmount = limits.maxPayout;
  }

  if (t.totalMinWinAmount > limits.maxPayout) {
    t.totalMinWinAmount = limits.maxPayout;
  }

  if (window.config.taxationEnabled === '1') {
    // compute tax locally if a tax code is provided
    if (window.config.taxCode) {
      debug('compute win tax locally', t.amount, t.stake, t.tax, t.totalMaxWinAmount, t.totalMinWinAmount);

      if (t.totalMaxWinAmount > 0) {
        const stakeRatio = t.realMoneyStake / t.stake;

        let taxableMaxWinAmount = round2(t.totalMaxWinAmount * stakeRatio);
        if (taxableMaxWinAmount < 0) {
          taxableMaxWinAmount = 0;
        }

        let taxableMinWinAmount = round2(t.totalMinWinAmount * stakeRatio);
        if (taxableMinWinAmount < 0) {
          taxableMinWinAmount = 0;
        }

        const taxResults = calculateTax([
          {
            key: 'maxWin',
            taxType: TaxType.SPORT_WIN,
            totalAmount: taxableMaxWinAmount * 100,
            notTaxableAmount: t.realMoneyStake * 100,
          },
          {
            key: 'minWin',
            taxType: TaxType.SPORT_WIN,
            totalAmount: taxableMinWinAmount * 100,
            notTaxableAmount: t.realMoneyStake * 100,
          },
        ]);

        debug('local win tax results', taxResults);

        if (taxResults && taxResults.maxWin) {
          t.totalTaxedMaxWinAmount = round2(t.totalMaxWinAmount - taxResults.maxWin.taxAmount / 100);
          t.totalMaxWinTax = round2(taxResults.maxWin.taxAmount / 100);
        }

        if (taxResults && taxResults.minWin) {
          t.totalTaxedMinWinAmount = round2(t.totalMinWinAmount - taxResults.minWin.taxAmount / 100);
          t.totalMinWinTax = round2(taxResults.minWin.taxAmount / 100);
        }
      } else {
        t.totalTaxedMaxWinAmount = t.totalMaxWinAmount;
        t.totalMaxWinTax = 0;
        t.totalTaxedMinWinAmount = t.totalMinWinAmount;
        t.totalMinWinTax = 0;
      }
    }
    // calculate tax using APIs
    else if (t.stakeTaxCalculation && t.stakeTaxCalculation.taxableAmount && !t.stakeTaxCalculation.inProgress) {
      debug(
        'stake tax computed, need to compute win tax',
        t.amount,
        t.stake,
        t.tax,
        t.totalMaxWinAmount,
        t.totalMinWinAmount,
      );

      if (t.totalMaxWinAmount > 0) {
        const stakeRatio = t.realMoneyStake / t.amount;

        let taxableMaxWinAmount = round2(t.totalMaxWinAmount * stakeRatio);
        if (taxableMaxWinAmount < 0) {
          taxableMaxWinAmount = 0;
        }
        const notTaxableMaxWinAmount = round2(t.totalMaxWinAmount - taxableMaxWinAmount);

        let taxableMinWinAmount = round2(t.totalMinWinAmount * stakeRatio);
        if (taxableMinWinAmount < 0) {
          taxableMinWinAmount = 0;
        }
        const notTaxableMinWinAmount = round2(t.totalMinWinAmount - taxableMinWinAmount);

        debug(
          `compute win tax ${stakeRatio} ${t.totalMaxWinAmount} ~ ${taxableMaxWinAmount}, ${t.totalMinWinAmount} ~ ${taxableMinWinAmount}`,
        );

        if (
          t.winTaxCalculation.maxAmount !== taxableMaxWinAmount ||
          t.winTaxCalculation.minAmount !== taxableMinWinAmount
        ) {
          debug(
            `compute win tax ${t.winTaxCalculation.maxAmount} ~ ${taxableMaxWinAmount}, ${t.winTaxCalculation.minAmount} ~ ${taxableMinWinAmount}`,
          );

          if (!t.winTaxCalculation.inProgress) {
            debug('compute win tax', t.totalMaxWinAmount, t.totalMinWinAmount);

            t.winTaxCalculation = {
              inProgress: true,
              notTaxableMaxWinAmount,
              notTaxableMinWinAmount,
            };

            action.asyncDispatch(
              betsSlipComputeWinTax(taxableMaxWinAmount, t.realMoneyStake, taxableMinWinAmount, t.realMoneyStake),
            );
          } else {
            debug('win tax request in progress');
          }

          return;
        }
      } else {
        t.winTaxCalculation = {};
        t.totalTaxedMaxWinAmount = 0;
        t.totalMaxWinTax = 0;
        t.totalTaxedMinWinAmount = 0;
        t.totalMinWinTax = 0;
      }
    }
  } else {
    t.totalTaxedMaxWinAmount = t.totalMaxWinAmount;
    t.totalMaxWinTax = 0;
    t.totalTaxedMinWinAmount = t.totalMinWinAmount;
    t.totalMinWinTax = 0;
  }

  // t.totalOdds = (t.live.totalOdds ? t.live.totalOdds : 1) * (t.prematch.totalOdds ? t.prematch.totalOdds : 1);
  // t.totalOddsMin =
  //   (t.live.totalOddsMin ? t.live.totalOddsMin : 1) * (t.prematch.totalOddsMin ? t.prematch.totalOddsMin : 1);
  // t.totalOddsMax =
  //   (t.live.totalOddsMax ? t.live.totalOddsMax : 1) * (t.prematch.totalOddsMax ? t.prematch.totalOddsMax : 1);

  // action.asyncDispatch(betsSlipPrematchEvalTicket(index));
  // } else {
  // betType = "live";

  //   t.evalStr = "";

  //   /*
  //   if (t.ticketType === "single") {
  //     computeSimpleWin(t, { ...action, mType: "live" });
  //     computeSimpleWin(t, { ...action, mType: "prematch" });
  //   } else {
  //     computeSystemWin(t, { ...action, mType: "live" });
  //     computeSystemWin(t, { ...action, mType: "prematch" });
  //   }
  //   */

  //   t.totalMinWinAmount = t.live.minWinAmount + t.prematch.minWinAmount;
  //   t.totalMaxWinAmount = t.live.maxWinAmount + t.prematch.maxWinAmount;

  //   if (t.totalMaxWinAmount > limits.maxPayout) {
  //     t.totalMaxWinAmount = limits.maxPayout;
  //   }

  //   t.totalOdds = t.live.totalOdds + t.prematch.totalOdds;
  //   t.totalOddsMin = t.live.totalOddsMin + t.prematch.totalOddsMin;
  //   t.totalOddsMax = t.live.totalOddsMax + t.prematch.totalOddsMax;
  // }

  if (t.winnerPlus) {
    t.winnerPlusNeededEvents = 1 + WP_EVENTS_NEEDED - t['prematch'].selected.length;

    if (t.winnerPlusNeededEvents > 0) {
      t.placeTicketEnabled = false;
      /*
      t.placeTicketErrorMessage =
        "Mai este nevoie sa adaugi " +
        t.winnerPlusNeededEvents +
        " " +
        (t.winnerPlusNeededEvents > 1 ? "pariuri" : "pariu") +
        " pentru a putea plasa biletul";
      */

      // t("You must add more bets in order to place this ticket");
      t.placeTicketErrorMessage = 'You must add more bets in order to place this ticket';
    }
  }

  // if (t.placeTicketEnabled && t.ticketType === "system") {
  //   if (t["live"].selected.length > 0 && t["live"].systems.length === 0) {
  //     // t("You must select at least one system");
  //     t.placeTicketErrorMessage = "You must select at least one system";
  //     t.placeTicketEnabled = false;
  //   }
  // }

  if (t.placeTicketEnabled) {
    if (t.totalMaxWinAmount === 0) {
      t.placeTicketEnabled = false;
      t.bRequestId = '';
      t.bDataStr = '';
    } else {
      // if (betType === "live") {
      if (draft.isWinnerFun) {
        requestWinnerFunEvaluation(action, t);
      } else {
        measureDuration('requestBonusEvaluation', () => requestBonusEvaluation(action, t));
      }
      // }
    }
  }

  //debug("final t", JSON.parse(JSON.stringify(t)));
};

const requestWinnerFunEvaluation = (action, t, betType) => {
  if (window.config.winnerFunEnabled !== '1') {
    debug('winnerfun not enabled');
    return;
  }

  debug('requestWinnerFunEvaluation', betType, action, t);

  if (
    action.authentication &&
    action.authentication.auth_type !== 'user' &&
    action.authentication.auth_type !== 'token'
  ) {
    debug('not authenticated');
    return;
  }

  if (action.profile && action.profile.client_player_id === null) {
    debug('account data not present');
    return;
  }

  const tb = t[betType];

  const wfData = {
    product: betType === 'prematch' ? 'SportsbookSM' : 'LiveBetting',
    ticket: {
      betType,
      ticketType: t.ticketType === 'single' ? 'combo' : 'system',
      totalOdds: t.totalOdds,
      totalOddsMin: t.totalOddsMin,
      totalOddsMax: t.totalOddsMax,
      totalCombinations: t.totalLines,
      minWinAmount: t.totalMinWinAmount,
      maxWinAmount: t.totalMaxWinAmount,
      amount: t.amount,
      tax: t.tax,
      stake: t.stake,
      systems: [...t.systems],
      use_boost: t.useWinnerFunBoost,
    },
  };

  wfData.ticket.bets = [];
  wfData.ticket.bets = wfData.ticket.bets
    .concat(
      ['live', 'prematch'].map((mType) =>
        t[mType].selected.map((s) => ({
          mType,
          idSport: s.idSport,
          idMatch: s.idMatch,
          idBet: s.idBet,
          idMb: s.idMb,
          idBo: s.idBo,
          idMbo: s.idMbo,
          fixed: s.fixed,
          odd: s.odd,
        })),
      ),
    )
    .flat();

  let wfDataStr = JSON.stringify(wfData);
  if (action.wallet) {
    wfDataStr += JSON.stringify(action.wallet);
  }

  if (wfDataStr !== t.wfDataStr) {
    wfData.requestId = t.wfRequestId = uuidv4();
    t.wfDataStr = wfDataStr;

    debug('winner fun evaluate request', wfData);

    //t.bonusEvaluate = null;

    action.asyncDispatch(appEvaluateWinnerFunRequest(wfData));
  } else {
    //debug('nothing changed');
  }
};

const requestBonusEvaluation = (action, t, betTypex, force) => {
  if (window.config.useBonusEvaluation !== '1') {
    return;
  }

  debug('requestBonusEvaluation', betTypex, action, t, force);

  const bData = {
    selectedFreeBet: t.selectedFreeBet,
    product: 'sport', //betType === "prematch" ? "SportsbookSM" : "LiveBetting",
    ticket: {
      // betType,
      ticketType: t.ticketType === 'single' ? 'combo' : 'system',
      totalOdds: t.totalOdds,
      totalOddsMin: t.totalOddsMin,
      totalOddsMax: t.totalOddsMax,
      totalCombinations: t.totalLines,
      minWinAmount: t.totalMinWinAmount,
      maxWinAmount: t.totalMaxWinAmount,
      amount: t.amount,
      tax: t.tax,
      stake: t.stake,
      systems: [...t.systems],
      dayMultiBetNumber: t.dayMultiBetNumber,
    },
  };

  bData.ticket.bets = [];
  bData.ticket.bets = bData.ticket.bets
    .concat(
      ['live', 'prematch'].map((mType) =>
        t[mType].selected.map((s) => ({
          mType,
          betType: s.betType,
          idSport: s.idSport,
          idMatch: s.idMatch,
          idBet: s.idBet,
          idMb: s.idMb,
          idBo: s.idBo,
          idMbo: s.idMbo,
          fixed: s.fixed,
          odd: s.odd,
        })),
      ),
    )
    .flat();

  // let bDataStr = JSON.stringify(bData);
  let bDataStr = measureDuration('bDataStr', () => JSON.stringify(bData));
  if (action.wallet) {
    bDataStr += JSON.stringify(action.wallet);
  }

  const obData = structuredClone(bData);

  if (bDataStr !== t.bDataStr || force) {
    // bData.ticket.bets = normalizeBets(action.state, bData.ticket.bets);
    bData.ticket.bets = measureDuration('normalizeBets', () => normalizeBets(action.state, bData.ticket.bets));

    // const { authOk, data: betData } = processBonusData(action.state, bData);
    const { authOk, data: betData } = measureDuration('processBonusData', () => processBonusData(action.state, bData));

    t.bDataStr = bDataStr;

    if (authOk || (window.config && window.config.digitainBonusesForGuests === '1')) {
      // const digitainEvalResult = digitainBonusEvaluation(action.state, betData);
      const digitainEvalResult = measureDuration('digitainBonusEvaluation', () =>
        digitainBonusEvaluation(action.state, betData),
      );

      debug(
        'digitain bonus evaluate response',
        digitainEvalResult && digitainEvalResult.data ? digitainEvalResult.data : {},
        t.totalMaxWinAmount,
      );

      if (digitainEvalResult.success) {
        for (const b of digitainEvalResult.bonuses) {
          if (b.type === 'Express') {
            if (b.factor) {
              b.maxAmount = round2(t.totalMaxWinAmount * (b.factor - 1));
              b.minAmount = round2(t.totalMinWinAmount * (b.factor - 1));
            }
          }
        }
      }

      t.bonus.digitainAppliedBonus = digitainEvalResult;
    }

    if (authOk) {
      // const bonusEvalResult = evaluateBonuses(action.state, betData);
      const bonusEvalResult = measureDuration('evaluateBonuses', () => evaluateBonuses(action.state, betData));

      debug('bonus evaluate response', bonusEvalResult && bonusEvalResult.data ? bonusEvalResult.data : {});

      t.bonusEvaluate = bonusEvalResult;
    }

    if (authOk) {
      // const freeBetsEvalResult = evaluateFreeBets(action.state, betData);
      const freeBetsEvalResult = measureDuration('evaluateFreeBets', () => evaluateFreeBets(action.state, betData));

      debug(
        'free bets evaluate response',
        freeBetsEvalResult && freeBetsEvalResult.data ? freeBetsEvalResult.data : {},
      );

      t.freeBetsEvaluate = freeBetsEvalResult;
    }

    if (authOk && !t.tournamentEvaluateInProgress) {
      t.tournamentEvaluateInProgress = true;
      action.asyncDispatch(appEvaluateTournamentRequest(obData));
    }
  }

  return;

  // let bDataStr = JSON.stringify(bData);
  // if (action.wallet) {
  //   bDataStr += JSON.stringify(action.wallet);
  // }

  // if (
  //   action.authentication &&
  //   action.authentication.auth_type !== 'user' &&
  //   action.authentication.auth_type !== 'token'
  // ) {
  //   // allow bonus evaluation for guests - digitain progressive bonus
  //   if (window.config && window.config.digitainBonusesForGuests === '1') {
  //     if (bDataStr !== t.bDataStr || force) {
  //       bData.requestId = t.bRequestId = uuidv4();
  //       t.bDataStr = bDataStr;

  //       action.asyncDispatch(appEvaluateBonusRequest(JSON.parse(JSON.stringify(bData))));
  //     }
  //   }
  //   return;
  // }

  // if (action.profile && action.profile.client_player_id === null) {
  //   debug('account data not present');
  //   return;
  // }

  // if (bDataStr !== t.bDataStr || force) {
  //   bData.requestId = t.bRequestId = uuidv4();
  //   t.bDataStr = bDataStr;

  //   const dbData = JSON.parse(JSON.stringify(bData));

  //   debug('bonus evaluate request', bData, dbData);

  //   //t.bonusEvaluate = null;

  //   action.asyncDispatch(appEvaluateBonusRequest(JSON.parse(JSON.stringify(bData))));
  //   action.asyncDispatch(appEvaluateFreeBetRequest(JSON.parse(JSON.stringify(bData))));

  //   t.tournamentEvaluateInProgress = true;
  //   action.asyncDispatch(appEvaluateTournamentRequest(JSON.parse(JSON.stringify(bData))));
  // } else {
  //   //debug('nothing changed');
  // }
};

const computeSimpleWin = (t, action) => {
  const mType = action.mType;

  t[mType].minWinAmount =
    t[mType].maxWinAmount =
    t[mType].totalOdds =
    t[mType].totalOddsMin =
    t[mType].totalOddsMax =
    t[mType].totalLines =
      0;

  if (t[mType].selected.length === 0) {
    return;
  }

  // get all selected odds
  let odds = t[mType].selected.map((so, i) => {
    //console.log("so", { ...so });

    if (!(so.idMatch in action.matches[mType])) {
      if (so.valid) so.valid = false;
      return 1;
    }

    // get current odd value
    let cv;

    if (so.reoffered) {
      cv = so.reofferedOdd;
    } else {
      // get the match
      const m = action.matches[mType][so.idMatch];

      cv = getOutcomeValue(m, so);
    }

    //console.log("cv", cv);

    if (cv < 0) {
      if (so.valid) so.valid = false;
      return 1;
    }

    // get stored outcome value
    const bv = getOutcomeValue(so.match, so);

    //console.log("bv", bv);

    if (bv < 0) {
      if (so.valid) so.valid = false;
      return 1;
    }

    if (cv !== bv) {
      if (t.autoAcceptOddChange) {
        acceptOddChange(t, action, i);
        if (!so.valid) so.valid = true;
      } else {
        if (so.valid) so.valid = false;
        so.oddValueChanged = true;
        // t("You must accept the odd changes in order to continue");
        t.placeTicketErrorMessage = 'You must accept the odd changes in order to continue';
        t.placeTicketEnabled = false;
      }
    } else {
      if (!so.valid) so.valid = true;
    }

    return cv;
  });

  // filter odds with value 1
  const validOdds = odds.filter((o) => o !== 1);

  if (validOdds.length === 0 || (!t.allowTicketPlaceWithInvalidEvents && validOdds.length !== odds.length)) {
    // t("No valid odds found")
    //t.errorMessage = "No valid odds found";
    t.placeTicketEnabled = false;
  }

  let totalOdds = 0;

  if (odds.length > 0) {
    // multiply all odds
    totalOdds = odds.reduce((acc, v) => {
      return acc * v;
    }, 1);
  }

  // compute win amount
  const winAmount = totalOdds * t.stake;

  // set results
  t[mType].totalLines = 1;
  t[mType].totalOdds = totalOdds;
  t[mType].totalOddsMin = totalOdds;
  t[mType].totalOddsMax = totalOdds;
  t[mType].minWinAmount = t[mType].maxWinAmount = winAmount;
  t[mType].cOddsStr = '';
};

const computeCombinedSimpleWin = (t, action) => {
  t.totalMinWinAmount = t.totalMaxWinAmount = t.totalOdds = t.totalOddsMin = t.totalOddsMax = t.totalLines = 0;

  if (t['live'].selected.length === 0 && t['prematch'].selected.length === 0) {
    return;
  }

  // get all selected odds
  let odds = [];

  odds = odds
    .concat(
      ['live', 'prematch'].map((mType) =>
        t[mType].selected.map((so, i) => {
          //console.log("so", { ...so });

          if (!(so.idMatch in action.matches[mType])) {
            if (so.valid) so.valid = false;
            return 1;
          }

          // get current odd value
          let cv;

          if (so.reoffered) {
            cv = so.reofferedOdd;
          } else {
            // get the match
            const m = action.matches[mType][so.idMatch];

            if (so.betType === 'betBuilder') {
              cv = getBetBuilderOutcomeValue(m, so);
            } else {
              cv = getOutcomeValue(m, so);
            }
          }

          //console.log("cv", cv);

          if (typeof cv === 'number' && cv < 0) {
            if (so.valid) so.valid = false;
            return 1;
          } else {
            if (so.betType === 'betBuilder') {
              cv = so.odd;
            }
          }

          let bv = null;

          // get stored outcome value
          if (so.betType === 'betBuilder') {
            bv = getBetBuilderOutcomeValue(so.match, so);
          } else {
            bv = getOutcomeValue(so.match, so);
          }

          //console.log("bv", bv);

          if (typeof bv === 'number' && bv < 0) {
            if (so.valid) so.valid = false;
            return 1;
          } else {
            if (so.betType === 'betBuilder') {
              bv = so.prevOdd;
            }
          }

          if (cv !== bv) {
            if (t.autoAcceptOddChange) {
              acceptOddChange(t, { ...action, mType }, i);
              if (!so.valid) so.valid = true;
            } else {
              if (so.valid) so.valid = false;
              so.oddValueChanged = true;
              // t("You must accept the odd changes in order to continue");
              t.placeTicketErrorMessage = 'You must accept the odd changes in order to continue';
              t.placeTicketEnabled = false;
            }
          } else {
            if (!so.valid) so.valid = true;
          }

          return cv;
        }),
      ),
    )
    .flat();

  //console.log("odds", odds);

  // filter odds with value 1
  const validOdds = odds.filter((o) => o !== 1);

  if (validOdds.length === 0 || (!t.allowTicketPlaceWithInvalidEvents && validOdds.length !== odds.length)) {
    // t("No valid odds found")
    //t.errorMessage = "No valid odds found";
    t.placeTicketEnabled = false;
  }

  let totalOdds = 0;

  if (odds.length > 0) {
    // multiply all odds
    totalOdds = odds.reduce((acc, v) => {
      return acc * v;
    }, 1);
  }

  if (import.meta.env.MODE === 'development' || window.config.environment === 'staging') {
    if (window.customTaxSetup && window.customTaxSetup.totalOdds) {
      totalOdds = window.customTaxSetup.totalOdds;
    }
  }

  // compute win amount
  const winAmount = round2(totalOdds * t.stake);

  // set results
  t.totalLines = 1;
  t.totalOdds = totalOdds;
  t.totalOddsMin = totalOdds;
  t.totalOddsMax = totalOdds;
  t.totalMinWinAmount = t.totalMaxWinAmount = winAmount;
  t.cOddsStr = '';
};

const genComb = (n, k) => {
  const res = [];
  let cr = new Array(k);

  const fcomb = (len, pos) => {
    if (len === 0) {
      res.push([...cr]);
      return;
    }

    for (let i = pos; i <= n - len; i++) {
      cr[k - len] = i;
      fcomb(len - 1, i + 1);
    }
  };

  fcomb(k, 0);

  return res;
};

const calculateMinMaxOdds = (n, k, odds) => {
  debug('calculateTotalOdds: n', n, 'k', k, 'odds', odds);

  let minOdds = 100000;
  let cr = new Array(k);
  let maxOdds = 0;

  const fcomb = (len, pos) => {
    //console.log("cl len", len, "pos", pos);

    if (len === 0) {
      let s = 1;
      for (let i = 0; i < k; i++) s *= odds[cr[i]];
      maxOdds += s;

      //let smw = s * stp;

      //if (smw < mw) mw = smw;
      if (s < minOdds) minOdds = s;

      //console.log("calculate line odd", cr, s, tc);

      return;
    }

    for (let i = pos; i <= n - len; i++) {
      cr[k - len] = i;
      fcomb(len - 1, i + 1);
    }
  };

  fcomb(k, 0);

  return [minOdds, maxOdds];
};

const computeSystemWin = (t, action) => {
  const mType = action.mType;

  t.errorMessage = '';

  if (t[mType].systems.length === 0) {
    t[mType].totalOdds =
      t[mType].totalOddsMin =
      t[mType].totalOddsMax =
      t[mType].minWinAmount =
      t[mType].maxWinAmount =
      t[mType].totalLines =
        0;
    return;
  }

  // check selected odds
  let odds = t[mType].selected.map((so, i) => {
    //console.log("so", { ...so });

    if (!(so.idMatch in action.matches[mType])) {
      if (so.valid) so.valid = false;
      return 1;
    }

    // get current odd value
    let cv;

    if (so.reoffered) {
      cv = so.reofferedOdd;
    } else {
      // get the match
      const m = action.matches[mType][so.idMatch];

      cv = getOutcomeValue(m, so);
    }

    //console.log("cv", cv);

    if (cv < 0) {
      if (so.valid) so.valid = false;
      return 1;
    }

    // get stored outcome value
    const bv = getOutcomeValue(so.match, so);

    //console.log("bv", bv);

    if (bv < 0) {
      if (so.valid) so.valid = false;
      return 1;
    }

    if (cv !== bv) {
      if (t.autoAcceptOddChange) {
        acceptOddChange(t, action, i);
        if (!so.valid) so.valid = true;
      } else {
        if (so.valid) so.valid = false;
        so.oddValueChanged = true;
        t.placeTicketErrorMessage = 'You must accept the odd changes in order to continue';
        t.placeTicketEnabled = false;
      }
    } else {
      if (!so.valid) so.valid = true;
    }

    return cv;
  });

  // filter odds with value 1
  const validOdds = odds.filter((o) => o !== 1);

  if (validOdds.length === 0 || (!t.allowTicketPlaceWithInvalidEvents && validOdds.length !== odds.length)) {
    // t("No valid odds found")
    //t.errorMessage = "No valid odds found";
    t.placeTicketEnabled = false;
  }

  //console.log("t", JSON.parse(JSON.stringify(t[mType])));

  // compute odds of fixed bets
  const fixedOdds = t[mType].selected.reduce((to, s, i) => to * (s.fixed ? odds[i] : 1), 1);

  // generate an array with available odds
  let avOdds = [];
  t[mType].selected.forEach((s, i) => {
    if (!s.fixed) {
      avOdds.push(odds[i]);
    }
  });

  // check odds have changed
  let oddsStr = `${t[mType].systems.join(',')},${fixedOdds},${avOdds.join(',')}`;

  //console.log("t.cOddsStr", t[mType].cOddsStr, "oddsStr", oddsStr);

  if (t[mType].cOddsStr === oddsStr) {
    return;
  }

  t[mType].cOddsStr = oddsStr;

  // generate lines - we compute the resulting odd for each line
  const lines = [];
  const oddsPerSystem = [];
  let totalLines = 0;
  let totalOddsMin = 1000000;
  let totalOddsMax = 0;

  t[mType].systems.map((sy) => {
    const cc = parseFloat(comb(avOdds.length, sy));

    debug('sy', sy, 'cc', cc);

    lines.push(cc);
    totalLines += cc;
  });

  debug('lines = ', lines, 'totalLines = ', totalLines);

  if (totalLines > limits.maxLines) {
    t[mType].totalLines = totalLines;
    return;
  }

  const stakePerLine = t.stake / totalLines;

  t[mType].systems.map((sy) => {
    const [minOdds, maxOdds] = calculateMinMaxOdds(avOdds.length, sy, avOdds);

    if (minOdds < totalOddsMin) {
      totalOddsMin = minOdds;
    }

    debug('sy', sy, 'minOdds', formatTotalOddsValue(minOdds), 'maxOdds', formatTotalOddsValue(maxOdds));

    totalOddsMax += maxOdds;
  });

  totalOddsMin *= fixedOdds;
  totalOddsMax *= fixedOdds;

  const minWin = round2(totalOddsMin * fixedOdds * stakePerLine);
  const maxWin = round2(totalOddsMax * fixedOdds * stakePerLine);

  debug(
    'totalOddsMin',
    formatTotalOddsValue(totalOddsMin),
    'totalOddsMax',
    formatTotalOddsValue(totalOddsMax),
    'minWin',
    minWin,
    'maxWin',
    maxWin,
  );

  /*
  lines.forEach(l => {
    //totalOdds += l;
    const lw = l * stakePerLine;

    if (lw < minWin) minWin = lw;
    maxWin += lw;
  });
  */

  /*
  t[mType].systems.map(sy => {
    const cc = genComb(avOdds.length, sy);

    console.log("sy", sy, "cc", cc);

    cc.forEach(c => {
      let so = fixedOdds;
      c.forEach(oi => (so = so * avOdds[oi]));
      lines.push(so);
    });
  });

  console.log("lines = ", lines);

  const stakePerLine = t.stake / lines.length;

  let minWin = 1000000;
  let maxWin = 0;
  let totalOdds = 0;

  lines.forEach(l => {
    totalOdds += l;
    const lw = l * stakePerLine;

    if (lw < minWin) minWin = lw;
    maxWin += lw;
  });
  */

  //debug("totalOdds", totalOdds, "maxWin", maxWin, "minWin", minWin);

  t[mType].totalLines = totalLines;
  t[mType].totalOdds = totalOddsMax;
  t[mType].totalOddsMin = totalOddsMin;
  t[mType].totalOddsMax = totalOddsMax;
  t[mType].maxWinAmount = maxWin;
  t[mType].minWinAmount = minWin;
};

const computeCombinedSystemWin = (t, action) => {
  t.errorMessage = '';

  if (t.systems.length === 0) {
    t.cOddsStr = '';
    t.totalOdds = t.totalOddsMin = t.totalOddsMax = t.totalMinWinAmount = t.totalMaxWinAmount = t.totalLines = 0;
    return;
  }

  // check selected odds
  let odds = [];
  odds = odds
    .concat(
      ['live', 'prematch'].map((mType) =>
        t[mType].selected.map((so, i) => {
          //console.log("so", { ...so });

          if (!(so.idMatch in action.matches[mType])) {
            if (so.valid) so.valid = false;
            return 1;
          }

          // get current odd value
          let cv;

          if (so.reoffered) {
            cv = so.reofferedOdd;
          } else {
            // get the match
            const m = action.matches[mType][so.idMatch];

            if (so.betType === 'betBuilder') {
              cv = getBetBuilderOutcomeValue(m, so);
            } else {
              cv = getOutcomeValue(m, so);
            }
          }

          //console.log("cv", cv);

          if (typeof cv === 'number' && cv < 0) {
            if (so.valid) so.valid = false;
            return 1;
          } else {
            if (so.betType === 'betBuilder') {
              cv = so.odd;
            }
          }

          let bv = null;

          // get stored outcome value
          if (so.betType === 'betBuilder') {
            bv = getBetBuilderOutcomeValue(so.match, so);
          } else {
            bv = getOutcomeValue(so.match, so);
          }

          //console.log("bv", bv);

          if (typeof bv === 'number' && bv < 0) {
            if (so.valid) so.valid = false;
            return 1;
          } else {
            if (so.betType === 'betBuilder') {
              bv = so.prevOdd;
            }
          }

          if (cv !== bv) {
            if (t.autoAcceptOddChange) {
              acceptOddChange(t, { ...action, mType }, i);
              if (!so.valid) so.valid = true;
            } else {
              if (so.valid) so.valid = false;
              so.oddValueChanged = true;
              t.placeTicketErrorMessage = 'You must accept the odd changes in order to continue';
              t.placeTicketEnabled = false;
            }
          } else {
            if (!so.valid) so.valid = true;
          }

          return cv;
        }),
      ),
    )
    .flat();

  // console.log('sysem odds', odds);

  // filter odds with value 1
  const validOdds = odds.filter((o) => o !== 1);

  if (validOdds.length === 0 || (!t.allowTicketPlaceWithInvalidEvents && validOdds.length !== odds.length)) {
    // t("No valid odds found")
    //t.errorMessage = "No valid odds found";
    t.placeTicketEnabled = false;
  }

  //console.log("t", JSON.parse(JSON.stringify(t[mType])));

  // compute odds of fixed bets
  let fixedOdds = t['live'].selected.reduce((to, s, i) => to * (s.fixed ? odds[i] : 1), 1);
  fixedOdds = fixedOdds * t['prematch'].selected.reduce((to, s, i) => to * (s.fixed ? odds[i] : 1), 1);

  // generate an array with available odds
  let avOdds = odds;
  // ["live", "prematch"].forEach(mType =>
  //   t[mType].selected.forEach((s, i) => {
  //     if (!s.fixed) {
  //       avOdds.push(odds[i]);
  //     }
  //   })
  // );

  // check odds have changed
  let oddsStr = `${t.systems.join(',')},${fixedOdds},${avOdds.join(',')},${t.amount}`;

  // console.log('t.cOddsStr', t.cOddsStr, 'oddsStr', oddsStr);

  if (t.cOddsStr === oddsStr) {
    // console.log('odds not changed');
    return;
  }

  t.cOddsStr = oddsStr;

  // generate lines - we compute the resulting odd for each line
  const lines = [];
  const oddsPerSystem = [];
  let totalLines = 0;
  let totalOddsMin = 1000000;
  let totalOddsMax = 0;

  t.systems.forEach((sy) => {
    const cc = parseFloat(comb(avOdds.length, sy));

    debug('sy', sy, 'cc', cc);

    lines.push(cc);
    totalLines += cc;
  });

  debug('lines = ', lines, 'totalLines = ', totalLines);

  if (totalLines > limits.maxLines) {
    t.totalLines = totalLines;
    return;
  }

  const stakePerLine = t.stake / totalLines;

  t.systems.forEach((sy) => {
    const [minOdds, maxOdds] = calculateMinMaxOdds(avOdds.length, sy, avOdds);

    if (minOdds < totalOddsMin) {
      totalOddsMin = minOdds;
    }

    debug('sy', sy, 'minOdds', formatTotalOddsValue(minOdds), 'maxOdds', formatTotalOddsValue(maxOdds));

    totalOddsMax += maxOdds;
  });

  totalOddsMin *= fixedOdds;
  totalOddsMax *= fixedOdds;

  const minWin = round2(totalOddsMin * fixedOdds * stakePerLine);
  const maxWin = round2(totalOddsMax * fixedOdds * stakePerLine);

  debug(
    'totalOddsMin',
    formatTotalOddsValue(totalOddsMin),
    'totalOddsMax',
    formatTotalOddsValue(totalOddsMax),
    'minWin',
    minWin,
    'maxWin',
    maxWin,
  );

  /*
  lines.forEach(l => {
    //totalOdds += l;
    const lw = l * stakePerLine;

    if (lw < minWin) minWin = lw;
    maxWin += lw;
  });
  */

  /*
  t[mType].systems.map(sy => {
    const cc = genComb(avOdds.length, sy);

    console.log("sy", sy, "cc", cc);

    cc.forEach(c => {
      let so = fixedOdds;
      c.forEach(oi => (so = so * avOdds[oi]));
      lines.push(so);
    });
  });

  console.log("lines = ", lines);

  const stakePerLine = t.stake / lines.length;

  let minWin = 1000000;
  let maxWin = 0;
  let totalOdds = 0;

  lines.forEach(l => {
    totalOdds += l;
    const lw = l * stakePerLine;

    if (lw < minWin) minWin = lw;
    maxWin += lw;
  });
  */

  //debug("totalOdds", totalOdds, "maxWin", maxWin, "minWin", minWin);

  t.totalLines = totalLines;
  t.totalOdds = totalOddsMax;
  t.totalOddsMin = totalOddsMin;
  t.totalOddsMax = totalOddsMax;
  t.totalMaxWinAmount = maxWin;
  t.totalMinWinAmount = minWin;
};

const computeWin = (draft, action) => {
  if (draft[action.mType].selected.length > 0) {
    computeSimpleWin(draft, action);
  } else {
    draft[action.mType].selected = [];
    draft.totalOdds = 0;
    draft.totalOddsMin = 0;
    draft.totalOddsMax = 0;
    draft.winAmount = 0;
    draft.mType = '';
  }
};

const acceptOddChange = (t, action, idx) => {
  const mType = action.mType;

  const so = t[mType].selected[idx];

  if (so.betType === 'betBuilder') {
    so.prevOdd = so.odd;
    so.oddValueChanged = false;
    return so.odd;
  }

  if (!(so.idMatch in action.matches[mType])) {
    return 1;
  }

  let m = action.matches[mType][so.idMatch];
  let bet = null;

  // find match bet
  [m, bet] = getMatchBet(m, so.idMb);

  if (!bet) {
    //console.log("Failed to find bet", so.idMb, { ...so.match });
    return;
  }

  const mboIdx = bet.mbOutcomes.findIndex((mbo) => mbo.idMbo === so.idMbo);

  if (mboIdx === -1) {
    //console.log("Failed to find outcome", so.idMbo, { ...so.match });
    return;
  }

  let cv;

  // test if a reoffered odd was received
  if (so.reoffered) {
    cv = so.reofferedOdd;
  } else {
    // get current odd value
    cv = getOutcomeValue(m, so);
  }

  let tBet = t[mType].selected[idx].match.matchBets.find((mb) => mb.idMb === so.idMb);
  if (!tBet) {
    //console.log("Failed to find selected bet", so.idMb, { ...so.match });
    return;
  }

  tBet.mbOutcomes[mboIdx].mboOddValue = cv;
  t[mType].selected[idx].odd = cv;
  t[mType].selected[idx].oddValueChanged = false;
};

const checkBetCount = (ct) => {
  if (ct.ticketType === 'single') {
    if (ct.prematch.selected.length + ct.live.selected.length > limits.betCount - 1) {
      //ct.errorMessage = `Numarul maxim de pariuri este ${limits.betCount}`;
      // t("Maximum number of bets reached");
      ct.errorMessage = 'Maximum number of bets reached';
      return false;
    }
  } else {
    if (ct.prematch.selected.length + ct.live.selected.length > limits.systemBetCount - 1) {
      //ct.errorMessage = `Numarul maxim de pariuri este ${limits.systemBetCount}`;
      ct.errorMessage = 'Maximum number of bets reached';
      return false;
    }
  }

  return true;
};

const clearTicket = (t) => {
  t.amount = INITIAL_AMOUNT;
  t.stake = INITIAL_AMOUNT;
  t.realMoneyStake = INITIAL_AMOUNT;
  t.tax = 0;
  t.taxPercent = 0;
  t.ticketType = 'single';
  t.ticketOnline = true;
  t.ticketCode = '';
  t.stakeTaxCalculation = {};

  t.ticketCreateStatus = 'pending';
  t.liveTicketCreateSuccess = false;
  t.prematchTicketCreateSuccess = true;

  t.live.selected = [];
  t.live.systems = [];
  t.live.requestUuid = '';
  t.live.totalOdds = 0;
  t.live.totalOddsMin = 0;
  t.live.totalOddsMax = 0;
  t.live.cOddsStr = '';
  t.live.totalLines = 0;

  t.live.minWinAmount = 0;
  t.live.maxWinAmount = 0;

  t.prematch.selected = [];
  t.prematch.systems = [];
  t.prematch.requestUuid = '';
  t.prematch.totalOdds = 0;
  t.prematch.totalOddsMin = 0;
  t.prematch.totalOddsMax = 0;
  t.prematch.cOddsStr = '';
  t.prematch.totalLines = 0;

  t.prematch.minWinAmount = 0;
  t.prematch.maxWinAmount = 0;

  t.totalOdds = 0;
  t.totalOddsMin = 0;
  t.totalOddsMax = 0;
  t.totalMinWinAmount = 0;
  t.totalTaxedMinWinAmount = 0;
  t.totalMinWinTax = 0;
  t.totalMaxWinAmount = 0;
  t.totalTaxedMaxWinAmount = 0;
  t.totalMaxWinTax = 0;
  t.cOddsStr = '';
  t.totalLines = '';
  t.lastError = '';
  t.systems = [];
  t.winTaxCalculation = {};

  t.placeTicketEnabled = false;
  t.placeTicketErrorMessage = '';
  t.errorMessage = '';
  t.errorDetails = [];

  t.winnerPlus = false;
  t.winnerPlusNeededEvents = 0;

  /*
  draft.liveTicketCreateSuccess = false;
  draft.prematchTicketCreateSuccess = false;
  draft.ticketCreateStatus = "pending";
  */

  t.evalStr = '';
  t.wfDataStr = '';

  t.bonus = {
    ticketAppliedBonus: null,
    ticketBonusEligibles: [],
    digitainAppliedBonus: null,
  };

  t.bonusEvaluate = null;
  t.winnerFunEvaluate = null;
  t.useWinnerFunBoost = false;

  t.freeBetsEvaluate = null;

  t.tournamentEvaluateInProgress = false;
  t.tournamentEvaluate = null;

  t.dayMultiBetNumber = 0;

  t.shareTicketNumber = null;

  return t;
};

const betsSlipReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const mType = action.mType;

    //console.log("mType", mType, "action", action, "state", state);

    switch (action.type) {
      case betsSlipConstants.FORCE_SHOW_BETSLIP: {
        draft.forceShowBetslip = action.value;
        return;
      }
      case appConstants.SET_URL_BASE_PATH: {
        const oldValue = draft.isWinnerFun;
        let newValue = false;

        if (action.basePath === '/winner-fun') {
          draft.isWinnerFun = true;
          newValue = true;
        } else {
          draft.isWinnerFun = false;
        }

        if (oldValue !== null && oldValue !== newValue) {
          // clear betslip if we switch between sports and winner fun
          const t = draft.tickets[draft.currentTicket];
          clearTicket(t);

          let hasTickets = 0;

          draft.tickets.forEach((t) => {
            hasTickets += t.live.selected.length + t.prematch.selected.length;
          });

          if (hasTickets === 0) {
            draft.multiTicket = false;
            draft.currentTicket = 0;
            draft.selectedFreeBet = -1;
            draft.selectedFreeBetSubIndex = -1;
            draft.selectedFreeBetData = null;
          }

          saveBetslip(draft);
          draft.loaded = false;
        }
        break;
      }
      case betsSlipConstants.ADD_TICKET: {
        const ct = draft.tickets.length;
        draft.tickets.push(initialTicketState);
        draft.currentTicket = ct;
        return;
      }
      case betsSlipConstants.DELETE_TICKET: {
        draft.tickets.splice(action.ticketIndex, 1);
        if (draft.currentTicket > draft.tickets.length - 1) {
          draft.currentTicket = draft.tickets.length - 1;
        }
        return;
      }
      case betsSlipConstants.ADD:
        const t = draft.tickets[draft.currentTicket][mType];

        const idIdx = t.selected.findIndex((b) => b.idMbo === action.idMbo);
        if (idIdx === -1) {
          t.selected.push({
            valid: true,
            idSport: action.idSport,
            idMatch: action.idMatch,
            idBet: action.idBet,
            idMb: action.idMb,
            idBo: action.idBo,
            idMbo: action.idMbo,
            match: action.match,
            fixed: false,
            oddValueChanged: false,
            odd: getOutcomeValue(action.match, action),
          });
          computeWinAmounts(draft, action);
        }
        return;
      case betsSlipConstants.REMOVE: {
        const t = draft.tickets[draft.currentTicket][mType];
        const idIdx = t.selected.findIndex((b) => b.idMbo === action.idMbo);
        if (idIdx > -1) {
          const sb = t.selected[idIdx];
          const bet = sb.match.matchBets.find((mb) => mb.idMb === sb.idMb);

          if (bet && bet.winnerPlus) {
            draft.tickets[draft.currentTicket].winnerPlus = false;
          }

          t.selected.splice(idIdx, 1);
        }
        computeWinAmounts(draft, action);

        let hasTickets = 0;
        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.multiTicket = false;
          draft.currentTicket = 0;
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        saveBetslip(draft);
        return;
      }
      case betsSlipConstants.CLEAR_CREATE_STATUS: {
        const t = draft.tickets[draft.currentTicket];

        t.ticketCreateStatus = 'pending';
        t.errorDetails = [];
        t.liveTicketCreateSuccess = false;
        t.prematchTicketCreateSuccess = true;

        return;
      }
      case betsSlipConstants.CLEAR: {
        const t = draft.tickets[draft.currentTicket];
        clearTicket(t);

        let hasTickets = 0;

        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.multiTicket = false;
          draft.currentTicket = 0;
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        saveBetslip(draft);

        return;
      }

      case betsSlipConstants.TOGGLE: {
        debug('betslip toggle', action);

        if (draft.lottoTicket !== null) {
          // t("You can't combine lotto bets with sport bets");
          draft.tickets[draft.currentTicket].errorMessage = "You can't combine lotto bets with sport bets";
          return;
        }
        // if (action.match.mType === "live") {
        //   if (draft.tickets[draft.currentTicket]["prematch"].selected.length > 0) {
        //     draft.selectError++;
        //     return;
        //   }
        // } else {
        //   if (draft.tickets[draft.currentTicket]["live"].selected.length > 0) {
        //     draft.selectError++;
        //     return;
        //   }
        // }

        draft.selectError = 0;

        const ct = draft.tickets[draft.currentTicket];
        const t = draft.tickets[draft.currentTicket][mType];

        if (action.shareTicketNumber) {
          ct.shareTicketNumber = action.shareTicketNumber;
        }

        let oddMatch = action.match;
        let mbFound = false;

        for (const mb of action.match.matchBets) {
          if (mb.idMb === action.idMb) {
            mbFound = true;
            break;
          }
        }

        if (!mbFound) {
          // bet not found in main section. look in periods
          if (action.match.periods && Array.isArray(action.match.periods)) {
            for (const p of action.match.periods) {
              for (const mb of p.matchBets) {
                if (mb.idMb === action.idMb) {
                  oddMatch = p;
                  mbFound = true;
                  break;
                }
              }

              if (mbFound) {
                break;
              }
            }
          }
        }

        if (mType === 'live') {
          // checks for any bet in same match and removes it before proceeding
          // const midIdx = t.selected.findIndex(b => b.match.idMatch === oddMatch.idMatch && b.idMbo !== action.idMbo);
          const midIdx = t.selected.findIndex((b) => b.idMatch === action.idMatch && b.idMbo !== action.idMbo);
          if (midIdx !== -1) {
            const bet = oddMatch.matchBets.find((mb) => mb.idMb === t.selected[midIdx].idMb);

            if (bet && bet.winnerPlus) {
              ct.winnerPlus = false;
            }

            t.selected.splice(midIdx, 1);
          }
        } else {
          // placeholder definition for combineable markets API return data (get via store when available)
          // API return comes wrapped in object with single field "data": an array like the sample below
          /*
          const combineableMarkets = [
            {
              "marketId1": 6, // final
              "marketId2": 51 // total goluri
            },
            {
              "marketId1": 6,
              "marketId2": 68 // total goluri prima repriza
            },
            {
              "marketId1": 6,
              "marketId2": 71 // total goluri a doua repriza
            },
            {
              "marketId1": 68,
              "marketId2": 71
            },
            // note that no combination is allowed for total goals + goals in any particular round
          ];
          */

          const combineableMarkets = action.combiningAllowed;

          //debug("bet toggle", action);
          //debug("bSdebug t.selected init", JSON.parse(JSON.stringify(t.selected)));

          // new market-combinations routine START
          // checks for any bet in same match but only removes it if not found as a compatible market combination

          let loopDone = false;
          // must repeat until no incompatible bets remain, else only first incompatible bet is removed
          do {
            // TODO ASAP: find out why JUST looping breaks betSlip cash bet value !?!
            // find index of first remaining incompatible bet
            const midIdx = t.selected.findIndex((b) => {
              if (b.idMbo === action.idMbo) {
                return false;
              }

              let isIncompatible = b.idMbo !== action.idMbo;
              // console.log("bSdebug compat init - ", isIncompatible, b.idBet, action.idBet); // debug
              /*
              combineableMarkets.forEach(rule => {
                // note: API rules have integer values, relevant local data contains numbers stored as strings
                if (
                  rule.marketId1 + "" === b.idBet + "" &&
                  rule.marketId2 + "" === action.idBet + ""
                ) {
                  isIncompatible = false;
                }
                if (rule.marketId1 + "" === action.idBet && rule.marketId2 + "" === b.idBet + "") {
                  isIncompatible = false;
                }
                // console.log("bSdebug compat chk - ", isIncompatible, rule.marketId1, rule.marketId2, b.idBet, action.idBet); // debug
              });
              */

              if (b.idBet in combineableMarkets) {
                isIncompatible = !combineableMarkets[b.idBet].find((m) => m + '' === action.idBet);
              }

              if (action.idBet in combineableMarkets) {
                isIncompatible = !combineableMarkets[action.idBet].find((m) => m + '' === b.idBet);
              }

              // console.log("bSdebug compat final - ", isIncompatible, b.idBet, action.idBet); // debug
              // console.log(`bSdebug compat final - ${isIncompatible} ${b.idBet} ${action.idBet} ${b.idMatch} ${action.idMatch}`); // debug
              return b.idMatch === action.idMatch && isIncompatible;
            });

            // console.log("bSdebug midIdx", midIdx); // debug
            // remove first remaining incompatible bet
            if (midIdx !== -1) {
              const bet = oddMatch.matchBets.find((mb) => mb.idMb === t.selected[midIdx].idMb);

              if (bet && bet.winnerPlus) {
                ct.winnerPlus = false;
              }

              t.selected.splice(midIdx, 1);
            } else {
              loopDone = true;
            }
          } while (!loopDone); // TODO ASAP: find out why JUST looping breaks betSlip cash bet value !?!

          // new market-combinations routine END
        }

        const idIdx = t.selected.findIndex((b) => b.idMbo === action.idMbo);

        //debug("same bet index", idIdx, JSON.parse(JSON.stringify(t.selected)));

        if (idIdx === -1) {
          // check if an odd from the same bet was selected
          const bIdx = t.selected.findIndex((b) => b.idMb === action.idMb);

          // if yes, then de-select it and we'll let the new odd be selected
          if (bIdx !== -1) {
            t.selected.splice(bIdx, 1);
          }

          if (!checkBetCount(ct)) {
            return;
          }

          //console.log("check winner plus", mType);

          // check for Winner Plus match
          if (mType === 'prematch' && bIdx === -1) {
            const bet = oddMatch.matchBets.find((mb) => mb.idMb === action.idMb);

            if (bet && bet.winnerPlus) {
              //console.log("bet is winner plus");

              if (ct.winnerPlus) {
                //console.log("ticket is winner plus already");

                //ct.placeTicketEnabled = false;
                // t("A ticket can contain only one Winner Plus bet")
                ct.errorMessage = 'A ticket can contain only one Winner Plus bet';
                if (typeof window.config.nsoftTheme !== 'undefined' && window.config.nsoftTheme) {
                  ct.errorMessage = `A ticket can contain only one ${window.config.nsoftTheme} Plus bet`;
                }
                return;
              } else {
                //console.log("make ticket winenr plus");

                ct.winnerPlus = true;
              }
            }
          }

          const betSlipMatchData = {
            valid: true,
            idSport: action.idSport,
            idMatch: action.idMatch,
            idBet: action.idBet,
            idMb: action.idMb,
            idBo: action.idBo,
            idMbo: action.idMbo,
            match: oddMatch,
            fixed: false,
            oddValueChanged: false,
            odd: getOutcomeValue(oddMatch, action),
            reofferedOdd: 0,
            reoffered: false,
          };

          // only add recommandation id if it exists
          if (action.brmId !== undefined) {
            betSlipMatchData.brmId = action.brmId;
          }

          t.selected.push(betSlipMatchData);
        } else {
          const bet = oddMatch.matchBets.find((mb) => mb.idMb === t.selected[idIdx].idMb);

          if (bet && bet.winnerPlus) {
            ct.winnerPlus = false;
          }

          t.selected.splice(idIdx, 1);
        }

        ct.bDataStr = '';
        computeWinAmounts(draft, action);

        saveBetslip(draft);

        let hasTickets = 0;
        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        return;
      }

      case betsSlipConstants.STAKE_INC: {
        const ct = draft.tickets[draft.currentTicket];

        if (ct.amount === '') ct.amount = 0;

        if (action.stake !== -1) {
          ct.amount += action.stake;
        } else {
          // get stake threshold
          let st = stakeThresholds.find((st) => {
            if (ct.amount >= st.min && ct.amount < st.max) {
              return st;
            }

            return null;
          });

          if (!st) st = stakeThresholds[stakeThresholds.length - 1];

          // round up to the nearest increment
          if (ct.amount === 0) {
            ct.amount = st.increment;
          } else if (ct.amount % st.increment === 0) {
            ct.amount = (ct.amount / st.increment + 1) * st.increment;
          } else {
            ct.amount = Math.ceil(ct.amount / st.increment) * st.increment;
          }

          //ct.amount += action.stake;
        }

        if (ct.amount > limits.maxAmount) {
          ct.amount = limits.maxAmount;
        }

        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.STAKE_DEC: {
        const ct = draft.tickets[draft.currentTicket];

        if (ct.amount === '') ct.amount = 0;

        if (action.stake !== -1) {
          if (ct.amount - action.stake > 0) {
            ct.amount -= action.stake;
          } else {
            return;
          }
        } else {
          // get stake threshold
          let stIdx = stakeThresholds.findIndex((st) => {
            if (ct.amount >= st.min && ct.amount < st.max) {
              return st;
            }

            return false;
          });

          if (stIdx === -1) stIdx = 0;
          if (stIdx > 0) stIdx--;

          const st = stakeThresholds[stIdx];

          // round down to the nearest increment
          if (ct.amount === 0) {
            ct.amount = st.increment;
          } else if (ct.amount % st.increment === 0) {
            ct.amount = (ct.amount / st.increment - 1) * st.increment;
          } else {
            ct.amount = Math.floor(ct.amount / st.increment) * st.increment;
          }

          if (ct.amount <= 0) {
            ct.amount = st.increment;
          }
        }

        if (ct.amount < limits.minAmount) {
          ct.amount = limits.minAmount;
        }

        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.STAKE_SET: {
        const ct = draft.tickets[draft.currentTicket];

        //console.log("set stake", action.stake);

        if (action.stake === '') {
          ct.amount = 0;
        } else {
          let stake = parseFloat(action.stake);
          if (isNaN(stake)) {
            stake = 0;
          }

          if (stake >= 0) {
            ct.amount = stake;

            // if (ct.amount < limits.minAmount) {
            //   ct.amount = limits.minAmount;
            // } else
            if (ct.amount > limits.maxAmount) {
              ct.amount = limits.maxAmount;
            }
          }
        }

        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.COMPUTE_WIN: {
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.TOGGLE_FIXED: {
        const t = draft.tickets[draft.currentTicket][mType];

        const idIdx = t.selected.findIndex((b) => b.idMbo === action.idMbo);
        if (idIdx !== -1) {
          if ('fixed' in t.selected[idIdx]) {
            t.selected[idIdx].fixed = !t.selected[idIdx].fixed;
          } else {
            t.selected[idIdx].fixed = true;
          }
        }
        t.systems = [];
        return;
      }
      case betsSlipConstants.ACCEPT_ODD_CHANGE: {
        const t = draft.tickets[draft.currentTicket];
        const idIdx = t[mType].selected.findIndex((b) => b.idMbo === action.idMbo);
        if (idIdx !== -1) {
          acceptOddChange(t, action, idIdx);
          computeWinAmounts(draft, action);
        }
        return;
      }
      case betsSlipConstants.TOGGLE_AUTO_ACCEPT_ODD_CHANGE: {
        const t = draft.tickets[draft.currentTicket];
        t.autoAcceptOddChange = !t.autoAcceptOddChange;
        t.bDataStr = '';
        t.wfDataStr = '';
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.TOGGLE_ALLOW_TICKET_PLACE_WITH_INVALID_EVENTS: {
        const t = draft.tickets[draft.currentTicket];
        t.allowTicketPlaceWithInvalidEvents = !t.allowTicketPlaceWithInvalidEvents;
        t.bDataStr = '';
        t.wfDataStr = '';
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.TOGGLE_WINNER_FUN_BOOST: {
        const t = draft.tickets[draft.currentTicket];
        t.useWinnerFunBoost = !t.useWinnerFunBoost;

        if (typeof action.force !== 'undefined') {
          t.useWinnerFunBoost = action.force;
        }

        const pn = t.prematch.selected.length;
        const ln = t.live.selected.length;

        if (pn === 0 && ln === 0) {
          return;
        }

        const betType = pn > 0 ? 'prematch' : 'live';

        requestWinnerFunEvaluation(action, t, betType);

        return;
      }
      case betsSlipConstants.TOGGLE_SYSTEM: {
        let t;

        if (mType && mType !== 'none') {
          t = draft.tickets[draft.currentTicket][mType];
        } else {
          t = draft.tickets[draft.currentTicket];
        }

        const idIdx = t.systems.findIndex((s) => s === action.system);
        if (action.provider === 'digitain') {
          // digitain only supports one system / ticket
          if (idIdx === -1) {
            t.systems = [action.system];
          } else {
            t.systems = [];
          }
        } else {
          if (idIdx === -1) {
            t.systems.push(action.system);
          } else {
            t.systems.splice(idIdx, 1);
          }
        }
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.SET_TICKET_TYPE: {
        const ct = draft.tickets[draft.currentTicket];
        ct.ticketType = action.ticketType;

        if (!checkBetCount(ct)) {
          return;
        }

        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.PREMATCH_CREATE_TICKET_SUCCESS: {
        //console.log("prematch ticket success", action);
        const t = draft.tickets[draft.currentTicket];
        t.prematchTicketCreateSuccess = true;
        if (t.liveTicketCreateSuccess) {
          t.ticketCreateStatus = 'done';
        }
        if (action.code) {
          t.ticketCode = action.code;
        }
        return;
      }
      case betsSlipConstants.PREMATCH_CREATE_TICKET_REOFFER: {
        //console.log("prematch ticket reoffer", action.data);

        // get ticket
        const ct = draft.tickets[draft.currentTicket];

        // set reoffer status and message
        ct.ticketCreateStatus = 'reoffered';
        ct.ticketMessage = action.data.reOfferedMessage;

        // set new ticket amount
        ct.amount = action.data.reOfferedTicket.paymentAmount;

        // go through every bet and see if the odd changed
        const t = draft.tickets[draft.currentTicket]['prematch'];

        action.data.reOfferedTicket.ticketBets.forEach((tb) => {
          const sb = t.selected.find((b) => b.idMbo === '' + tb.eventMarketOutcomeId);

          if (sb) {
            if (sb.odd !== tb.odd) {
              sb.oddValueChanged = true;
            }

            sb.reofferedOdd = tb.odd;
            sb.reoffered = true;
          }
        });

        computeWinAmounts(draft, action);

        return;
      }
      case betsSlipConstants.LIVE_CREATE_TICKET_PENDING: {
        //console.log("live ticket pending", action.index, action.requestUuid);
        const t = draft.tickets[action.index];

        t.live.requestUuid = action.requestUuid;
        t.live.ticketCreateStatus = 'pending';

        return;
      }
      case betsSlipConstants.LIVE_CREATE_TICKET_SETTLE: {
        //console.log("ticket settle data", action.data);
        return;
      }
      case betsSlipConstants.LIVE_CREATE_TICKET_SUCCESS: {
        //console.log("live ticket success", draft.prematchTicketCreateSuccess);
        const t = draft.tickets[draft.currentTicket];
        t.liveTicketCreateSuccess = true;
        if (t.prematchTicketCreateSuccess) {
          //console.log("done");
          t.ticketCreateStatus = 'done';
        }
        if (action.code) {
          t.ticketCode = action.code;
        }
        return;
      }
      case betsSlipConstants.LIVE_CREATE_TICKET_ERROR:
      case betsSlipConstants.PREMATCH_CREATE_TICKET_ERROR: {
        const t = draft.tickets[draft.currentTicket];
        t.ticketCreateStatus = action.error;
        t.errorDetails = action.details || [];
        return;
      }
      case betsSlipConstants.SET_TICKET_ONLINE: {
        //console.log("ticket online", action.online);
        draft.tickets[draft.currentTicket].ticketOnline = action.online;
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.LOTTO_ADD_TICKET: {
        const t = draft.tickets[draft.currentTicket];

        if (t.live.selected.length || t.prematch.selected.length) {
          // t("You can't combine sport bets with lotto bets");
          t.errorMessage = "You can't combine sport bets with lotto bets";
          return;
        }
        draft.lottoTicket = action.ticket;
        return;
      }
      case betsSlipConstants.LOTTO_CLEAR_TICKET: {
        draft.lottoTicket = null;
        return;
      }
      case betsSlipConstants.PREMATCH_EVAL_TICKET_STR: {
        const t = draft.tickets[action.index];
        t.evalStr = action.evalStr;
        return;
      }
      case betsSlipConstants.PREMATCH_EVAL_TICKET_DATA: {
        debug('eval data', action.data, action.index);

        const t = draft.tickets[action.index];
        const dt = action.data ? action.data : {};

        if (!t) return;

        if ('winningAmountMin' in dt) {
          t.totalMinWinAmount = t.live.minWinAmount + action.data.winningAmountMin;
        }

        if ('winningAmountMax' in dt) {
          t.totalMaxWinAmount = t.live.maxWinAmount + action.data.winningAmountMax;
        }

        if (t.totalMinWinAmount > limits.maxPayout) {
          t.totalMinWinAmount = limits.maxPayout;
        }

        if (t.totalMaxWinAmount > limits.maxPayout) {
          t.totalMaxWinAmount = limits.maxPayout;
        }

        if ('oddMax' in dt) {
          t.totalOdds = t.live.totalOdds + action.data.oddMax;
          t.totalOddsMax = t.live.totalOddsMax + action.data.oddMax;
        }

        if ('oddMin' in dt) {
          t.totalOddsMin = t.live.totalOddsMin + action.data.oddMin;
        }

        if ('ticketAppliedBonus' in dt) {
          t.bonus.ticketAppliedBonus = action.data.ticketAppliedBonus;
        } else {
          t.bonus.ticketAppliedBonus = null;
        }

        if ('ticketBonusEligibles' in dt) {
          t.bonus.ticketBonusEligibles = action.data.ticketBonusEligibles;
        } else {
          t.bonus.ticketBonusEligibles = [];
        }

        if (draft.isWinnerFun) {
          requestWinnerFunEvaluation(action, t, 'prematch');
        } else {
          requestBonusEvaluation(action, t, 'prematch');
        }

        if (t.totalMaxWinAmount > 0) {
          t.placeTicketEnabled = true;
        }

        return;
      }
      case betsSlipConstants.REQUEST_BONUS_EVALUATION: {
        const t = draft.tickets[draft.currentTicket];

        const pn = t.prematch.selected.length;
        const ln = t.live.selected.length;

        if (pn === 0 && ln === 0) {
          return;
        }

        t.selectedFreeBet = draft.selectedFreeBet;

        const betType = pn > 0 ? 'prematch' : 'live';

        if (draft.isWinnerFun) {
          requestWinnerFunEvaluation(action, t, betType);
        } else {
          requestBonusEvaluation(action, t, betType, action.force);
        }

        return;
      }
      case betsSlipConstants.SET_CURRENT_TICKET: {
        draft.currentTicket = action.index;
        computeWinAmounts(draft, action);
        return;
      }
      case betsSlipConstants.COPY_CURRENT_TICKET: {
        const t = draft.tickets[draft.currentTicket];

        let copied = -1;

        if (t.live.selected.length !== 0 || t.prematch.selected.length !== 0) {
          for (let i = 0; i < draft.tickets.length; i++) {
            if (i !== draft.currentTicket) {
              if (draft.tickets[i].live.selected.length === 0 && draft.tickets[i].prematch.selected.length === 0) {
                const copy = { ...t };
                draft.tickets[i] = copy;
                copied = i;
                break;
              }
            }
          }
        }

        if (copied > -1) {
          draft.multiTicket = true;
          draft.copyStatus = {
            status: true,
            from: draft.currentTicket + 1,
            to: copied + 1,
          };
          if (action.switchToTab) {
            draft.currentTicket = copied;
          }
        }

        return;
      }
      case betsSlipConstants.CLEAR_COPY_STATUS: {
        draft.copyStatus = {
          status: false,
          from: 0,
          to: 0,
        };
        return;
      }
      case betsSlipConstants.LOAD: {
        debug(`load betslip, is winnerFun ${draft.isWinnerFun}`, action);

        // if (action.stateReady) {
        if (draft.prematchLoadRequestId === null) {
          const nm = loadBetslipEvents(draft, action);

          if (nm === 0) {
            loadBetslip(draft, action);
          } else {
            debug(`waiting for ${nm} matches to load with request ${draft.prematchLoadRequestId}`);
          }
        } else {
          loadBetslip(draft, action);
        }
        // }

        return;
      }
      case betsSlipConstants.BONUS_EVALUATE_RESPONSE: {
        debug(
          'bonus evaluate response',
          action.bonusEvaluate && action.bonusEvaluate.data ? action.bonusEvaluate.data : {},
        );
        draft.tickets[draft.currentTicket].bonusEvaluate = action.bonusEvaluate;
        return;
      }
      case betsSlipConstants.WINNER_FUN_EVALUATE_RESPONSE: {
        debug(
          'winner fun evaluate response',
          action.winnerFunEvaluate && action.winnerFunEvaluate.data ? action.winnerFunEvaluate.data : {},
        );
        draft.tickets[draft.currentTicket].winnerFunEvaluate = action.winnerFunEvaluate;
        return;
      }
      case betsSlipConstants.FREE_BET_EVALUATE_RESPONSE: {
        debug(
          'free bet evaluate response',
          action.freeBetsEvaluate && action.freeBetsEvaluate.data ? action.freeBetsEvaluate.data : {},
        );
        draft.tickets[draft.currentTicket].freeBetsEvaluate = action.freeBetsEvaluate;
        return;
      }
      case betsSlipConstants.TOURNAMENT_EVALUATE_REQUEST: {
        debug(
          'tournament evaluate response',
          action.tournamentEvaluate && action.tournamentEvaluate.data ? action.tournamentEvaluate.data : {},
        );
        draft.tickets[draft.currentTicket].tournamentEvaluateInProgress = false;
        draft.tickets[draft.currentTicket].tournamentEvaluate = action.tournamentEvaluate;
        return;
      }
      case betsSlipConstants.FREE_BET_SELECTED_INDEX: {
        draft.selectedFreeBet = action.index;
        draft.selectedFreeBetSubIndex = action.subIndex;
        draft.selectedFreeBetData = action.freeBet;
        break;
      }
      case betsSlipConstants.PREMATCH_MATCHES_LOADED: {
        debug(`betslip set matches ${action.idRequest}`);

        if (draft.prematchLoadRequestId !== null && draft.prematchLoadRequestId === action.idRequest) {
          debug('matched load request, load betslip');
          loadBetslip(draft, action);
          draft.prematchLoadRequestId = null;
        }

        break;
      }
      case betsSlipConstants.DIGITAIN_BONUS_EVALUATE_RESPONSE: {
        const ct = draft.tickets[draft.currentTicket];

        debug(
          'digitain bonus evaluate response',
          action.bonusEvaluate && action.bonusEvaluate.data ? action.bonusEvaluate.data : {},
          ct.totalMaxWinAmount,
        );
        const be = action.bonusEvaluate;

        if (be.success) {
          for (const b of be.bonuses) {
            if (b.type === 'Express') {
              if (b.factor) {
                b.maxAmount = round2(ct.totalMaxWinAmount * (b.factor - 1));
                b.minAmount = round2(ct.totalMinWinAmount * (b.factor - 1));
              }
            }
          }
        }

        ct.bonus.digitainAppliedBonus = action.bonusEvaluate;
        break;
      }
      case betsSlipConstants.DIGITAIN_LOAD_MULTI_BET_DAY: {
        const ct = draft.tickets[draft.currentTicket];
        clearTicket(ct);

        const t = draft.tickets[draft.currentTicket]['prematch'];

        if (action.dmb) {
          for (const s of action.dmb.stakes) {
            t.selected.push({
              valid: true,
              idSport: s.match.idSport,
              idMatch: s.match.idMatch,
              idBet: s.stake.idBet,
              idMb: s.stake.idMb,
              idBo: s.stake.idBo,
              idMbo: s.stake.idMbo,
              match: s.match,
              fixed: false,
              oddValueChanged: false,
              odd: getOutcomeValue(s.match, s.stake),
              reofferedOdd: 0,
              reoffered: false,
            });
          }

          ct.dayMultiBetNumber = action.dmb.number;
        }

        ct.bDataStr = '';
        computeWinAmounts(draft, action);

        let hasTickets = 0;

        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.multiTicket = false;
          draft.currentTicket = 0;
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        break;
      }
      case betsSlipConstants.DIGITAIN_SET_MULTI_BET_DAY_NUMBER: {
        const ct = draft.tickets[draft.currentTicket];
        ct.dayMultiBetNumber = action.dmbNumber;
        break;
      }
      case betsSlipConstants.DIGITAIN_SET_BET_BUILDER_BETS: {
        debug('DIGITAIN_SET_BET_BUILDER_BETS', action);

        const ct = draft.tickets[draft.currentTicket];
        const t = ct[mType];
        const m = action.match;

        // check if a bet already exists with the same match Id
        const matchIdx = t.selected.findIndex((b) => b.idMatch === action.idMatch);

        // if found, remove it
        if (matchIdx > -1) {
          t.selected.splice(matchIdx, 1);
        }

        t.selected.push({
          valid: true,
          idSport: m.idSport,
          idMatch: m.idMatch,
          idBet: m.idMatch + 'betBuilder',
          idMb: m.idMatch + 'betBuilder',
          idBo: m.idMatch + 'betBuilder',
          idMbo: m.idMatch + 'betBuilder', // needs to be unique so we can use the value when deleting this odd from betslip
          betType: 'betBuilder',
          bb: {
            bets: action.bets,
            info: action.bbInfo,
            mustUpdate: false,
            updating: false,
          },
          match: m,
          fixed: false,
          oddValueChanged: false,
          odd: action.bbInfo.bbf,
          prevOdd: action.bbInfo.bbf,
          reofferedOdd: 0,
          reoffered: false,
        });

        ct.bDataStr = '';
        computeWinAmounts(draft, action);

        saveBetslip(draft);

        let hasTickets = 0;
        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        break;
      }
      case betBuilderConstants.MUST_UPDATE_BBF: {
        debug('MUST_UPDATE_BBF', action);

        const ct = draft.tickets[draft.currentTicket];
        const t = ct[mType];

        const b = t.selected.find((b) => b.betType === 'betBuilder' && b.idMatch === action.idMatch);
        if (b) {
          if (b.bb.updating) {
            // b.bb.mustUpdate = true;
          } else {
            b.bb.updating = true;
            const stakes = b.bb.bets.map((b) => b.idMbo);
            action.asyncDispatch(betBuilderStartUpdateBBF(mType, action.idMatch, stakes));
            return;
          }
        } else {
          debug('MUST_UPDATE_BBF bet not found', action);
        }

        break;
      }
      case betBuilderConstants.UPDATE_BBF: {
        debug('UPDATE_BBF', action);

        const ct = draft.tickets[draft.currentTicket];
        const t = ct[mType];
        const m = action.match;

        const b = t.selected.find((b) => b.betType === 'betBuilder' && b.idMatch === action.idMatch);
        if (b) {
          b.bb.updating = false;
          b.match = m;
        }

        if (b && action.bbInfo) {
          b.bb.info = action.bbInfo;
          b.odd = action.bbInfo.bbf;
        }

        if (b) {
          if (b.bb.mustUpdate) {
            debug('UPDATE_BBF must update', action);
            b.bb.mustUpdate = false;
            b.bb.updating = true;
            const stakes = b.bb.bets.map((b) => b.idMbo);
            action.asyncDispatch(betBuilderStartUpdateBBF(mType, action.idMatch, stakes));
            return;
          }
        } else {
          debug('UPDATE_BBF bet not found', action);
          return;
        }

        ct.bDataStr = '';
        computeWinAmounts(draft, action);

        saveBetslip(draft);

        let hasTickets = 0;
        draft.tickets.forEach((t) => {
          hasTickets += t.live.selected.length + t.prematch.selected.length;
        });

        if (hasTickets === 0) {
          draft.selectedFreeBet = -1;
          draft.selectedFreeBetSubIndex = -1;
          draft.selectedFreeBetData = null;
        }

        break;
      }
      case betsSlipConstants.SET_STAKE_TAX: {
        const ct = draft.tickets[draft.currentTicket];
        ct.stakeTaxCalculation = {
          totalAmount: action.totalAmount,
          taxableAmount: action.taxableAmount,
          inProgress: false,
        };
        ct.realMoneyStake = action.taxableAmount;
        ct.stake = ct.amount - action.stakeTax;
        ct.tax = action.stakeTax;

        computeWinAmounts(draft, action);

        break;
      }
      case betsSlipConstants.SET_WIN_TAX: {
        const ct = draft.tickets[draft.currentTicket];

        ct.totalTaxedMaxWinAmount = ct.winTaxCalculation.notTaxableMaxWinAmount + action.taxedMaxAmount;
        ct.totalMaxWinTax = action.maxTax;
        ct.totalTaxedMinWinAmount = ct.winTaxCalculation.notTaxableMinWinAmount + action.taxedMinAmount;
        ct.totalMinWinTax = action.minTax;

        debug(
          `SET_WIN_TAX max = ${ct.totalMaxWinAmount}, taxed = ${ct.totalTaxedMaxWinAmount}, tax = ${ct.totalMaxWinTax}, min = ${ct.totalMinWinAmount}, taxed = ${ct.totalTaxedMinWinAmount}, tax = ${ct.totalMinWinTax}`,
        );

        ct.winTaxCalculation = {
          maxAmount: action.maxAmount,
          minAmount: action.minAmount,
          inProgress: false,
        };

        computeWinAmounts(draft, action);

        break;
      }
      default:
        return;
    }
  });

export default betsSlipReducer;
