import React from 'react';
import styled from 'styled-components';
import { uniqueId, isEqual } from 'lodash-es';

import { useAppSelector } from '../../store';
import { DataElementContext } from '../common/DataElementContext';
import { getStateValue } from '../utils/getStateValue';
import triggerStateLoaderIfNeeded from '../utils/hooks/triggerStateLoaderIfNeeded';

interface StateElementProps {
  className: string;
  $styleText: string;
  style?: any;
  properties?: {
    dsType: string;
  };
  children?: JSX.Element;
}

const StateElementDiv = styled.div<StateElementProps>((props) => props.$styleText);

export const StateElement = (componentProps: any) => {
  const [__id] = React.useState(uniqueId('unique-'));
  const dataElementTypes = useAppSelector((state) => state.templatesConfig.dataElementTypes);
  const dsType = componentProps.properties?.dsType ?? '';
  const det = dsType ? dataElementTypes[dsType] : null;

  let stateKey = '';
  if (det && det.internal && det.internal.length) {
    const from = det.internal.find((el) => el.id === 'from');
    const path = det.internal.find((el) => el.id === 'path');
    const shouldLoad = det.internal.find((el) => el.id === 'shouldLoad');
    if (from && from.value === 'state') {
      if (path && path.value) stateKey = path.value;
    }

    if (shouldLoad && shouldLoad.value) {
      if (!stateKey) return null;
      triggerStateLoaderIfNeeded(stateKey);
    }
  }

  const stateItemValue = useAppSelector((state) => getStateValue(state, stateKey), isEqual);

  const contextValue = React.useMemo(() => {
    return {
      ...stateItemValue,
      __id,
    };
  }, [componentProps, stateItemValue]);

  if (!stateKey && !stateItemValue) return null;

  return (
    <StateElementDiv
      className={componentProps.className ?? ''}
      $styleText={componentProps.styleText}
      style={componentProps.style}
    >
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </StateElementDiv>
  );
};
