import React, { useState, createContext, useMemo, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store';
import styled from 'styled-components';
import { fetchSources } from '@/store/slices/dataSources';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { updateHallOfFame } from '@/store/slices/playerStats';
import { on } from 'events';
import { set } from 'lodash';

// Create the context
export const HallOfFameContext = createContext(null);

type ModuleStateProps = {
  isModalOpen: boolean;
};

type TemplateProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

// Provider component
export const HallOfFame = (componentProps: TemplateProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();

  const fetchedSources = React.useRef(false);
  
  const dsItems = useAppSelector((state) => state.dataSources.items);
  React.useEffect(() => {
    if (dsItems) {
      if (!dsItems.achievements && !fetchedSources.current) {
        dispatch(fetchSources({ ids: ['achievements'] }));
        fetchedSources.current = true;
      }
    }
  }, [dispatch, dsItems]);

  // Initial badges from Redux or create empty array with empty objects
  const initialSelectedBadges = useAppSelector(
    (state) => state.playerStats.data?.hall_of_fame?.selectedBadges
  ) || [{}, {}, {}];

  const initialState = {
    isModalOpen: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);
  
  // Local state for badge selections
  const [selectedBadges, setSelectedBadges] = useState(initialSelectedBadges);
  const [savedSelectedBadges, setSavedSelectedBadges] = useState(initialSelectedBadges);
  const [currentSlotIndex, setCurrentSlotIndex] = useState(0);

  
  // Function to check if a badge ID is in the selectedBadges array
  const isBadgeInSelection = useCallback((badgeId: number | string) => {
    if (!badgeId) return false;
    
    // Convert to number if it's a string
    const id = typeof badgeId === 'string' ? parseInt(badgeId, 10) : badgeId;
    
    // Check if this ID exists in any of the selected badges
    return selectedBadges.some(badge => Number(badge.id) === id);
  }, [selectedBadges]);
  
  // Find the slot index where a badge is selected
  const findBadgeSlotIndex = useCallback((badgeId: number | string) => {
    if (!badgeId) return -1;
    
    // Convert to number if it's a string
    const id = typeof badgeId === 'string' ? parseInt(badgeId, 10) : badgeId;
    
    // Return the index of the slot containing this badge ID, or -1 if not found
    return selectedBadges.findIndex(badge => {
      // console.log('HallOfFame[findBadgeSlotIndex]', id, badge);
      if(!badge) return false;
      return Number(badge.id) == id;
    });
  }, [selectedBadges]);
  
  // Select or deselect badge based on click
  const selectBadge = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    const badgeId = parseInt(event.target.dataset.id);
    
    if (!isNaN(badgeId)) {
      
        // Check if this badge is already selected in ANY slot
        const existingBadgeSlot = findBadgeSlotIndex(badgeId);
        const isAlreadySelected = existingBadgeSlot != -1;
        const allBadges = dsItems.achievements?.data || [];
        const badgeObj = allBadges.find((badge: { id: number }) => badge.id == badgeId);

        setSelectedBadges(prev => {
          const newBadges = [...prev];
          
          if (isAlreadySelected) {
            newBadges[existingBadgeSlot] = {};
          } else {
            newBadges[currentSlotIndex] = badgeObj || {}; 
          }
          
          return newBadges;
        });
        
        if (!isAlreadySelected) {
          const nextEmptySlotIndex = selectedBadges.findIndex((badge, index) => 
            index != currentSlotIndex && !badge?.id
          );
          
          if (nextEmptySlotIndex !== -1) {
            setCurrentSlotIndex(nextEmptySlotIndex);
          }
        }
    }
  }, [currentSlotIndex, selectedBadges, findBadgeSlotIndex, dsItems.achievements]);

  const onToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
      setState((v) => ({
        ...v,
        isModalOpen: !v.isModalOpen,
      }));
    };

    const onClose = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
        setState((v) => ({
          ...v,
          isModalOpen: false,
        }));
      };
  

  const saveSelection = useCallback(() => {
    dispatch(updateHallOfFame({ 
      selectedBadges,
    }));
    setSavedSelectedBadges(selectedBadges);
    setState((v) => ({
      ...v,
      isModalOpen: false,
    }));

  }, [dispatch, selectedBadges]);
  
  // Memoized context value
  const contextValue = useMemo(() => {
    
    return {
      // Badge data
      isModalOpen: state.isModalOpen,
      savedSelectedBadges,
      selectedBadges,
      allBadges: dsItems.achievements,
      
      // Actions
      selectBadge,
      saveSelection,
      
      // Helper methods
      isBadgeSelected: isBadgeInSelection,
      onToggle,
      onClose,
    };
  }, [state, dsItems.achievements, savedSelectedBadges, selectedBadges, selectBadge, saveSelection, isBadgeInSelection]);


  // console.log('HallOfFame[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default HallOfFame;

