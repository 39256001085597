import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { gsap, TimelineMax, TweenMax, Power3, Power2, Power0, Linear } from 'gsap/all';

gsap.registerPlugin(TweenMax, TimelineMax, Power2, Power3, Power0, Linear);

const DURATION = 1000;
const DEBUG = false;

const removeClass = (el, name) => {
  var elClass = el.className;
  while (elClass.indexOf(name) !== -1) {
    elClass = elClass.replace(name, '');
    elClass = elClass.trim();
  }
  el.className = elClass;
};

const addClass = (el, name) => {
  var elClass = el.className;
  if (elClass.indexOf(name) === -1) {
    el.className += ' ' + name;
  }
};

const getDigitCount = (...args) => {
  var i, max, value, values, _i, _len;
  values = 1 <= args.length ? [].slice.call(args, 0) : [];
  for (i = _i = 0, _len = values.length; _i < _len; i = ++_i) {
    value = values[i];
    values[i] = Math.abs(value);
  }
  max = Math.max.apply(Math, values);
  return Math.ceil(Math.log(max + 1) / Math.log(10));
};

const truncate = (val) => {
  if (val < 0) {
    return Math.ceil(val);
  } else {
    return Math.floor(val);
  }
};

const Div = styled.div((props) => props.$styleText);

const Counter = (props) => {
  const { digits, fractionalDigits, value, styles } = props;

  const [currentValue, setCurrentValue] = useState(null);
  const digitRefs = useRef([...new Array(20)].map(() => React.createRef()));

  DEBUG && console.log('render', 'value', value, 'digitRefs', digitRefs);

  let valueStr = (currentValue ?? 0)?.toString().length - 2; // used for adding dots in the counter for every 3 digits

  useEffect(() => {
    const doResize = () => {
      setCurrentValue(null);
    };

    window.addEventListener('resize', doResize, false);

    return () => {
      window.removeEventListener('resize', doResize, false);
    };
  }, []);

  useEffect(() => {
    const newValue = parseInt(Math.round(parseFloat(value) * Math.pow(10, fractionalDigits), 10));

    let cv = currentValue;
    let duration = DURATION;

    if (cv === null) {
      cv = 0;
      duration = 0;
      DEBUG && console.log('mount', 'value', value, 'new value', newValue, 'cv', cv);
    }

    DEBUG && console.log('new value', newValue, 'cv', cv);

    if (newValue === cv && currentValue !== null) {
      DEBUG && console.log('no animation');
      return;
    }

    const nd = getDigitCount(cv, newValue);

    let nnd = nd;

    if (cv > newValue) {
      nnd = getDigitCount(0, newValue);
    }

    const dd = nnd < fractionalDigits + 1 ? fractionalDigits + 1 : nnd;

    for (let i = 0; i < digits + fractionalDigits; i++) {
      if (i < digits + fractionalDigits - dd) {
        addClass(digitRefs.current[i].current, 'disabled');
      } else {
        removeClass(digitRefs.current[i].current, 'disabled');
      }
    }

    DEBUG && console.log('currentValue', cv, 'newValue', newValue, 'nd', nd, '');

    DEBUG && console.log('digitRefs', digitRefs);

    const dw0 = digitRefs.current[0].current;
    if (dw0 === null) return;
    const dwfh = (dw0.offsetHeight / 11) * 10;
    const dwh = dwfh / 10;

    DEBUG && console.log('dwfh', dwfh, 'dwh', dwh);

    const gtl = gsap.timeline({
      paused: true,
      autoRemoveChildren: true,
    });

    DEBUG &&
      gtl.eventCallback('onComplete', function () {
        DEBUG && console.log('gtl complete');
      });

    for (let i = 0; i < nd; i++) {
      const tl = gsap.timeline({
        autoRemoveChildren: true,
      });

      DEBUG &&
        tl.eventCallback('onComplete', function () {
          DEBUG && console.log('tl complete');
        });

      const start = truncate(cv / Math.pow(10, nd - i - 1));
      const end = truncate(newValue / Math.pow(10, nd - i - 1));

      const diff = end - start;

      let de = end % 10;
      if (diff > 0 && de === 0) {
        de = 10;
      }
      const ds = start % 10;
      let cs = Math.floor(diff / 10);

      if (cs > 10) {
        cs = 1 + Math.floor(cs / 10);
      }

      const rdn = digits + fractionalDigits - nd + i;

      DEBUG && console.log('rdn', rdn, 'start', start, 'end', end, 'diff', diff, 'ds', ds, 'de', de, 'cs', cs);

      let td = 0;

      if (ds < de || cs > 0) {
        if (duration === 0) {
          cs = 1;
        }

        const nv = 10 - ds + de + (cs - 1) * 10;

        const dn = duration / (nv * 1000);

        DEBUG && console.log('nv', nv, 'dn', dn);

        if (cs > 0) {
          if (ds !== 0) {
            DEBUG && console.log('start #dw' + rdn, 'duration', (10 - ds) * dn, 'y: -=' + (dwfh - dwh * ds));
            tl.fromTo(
              digitRefs.current[rdn].current,
              {
                y: -(ds * dwh),
              },
              {
                duration: (10 - ds) * dn,
                y: -dwfh,
                ease: 'none',
              },
            );

            td += (10 - ds) * dn;

            if (de !== 10) {
              cs--;
            }
          } else {
            if (de === 10) {
              cs++;
            }
          }

          for (let i = 0; i < cs; i++) {
            DEBUG && console.log('loop #dw' + rdn, 'y: 0, duration', 10 * dn, 'y: -=', dwfh);

            tl.fromTo(
              digitRefs.current[rdn].current,
              {
                y: 0,
              },
              {
                duration: 10 * dn,
                y: -dwfh,
                ease: 'none',
              },
            );

            td += 10 * dn;
          }

          if (de % 10 !== 0) {
            DEBUG && console.log('end #dw' + rdn, 'y: 0, dration', de * dn, 'y: -=' + de * dwh);
            tl.fromTo(
              digitRefs.current[rdn].current,
              {
                y: 0,
              },
              {
                duration: de * dn,
                y: -(de * dwh),
                ease: 'none',
              },
            );

            td += de * dn;
          }
        } else {
          DEBUG && console.log('rdn', rdn);

          tl.fromTo(
            digitRefs.current[rdn].current,
            {
              y: -(ds * dwh),
            },
            {
              duration: duration / 1000,
              y: -(de * dwh),
              ease: 'none',
            },
          );

          td += duration / 1000;
        }
      } else if (ds > de) {
        const dds = 10 - ds;

        const d1 = duration / (1000 * (dds + de));

        DEBUG && console.log('#dw' + rdn, 'duration: ', dds * d1, 'y: -=' + dds * dwh);
        DEBUG && console.log('#dw' + rdn, 'y: 0');
        DEBUG && console.log('#dw' + rdn, 'duration: ', de * d1, 'y: -=' + de * dwh);

        tl.fromTo(
          digitRefs.current[rdn].current,
          {
            y: -(ds * dwh),
          },
          {
            duration: dds * d1,
            y: -dwfh,
            ease: 'none',
          },
        ).fromTo(
          digitRefs.current[rdn].current,
          {
            y: 0,
          },
          {
            duration: de * d1,
            y: -(de * dwh),
            ease: 'none',
          },
        );

        td += dds * d1 + de * d1;
      }

      DEBUG && console.log('add animation', 'duration', duration, 'td', td);

      gtl.add(tl, 0);
    }

    setCurrentValue(newValue);

    DEBUG && console.log('play');

    gtl.play();
  }, [value, digits, fractionalDigits, currentValue]);

  const createDigit = (doi, i, type) => {
    return (
      <Div key={'dw' + i} className={styles[`${type}sWrapperClassName`]} $styleText={styles[`${type}sWrapper`]}>
        <Div
          ref={(r) => {
            DEBUG && console.log('set ref', doi, r);
            digitRefs.current[doi].current = r;
          }}
          className={`${styles[`${type}sInnerWrapperClassName`]} disabled`}
          $styleText={styles[`${type}sInnerWrapper`]}
        >
          {[...Array(11).keys()].map((d, di) => {
            return (
              <Div key={'d' + di} className={`${styles[type + 'ClassName'] ?? ''} digit`} $styleText={styles[type]}>
                {di % 10}
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  };

  return React.useMemo(() => {
    DEBUG && console.log('counter render');

    DEBUG && console.log(valueStr, 'digits', digits, 'fractionalDigits', fractionalDigits);

    try {
      [...Array(digits).keys()].forEach((doi) => {
        digitRefs.current[doi].current = null;
      });
    } catch (e) { }
    try {
      [...Array(fractionalDigits).keys()].forEach((doi) => {
        digitRefs.current[digits + doi].current = null;
      });
    } catch (e) { }

    const digitsArr = [];
    [...Array(digits).keys()].reverse().forEach((doi, i) => {
      digitsArr.push(createDigit(doi, i, 'digit'));
      if ((i + 1) % 3 === 0 && valueStr && valueStr > 2 && valueStr - 1 > i) {
        DEBUG && console.log('PUSHING DOT', { i, valueStr, length: digitsArr.length, currentValue });
        digitsArr.push(
          <Div key={`add-dot-${i}`} className={styles.digitsSeparatorWrapper ?? 'digitsSeparatorWrapper'}>
            .
          </Div>,
        );
      }
    });

    return (
      <React.Fragment>
        {digitsArr.reverse()}
        {fractionalDigits > 0 && (
          <React.Fragment>
            <Div className={styles.radixClassName} $styleText={styles.radix}>
              ,
            </Div>
            {[...Array(fractionalDigits).keys()].map((doi, i) => createDigit(digits + doi, digits + i, 'decimal'))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [digits, fractionalDigits, valueStr]);
};

Counter.propTypes = {
  digits: PropTypes.number,
  fractionalDigits: PropTypes.number,
  styles: PropTypes.shape({
    digitsWrapper: PropTypes.string,
    digitsInnerWrapper: PropTypes.string,
    digit: PropTypes.string,
    decimalsWrapper: PropTypes.string,
    decimalsInnerWrapper: PropTypes.string,
    decimal: PropTypes.string,
    radix: PropTypes.string,
  }),
};

Counter.defaultProps = {
  digits: 6,
  fractionalDigits: 2,
  styles: {
    digitsWrapper:
      'height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
    digitsInnerWrapper: '&.disabled {color: rgba(0, 0, 0, 0.3);}',
    digit: 'font-size: 14px;font-weight: 400;',
    decimalsWrapper:
      'height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
    decimalsInnerWrapper: 'font-size: 14px;font-weight: 400;',
    decimal: 'font-size: 14px;font-weight: 400;',
    radix:
      'height: 14px;line-height: 13px;display: inline-block;width: 0;overflow: visible;position: relative;z-index: 1;top: 0;left: -2px;font-size: 14px;font-weight: 600;padding: 0 1px;',
    digitsSeparatorWrapper:
      'display:flex; height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
  },
};

export default Counter;
