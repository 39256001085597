import { debug } from './';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import moment from 'moment';
import axios from 'axios';
import Debugger from '../../../utils/logger';
import { normalizeBetsForEvaluation } from './formatters';

let apiUrl = window.config.betsApiUrl + '/missions';

const logger = Debugger('tournaments-evaluation', Debugger.DEBUG);

const sha1Hex = async (message) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

const getTournament = (groups) => {
  let tournament = null;
  let filteredGroups = groups.filter((g) => g.type === 3);
  let done = false;

  if (filteredGroups.length) {
    filteredGroups.forEach((g) => {
      if (done) return;

      if (!g) return;
      if (g.block_enroll) return;

      const tournaments = [...g.tournaments];

      tournaments.sort((a, b) => {
        if (a && a.start_date && b && b.end_date) {
          return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
        }
        return 0;
      });

      const now = moment().valueOf();
      const activeTournaments = tournaments.filter((t) => {
        if (t && parseInt(t.end_date, 10) > now) return true;
        return false;
      });
      if (!activeTournaments.length) return;

      const t = activeTournaments[0];

      tournament = t;
      done = true;
    });
  }
  return tournament;
};

const evaluateTournament = (ticket, tournamentFilter, acceptBetBuilder) => {
  // check there are some filters; if no filters then is eligible
  if (!tournamentFilter) return [true, []];

  // check ticket type in filters; if no filters then is eligible
  if (typeof tournamentFilter.prematch === 'undefined') return [true, []];

  const fs = tournamentFilter.prematch;

  // no filters then is eligible
  if (fs.length === 0) return [true, []];

  logger.debug('filters', fs, ticket.bets);

  let eligible = true;

  const existentErrors = {};
  const errors = [];
  const errorBets = {};

  const markets = fs.filter((b) => typeof b.idBet !== 'undefined');
  const events = fs.filter((b) => typeof b.idBet === 'undefined');

  for (let bet of ticket.bets) {
    if (bet.betType === 'betBuilder') {
      if (!acceptBetBuilder) {
        if (typeof existentErrors['betBuilder'] === 'undefined') {
          existentErrors['betBuilder'] = true;
          errors.push({
            type: 'outcome',
            idMbo: bet.idMbo,
            args: ['This type of bet (%s) is not accepted', 'Bet Builder'],
          });
        }
        errorBets[bet.idMbo] = true;
        eligible = false;
      }
      continue;
    }

    if (fs) {
      let totalErrors = 0;

      let idxEvent = events.findIndex((f) => {
        if ('idMatch' in f) {
          if (bet.idMatch.toString() !== f.idMatch.toString()) {
            if (typeof existentErrors[`event:${bet.team1Name}:${bet.team2Name}`] === 'undefined' && totalErrors === 0) {
              existentErrors[`event:${bet.team1Name}:${bet.team2Name}`] = true;
              errors.push({
                type: 'outcome',
                idMbo: bet.idMbo,
                args: ['This event (%s) is not accepted', `${bet.team1Name} : ${bet.team2Name}`],
              });
            }
            totalErrors += 1;
            errorBets[bet.idMbo] = true;
          }

          return bet.idMatch.toString() === f.idMatch.toString();
        }
        if ('idTournament' in f) {
          if (bet.idTournament.toString() !== f.idTournament.toString()) {
            if (typeof existentErrors[`tournament:${bet.tournamentName}`] === 'undefined' && totalErrors === 0) {
              existentErrors[`tournament:${bet.tournamentName}`] = true;
              errors.push({
                type: 'outcome',
                idMbo: bet.idMbo,
                args: ['This tournament (%s) is not accepted', bet.tournamentName],
              });
            }
            totalErrors += 1;
            errorBets[bet.idMbo] = true;
          }

          return bet.idTournament.toString() === f.idTournament.toString();
        }
        if ('idCategory' in f) {
          if (bet.idCategory.toString() !== f.idCategory.toString()) {
            if (typeof existentErrors[`category:${bet.categoryName}`] === 'undefined' && totalErrors === 0) {
              existentErrors[`category:${bet.categoryName}`] = true;
              errors.push({
                type: 'outcome',
                idMbo: bet.idMbo,
                args: ['This category (%s) is not accepted', bet.categoryName],
              });
            }
            totalErrors += 1;
            errorBets[bet.idMbo] = true;
          }

          return bet.idCategory.toString() === f.idCategory.toString();
        }
        if ('idSport' in f) {
          if (bet.idSport.toString() !== f.idSport.toString()) {
            if (typeof existentErrors[`sport:${bet.sportName}`] === 'undefined' && totalErrors === 0) {
              existentErrors[`sport:${bet.sportName}`] = true;
              errors.push({
                type: 'outcome',
                idMbo: bet.idMbo,
                args: ['This type of sport (%s) is not accepted', bet.sportName],
              });
            }
            totalErrors += 1;
            errorBets[bet.idMbo] = true;
          }
          return bet.idSport.toString() === f.idSport.toString();
        }
        return false;
      });

      let idxMarket = markets.findIndex((f) => {
        if (bet.idBet.toString() !== f.idBet.toString()) {
          if (typeof existentErrors[`bet:${bet.betName}`] === 'undefined' && totalErrors === 0) {
            existentErrors[`bet:${bet.betName}`] = true;
            errors.push({
              type: 'outcome',
              idMbo: bet.idMbo,
              args: ['This type of bet (%s) is not accepted', bet.betName],
            });
          }
          totalErrors += 1;
          errorBets[bet.idMbo] = true;
        }
        return bet.idBet.toString() === f.idBet.toString();
      });

      let matchesEvent = false;
      if (!events.length) {
        matchesEvent = true;
      } else {
        if (idxEvent !== -1) matchesEvent = true;
      }

      let matchesBet = false;
      if (!markets.length) {
        matchesBet = true;
      } else {
        if (idxMarket !== -1) matchesBet = true;
      }

      if (!(matchesBet && matchesEvent)) {
        // at least one bet doesn't match the filters - set eligible false
        eligible = false;
      }
    }
  }

  // all bets found in ticket filters - ticket eligible
  return [eligible, errors, errorBets];
};

const evaluateTournaments = async (rt) => {
  const state = getStore().getState();

  const headers = {};
  const authentication = state.authentication;
  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    logger.debug('no authentication for tournaments evaluation');
    return;
  }

  headers['Authorization'] = 'Bearer ' + authentication.access_token;

  const ticket = rt.ticket;
  const normalizedBets = normalizeBetsForEvaluation(ticket.betType, ticket.bets);
  ticket.bets = normalizedBets;

  logger.debug('data', rt);

  const bst = getBetsState(state);

  const groups = state.tournamentsMissions ? state.tournamentsMissions.groups.list : [];
  const tournament = getTournament(groups);

  if (!tournament) return null;

  // check if the user has activated the tournament
  if (!tournament.activated) return null;

  const now = new Date().valueOf();
  // check if tournament is running
  if (tournament && (tournament.start_date > now || tournament.end_date < now)) return null;

  const tournamentFilters =
    tournament && tournament.meta && tournament.meta.sport_filters ? tournament.meta.sport_filters : {};
  const tournamentRulesetId =
    tournament && tournament.meta && tournament.meta.ruleset_id ? tournament.meta.ruleset_id : '';
  const rules = bst.config.bonusRules;

  const response = {
    success: true,
    data: {},
  };

  let errors = [];

  let [resFilters, resFiltersErrors, errorBets] = evaluateTournament(
    ticket,
    tournamentFilters,
    !!tournament?.meta?.bet_builder,
  );
  let resRule = true;

  errors = resFiltersErrors;

  if (typeof rules['bets'] !== 'undefined' && tournamentRulesetId && tournamentRulesetId in rules['bets']) {
    rules['bets'][tournamentRulesetId].forEach((r) => {
      logger.debug('evaluating tournament rule', r.id);
      logger.debug('rule lists', r.lists);

      let evalRes = null;

      const lists = r.lists.prematch;

      try {
        evalRes = r.script(ticket, tournament, lists);
      } catch (e) {
        evalRes = { eligible: false };
      }

      if (!evalRes.eligible) {
        resRule = false;

        if (evalRes.error) {
          errors.push(evalRes.error);
        }
      }
    });
  }

  response.data.eligible = resFilters && resRule;

  if (response.data.eligible) {
    const walletAmount = state.wallet.main * 100;

    const betsProductId = 2;
    const bonus = state.wallet.bonuses
      ? state.wallet.bonuses.filter((w) => w.eligibleProducts.find((ep) => ep === betsProductId))
      : [];
    const ring_fence = state.wallet.ringFences
      ? state.wallet.ringFences.filter((w) => w.eligibleProducts.find((ep) => ep === betsProductId))
      : [];

    let bonusTotal = 0;
    bonus.forEach((b) => (bonusTotal += b.amount));
    ring_fence.forEach((b) => (bonusTotal += b.amount));

    bonusTotal = bonusTotal * 100;

    const ticketAmount = ticket.amount * 100;

    let bet_real = ticketAmount;
    let bet_bonus = 0;

    if (ticketAmount > walletAmount + bonusTotal) {
      bet_real = ticketAmount - bonusTotal;
      bet_bonus = bonusTotal;
    } else if (ticketAmount > walletAmount) {
      bet_real = walletAmount;
      bet_bonus = ticketAmount - walletAmount;
    }

    const normalizedTicket = {
      request_id: await sha1Hex(JSON.stringify(ticket)),
      player_id: state.profile.client_player_id,
      client_id: parseInt(window.config.clientId, 10),
      product: 1,
      sub_product: ticket.betType === 'prematch' ? 1 : 2,
      product_type: bst.betsSlip.isWinnerFun ? 2 : 1,
      event_type: 1,
      created_at: +new Date(),
      is_free_bet: bst.betsSlip.selectedFreeBet === -1 ? 0 : 1,
      type_id: ticket.ticketType === 'system' ? 2 : 1,
      odds_min: ticket.totalOddsMin,
      odds_max: ticket.totalOddsMax,
      bet_total: bet_real + bet_bonus,
      bet_real: bet_real,
      bet_bonus: bet_bonus,
      win_total: (ticket.maxWinAmount > 150000 ? 150000 : ticket.maxWinAmount) * 100,
      win_real: (ticket.maxWinAmount > 150000 ? 150000 : ticket.maxWinAmount) * 100,
      win_bonus: 0,
      systems: ticket.systems,
      bets: ticket.bets.map((bet) => ({
        sport_id: bet.idSport,
        category_id: bet.idCategory,
        tournament_id: bet.idTournament,
        event_id: bet.idMatch,
        bet_id: bet.idBet,
        event_market_outcome_id: bet.idMbo,
        odd: bet.value,
        bet_type: bet.mType,
      })),
    };

    try {
      // filters and ruleset id
      const { data } = await axios.post(`${apiUrl}/sport-ticket/evaluate`, normalizedTicket, { headers });

      if (data.error && data.errorCode === 4015) {
        errors.push({
          type: 'outcome',
          idMbo: null,
          args: [data.detail],
        });
        response.data.boosters = [];
        response.data.errors = errors;
        if (data.counterBetDetails && data.counterBetDetails.event_market_outcome_id)
          errorBets[data.counterBetDetails.event_market_outcome_id] = true;
        response.data.errorBets = errorBets;

        response.data.eligible = false;
      } else {
        response.data.boosters = typeof data.error === 'undefined' ? data : [];
      }
    } catch (err) {
      response.data.boosters = [];
    }
  } else {
    response.data.errors = errors;
    response.data.errorBets = errorBets;
  }

  logger.debug('evalRes', response);

  return response;
};

export { evaluateTournaments };
