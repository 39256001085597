export const betConstants = {
  TOGGLE: 'BETS_BET_TOGGLE',
};

export const navSportsConstants = {
  SAVE: 'BETS_NAVSPORT_SAVE',
};

export const searchConstants = {
  SAVE: 'BETS_SEARCH_SAVE',
  SAVE_LEAGUE: 'BETS_SEARCH_SAVE_LEAGUE',
};

export const filterConstants = {
  LOAD: 'BETS_FILTER_LOAD',
  MATCH_DATA: 'BETS_FILTER_MATCH_DATA',
  SET_SELECTED: 'BETS_FILTER_SET_SELECTED',
  UNSELECT: 'BETS_FILTER_UNSELECT',
};

export const betsSlipConstants = {
  ADD: 'BETS_BETSLIP_ADD',
  TOGGLE: 'BETS_BETSLIP_TOGGLE',
  REMOVE: 'BETS_BETSLIP_REMOVE',
  CLEAR: 'BETS_BETSLIP_CLEAR',
  CLEAR_CREATE_STATUS: 'BETS_BETSLIP_CLEAR_CREATE_STATUS',
  STAKE_INC: 'BETS_BETSLIP_STAKE_INC',
  STAKE_DEC: 'BETS_BETSLIP_STAKE_DEC',
  STAKE_SET: 'BETS_BETSLIP_STAKE_SET',
  COMPUTE_WIN: 'BETS_BETSLIP_COMPUTE_WIN',
  TOGGLE_FIXED: 'BETS_BETSLIP_TOGGLE_FIXED',
  TOGGLE_SYSTEM: 'BETS_BETSLIP_TOGGLE_SYSTEM',
  SET_TICKET_TYPE: 'BETS_BETSLIP_SET_TICKET_TYPE',
  ACCEPT_ODD_CHANGE: 'BETS_BETSLIP_ACCEPT_ODD_CHANGE',
  TOGGLE_AUTO_ACCEPT_ODD_CHANGE: 'BETS_BETSLIP_TOGGLE_AUTO_ACCEPT_ODD_CHANGE',
  TOGGLE_ALLOW_TICKET_PLACE_WITH_INVALID_EVENTS: 'BETS_BETSLIP_TOGGLE_ALLOW_TICKET_PLACE_WITH_INVALID_EVENTS',
  PREMATCH_CREATE_TICKET_SUCCESS: 'BETS_BETSLIP_PREMATCH_CREATE_TICKET_SUCCESS',
  PREMATCH_CREATE_TICKET_ERROR: 'BETS_BETSLIP_PREMATCH_CREATE_TICKET_ERROR',
  PREMATCH_CREATE_TICKET_REOFFER: 'BETS_BETSLIP_PREMATCH_CREATE_TICKET_REOFFER',
  LIVE_CREATE_TICKET_PENDING: 'BETS_BETSLIP_LIVE_CREATE_TICKET_PENDING',
  LIVE_CREATE_TICKET_SUCCESS: 'BETS_BETSLIP_LIVE_CREATE_TICKET_SUCCESS',
  LIVE_CREATE_TICKET_ERROR: 'BETS_BETSLIP_LIVE_CREATE_TICKET_ERROR',
  SET_TICKET_ONLINE: 'BETS_BETSLIP_SET_TICKET_ONLINE',
  LOTTO_ADD_TICKET: 'BETS_BETSLIP_LOTTO_ADD_TICKET',
  LOTTO_CLEAR_TICKET: 'BETS_BETSLIP_LOTTO_ADD_TICKET',
  PREMATCH_EVAL_TICKET: 'BETS_BETSLIP_PREMATCH_EVAL_TICKET',
  PREMATCH_EVAL_TICKET_STR: 'BETS_BETSLIP_PREMATCH_EVAL_TICKET_STR',
  PREMATCH_EVAL_TICKET_DATA: 'BETS_BETSLIP_PREMATCH_EVAL_TICKET_DATA',
  SET_CURRENT_TICKET: 'BETS_BETSLIP_SET_CURRENT_TICKET',
  COPY_CURRENT_TICKET: 'BETS_BETSLIP_COPY_CURRENT_TICKET',
  CLEAR_COPY_STATUS: 'BETS_BETSLIP_CLEAR_COPY_STATUS',
  LOAD: 'BETS_BETSLIP_LOAD',
  BONUS_EVALUATE_RESPONSE: 'BETS_BONUS_EVALUATE_RESPONSE',
  REQUEST_BONUS_EVALUATION: 'BETS_REQUEST_BONUS_EVALUATION',
  WINNER_FUN_EVALUATE_RESPONSE: 'BETS_WINNER_FUN_EVALUATE_RESPONSE',
  REQUEST_WINNER_FUN_EVALUATION: 'BETS_REQUEST_WINNER_FUN_EVALUATION',
  TOGGLE_WINNER_FUN_BOOST: 'BETS_BETSLIP_TOGGLE_WINNER_FUN_BOOST',

  FREE_BET_SELECTED_INDEX: 'BETS_BETSLIP_FREE_BET_SELECTED_INDEX',
  FREE_BET_EVALUATE_RESPONSE: 'BETS_FREE_BET_EVALUATE_RESPONSE',

  PREMATCH_MATCHES_LOADED: 'BETS_BETSLIP_PREMATCH_MATCHES_LOADED',

  TOURNAMENT_EVALUATE_REQUEST: 'BETS_TOURNAMENT_EVALUATE_RESPONSE',

  DIGITAIN_BONUS_EVALUATE_RESPONSE: 'BETS_DIGITAIN_BONUS_EVALUATE_RESPONSE',
  DIGITAIN_LOAD_MULTI_BET_DAY: 'BETS_DIGITAIN_LOAD_MULTI_BET_DAY',
  DIGITAIN_SET_MULTI_BET_DAY_NUMBER: 'BETS_DIGITAIN_SET_MULTI_BET_DAY_NUMBER',
  DIGITAIN_SET_BET_BUILDER_BETS: 'BETS_DIGITAIN_SET_BET_BUILDER_BETS',

  FORCE_SHOW_BETSLIP: 'BETS_BETSLIP_FORCE_SHOW_BETSLIP',

  COMPUTE_STAKE_TAX: 'BETS_BETSLIP_COMPUTE_STAKE_TAX',
  SET_STAKE_TAX: 'BETS_BETSLIP_SET_STAKE_TAX',
  COMPUTE_WIN_TAX: 'BETS_BETSLIP_COMPUTE_WIN_TAX',
  SET_WIN_TAX: 'BETS_BETSLIP_SET_WIN_TAX',
};

export const liveConstants = {
  INITIALIZE: 'BETS_LIVE_INITIALIZE',
  SET_MINI_STATE: 'BETS_LIVE_SET_MINI_STATE',
  SET_STATE: 'BETS_LIVE_SET_STATE',
  SET_ODDS: 'BETS_LIVE_SET_ODDS',
  SET_CURRENT_MATCHES: 'BETS_LIVE_SET_CURRENT_MATCHES',
  SET_CARDS: 'BETS_LIVE_SET_CARDS',
  SET_MATCH_BETTING_STATUS: 'BETS_LIVE_SET_MATCH_BETTING_STATUS',
  ADD_MATCHES: 'BETS_LIVE_ADD_MATCHES',
  REMOVE_MATCHES: 'BETS_LIVE_REMOVE_MATCHES',
  MERGE_STATE: 'BETS_LIVE_MERGE_STATE',
  CLEAR_MERGE_STATE: 'BETS_LIVE_CLEAR_MERGE_STATE',
  CREATE_TICKET_REQUEST: 'BETS_LIVE_CREATE_TICKET_REQUEST',
  PLAYER_LOGIN: 'BETS_LIVE_PLAYER_LOGIN',
  PLAYER_LOGOUT: 'BETS_LIVE_PLAYER_LOGOUT',
  UPDATE_SPORT: 'BETS_LIVE_UPDATE_SPORT',
  UPDATE_BET: 'BETS_LIVE_UPDATE_BET',
  UPDATE_CATEGORY: 'BETS_LIVE_UPDATE_CATEGORY',
  UPDATE_TOURNAMENT: 'BETS_LIVE_UPDATE_TOURNAMENT',
  UPDATE_TEAM: 'BETS_LIVE_UPDATE_TEAM',
  UPDATE_MATCH: 'BETS_LIVE_UPDATE_MATCH',
  REMOVE_TOURNAMENT: 'BETS_LIVE_REMOVE_TOURNAMENT',
  REMOVE_CATEGORY: 'BETS_LIVE_REMOVE_CATEGORY',
  BET_META: 'BETS_LIVE_BET_META',
  FETCH_MATCH: 'BETS_LIVE_FETCH_MATCH',
  FETCH_MATCHES: 'BETS_LIVE_FETCH_MATCHES',
  SET_MATCH: 'BETS_LIVE_SET_MATCH',
  SET_MATCHES: 'BETS_LIVE_SET_MATCHES',
  MERGE_MATCHES_WITH_MARKETS: 'BETS_LIVE_MERGE_MATCHES_WITH_MARKETS',
};

export const digitainConstants = {
  INITIALIZE: 'DIGITAIN_LIVE_INITIALIZE',
  SET_MINI_STATE: 'DIGITAIN_LIVE_SET_MINI_STATE',
  SET_STATE: 'DIGITAIN_LIVE_SET_STATE',
  SET_PARSED_STATE: 'DIGITAIN_LIVE_SET_PARSED_STATE',
  QUEUE_UPDATES: 'DIGITAIN_LIVE_QUEUE_UPDATES',
  MERGE_QUEUE: 'DIGITAIN_LIVE_MERGE_QUEUE',
  CLEAR_QUEUE: 'DIGITAIN_LIVE_CLEAR_QUEUE',
  LIVE_UPDATES: 'DIGITAIN_LIVE_UPDATES',
  PREMATCH_UPDATES: 'DIGITAIN_PREMATCH_UPDATES',
  PLAYER_LOGIN: 'DIGITAIN_LIVE_PLAYER_LOGIN',
  PLAYER_LOGOUT: 'DIGITAIN_LIVE_PLAYER_LOGOUT',
  SET_BONUS_RULES: 'DIGITAIN_SET_BONUS_RULES',
  SET_PROGRESSIVE_STATE: 'DIGITAIN_SET_PROGRESSIVE_STATE',
  SET_BETS_CACHE_KEY: 'DIGITAIN_SET_BETS_CACHE_KEY',
};

export const prematchConstants = {
  INITIALIZE: 'BETS_PREMATCH_INITIALIZE',
  SET_MINI_STATE: 'BETS_PREMATCH_SET_MINI_STATE',
  SET_STATE: 'BETS_PREMATCH_SET_STATE',
  COPY_STATE: 'BETS_PREMATCH_COPY_STATE',
  MERGE_STATE: 'BETS_PREMATCH_MERGE_STATE',
  SHOW_TOP_OFFER: 'BETS_PREMATCH_SHOW_TOP_OFFER',
  ADD_MATCHES: 'BETS_PREMATCH_ADD_MATCHES',
  CREATE_TICKET_REQUEST: 'BETS_PREMATCH_CREATE_TICKET_REQUEST',
  CLEAR_EXPIRED: 'BETS_PREMATCH_CLEAR_EXPIRED',
  CONNECT_TICKET_SOCKET: 'BETS_PREMATCH_CONNECT_TICKET_SOCKET',
  AUTH_TICKET_SOCKET: 'BETS_PREMATCH_AUTH_TICKET_SOCKET',
  DISCONNECT_TICKET_SOCKET: 'BETS_PREMATCH_DISCONNECT_TICKET_SOCKET',
  MERGE_MATCHES: 'BETS_PREMATCH_MERGE_MATCHES',
  FETCH_MATCH: 'BETS_PREMATCH_FETCH_MATCH',
  FETCH_MATCHES: 'BETS_PREMATCH_FETCH_MATCHES',
  FETCH_TOURNAMENT_MATCHES: 'BETS_PREMATCH_FETCH_TOURNAMENT_MATCHES',
  SET_MATCH: 'BETS_PREMATCH_SET_MATCH',
  SET_MATCHES: 'BETS_PREMATCH_SET_MATCHES',
  FETCH_SPORT_BY_DAY: 'BETS_PREMATCH_FETCH_SPORT_BY_DAY',
  SET_LOADING_STATE: 'BETS_PREMATCH_SET_LOADING_STATE',
  MERGE_MATCHES_WITH_MARKETS: 'BETS_PREMATCH_MERGE_MATCHES_WITH_MARKETS',
};

export const matchDetailsConstants = {
  GET_MATCH_DETAILS: 'BETS_GET_MATCH_DETAILS',
  RECEIVED_INFO: 'BETS_RECEIVED_MATCH_INFO',
  REQUESTING_DATA: 'BETS_REQUESTING_MATCH_DATA',
  SET_OPEN_STATE: 'BETS_SET_OPEN_STATE',
};

export const leagueDetailsConstants = {
  GET_LEAGUE_DETAILS: 'BETS_GET_LEAGUE_DETAILS',
  RECEIVED_INFO: 'BETS_RECEIVED_LEAGUE_INFO',
  REQUESTING_DATA: 'BETS_REQUESTING_LEAGUE_DATA',
};

export const authConstants = {
  INITIALIZE: 'BETS_AUTH_INITIALIZE',
  SUCCESS: 'BETS_AUTH_SUCCESS',
  LIVE_SUCCESS: 'BETS_LIVE_AUTH_SUCCESS',
  PREMATCH_SUCCESS: 'BETS_PREMATCH_AUTH_SUCCESS',
  END_CYCLE: 'BETS_AUTH_END_CYCLE',
  CLEAR: 'BETS_AUTH_CLEAR',
  WINNER_FUN_SUCCESS: 'BETS_AUTH_WINNER_FUN_SUCCESS',
};

export const ticketConstants = {
  SETTLED_LIST_REQUEST: 'BETS_TICKET_SETTLED_LIST_REQUEST',
  WINNER_FUN_SETTLED_LIST_REQUEST: 'BETS_TICKET_WINNER_FUN_SETTLED_LIST_REQUEST',
  OPENED_LIST_REQUEST: 'BETS_TICKET_OPENED_LIST_REQUEST',
  WINNER_FUN_OPENED_LIST_REQUEST: 'BETS_TICKET_WINNER_FUN_OPENED_LIST_REQUEST',
  RESERVED_LIST_REQUEST: 'BETS_TICKET_RESERVED_LIST_REQUEST',
  LIST_REQUEST: 'BETS_TICKET_LIST_REQUEST',
  SETTLED_LIST_RECEIVED: 'BETS_TICKET_SETTLED_LIST_RECEIVED',
  WINNER_FUN_SETTLED_LIST_RECEIVED: 'BETS_TICKET_WINNER_FUN_SETTLED_LIST_RECEIVED',
  OPENED_LIST_RECEIVED: 'BETS_TICKET_OPENED_LIST_RECEIVED',
  WINNER_FUN_OPENED_LIST_RECEIVED: 'BETS_TICKET_WINNER_FUN_OPENED_LIST_RECEIVED',
  RESERVED_LIST_RECEIVED: 'BETS_TICKET_RESERVED_LIST_RECEIVED',
  LIST_RECEIVED: 'BETS_TICKET_LIST_RECEIVED',
  LIST_ERROR: 'BETS_TICKET_LIST_ERROR',
  WINNER_FUN_LIST_ERROR: 'BETS_TICKET_WINNER_FUN_LIST_ERROR',
  REQUESTING_DATA: 'BETS_REQUESTING_TICKET_DATA',
  WINNER_FUN_REQUESTING_DATA: 'BETS_REQUESTING_WINNER_FUN_TICKET_DATA',
  CANCEL_REQUEST: 'BETS_TICKET_CANCEL_REQUEST',
  CANCEL_SUCCESS: 'BETS_TICKET_CANCEL_SUCCESS',
  CANCEL_ERROR: 'BETS_TICKET_CANCEL_ERROR',
  STORE_OPEN: 'BETS_TICKET_STORE_OPEN',
  WINNER_FUN_STORE_OPEN_PREPARE: 'BETS_TICKET_WINNER_FUN_STORE_OPEN_PREPARE',
  WINNER_FUN_STORE_OPEN: 'BETS_TICKET_WINNER_FUN_STORE_OPEN',
  CHECK_CODE: 'BETS_TICKET_CHECK_CODE',
  CHECK_CODE_PENDING: 'BETS_TICKET_CHECK_PENDING',
  CHECK_CODE_DATA: 'BETS_TICKET_CHECK_CODE_DATA',
  CHECK_CODE_ERROR: 'BETS_TICKET_CHECK_CODE_ERROR',
  CHECK_CODE_CLOSE: 'BETS_TICKET_CHECK_CODE_CLOSE',
  RELOAD_PREMATCH: 'BETS_TICKET_RELOAD_PREMATCH',
  WINNER_FUN_RELOAD_PREMATCH: 'BETS_TICKET_WINNER_FUN_RELOAD_PREMATCH',
  RELOAD_LIVE: 'BETS_TICKET_RELOAD_LIVE',
  WINNER_FUN_RELOAD_LIVE: 'BETS_TICKET_WINER_FUN_RELOAD_LIVE',
  HANDLE_UPDATED: 'BETS_TICKET_HANDLE_UPDATED',
  WINNER_FUN_HANDLE_UPDATED: 'BETS_TICKET_WINNER_FUN_HANDLE_UPDATED',
  STORE_META: 'BETS_TICKET_STORE_META',
  HANDLE_CASHOUTED: 'BETS_TICKET_HANDLE_CASHOUTED',
  WINNER_FUN_HANDLE_CASHOUTED: 'BETS_TICKET_WINNER_FUN_HANDLE_CASHOUTED',
  SET_BRID: 'BETS_TICKET_SET_BRID',
};

export const appConstants = {
  INITIALIZE: 'BETS_APP_INITIALIZE',
  PLAYER_INITIALIZE: 'BETS_APP_PLAYER_INITIALIZE',
  SUBSCRIBE_LIVE_TEXT: 'BETS_APP_SUBSCRIBE_LIVE_TEXT',
  UNSUBSCRIBE_LIVE_TEXT: 'BETS_APP_UNSUBSCRIBE_LIVE_TEXT',
  LIVE_TEXT_RECEIVED: 'BETS_APP_LIVE_TEXT_RECEIVED',
  LIVE_TEXT_CLEAR: 'BETS_APP_LIVE_TEXT_CLEAR',
  SET_SELECTED_BET: 'BETS_APP_SET_SELECTED_BET',
  SAVE_RESERVED_TICKET: 'BETS_APP_SAVE_RESERVED_TICKET',
  SAVE_ERROR_LOG: 'BETS_APP_SAVE_ERROR_LOG',
  GET_TICKET_META: 'BETS_APP_GET_TICKET_META',
  EVALUATE_BONUS_REQUEST: 'BETS_APP_EVALUATE_BONUS_REQUEST',
  EVALUATE_BONUS_RESPONSE: 'BETS_APP_EVALUATE_BONUS_RESPONSE',
  SET_URL_BASE_PATH: 'BETS_APP_SET_URL_BASE_PATH',
  EVALUATE_WINNER_FUN_REQUEST: 'BETS_APP_EVALUATE_WINNER_FUN_REQUEST',
  EVALUATE_WINNER_FUN_RESPONSE: 'BETS_APP_EVALUATE_WINNER_FUN_RESPONSE',
  PLAYER_TICKETS_RECEIVED: 'BETS_APP_PLAYER_TICKETS_RECEIVED',
  WINNER_FUN_STORE_EVAL: 'BETS_TICKET_WINNER_FUN_STORE_EVAL',
  EVALUATE_FREE_BET_REQUEST: 'BETS_APP_EVALUATE_FREE_BET_REQUEST',
  EVALUATE_TOURNAMENT_REQUEST: 'BETS_APP_EVALUATE_TOURNAMENT_REQUEST',
  SET_MATCH_PERIOD: 'BETS_APP_SET_MATCH_PERIOD',
  TOGGLE_WINNER_FUN: 'BETS_APP_TOGGLE_WINNER_FUN',
  TOGGLE_COMPETITIONS: 'BETS_APP_TOGGLE_COMPETITIONS',
  SET_IN_PAGE_ID_MATCH: 'BETS_APP_SET_IN_PAGE_ID_MATCH',
  UK_SET_MARKETS: 'BETS_APP_UK_SET_MARKETS',
  UK_SET_SELECTED_BET: 'BETS_APP_UK_SET_SELECTED_BET',
  UK_SET_SELECTED_ARGUMENT: 'BETS_APP_UK_SET_SELECTED_ARGUMENT',
  ODD_LIMITER_ENABLED: 'BETS_APP_ODD_LIMITER_ENABLED',
  ODD_LIMITER_VALUE: 'BETS_APP_ODD_LIMITER_VALUE',
};

export const favoriteConstants = {
  LOAD: 'BETS_FAVORITE_LOAD',
  TOGGLE_PREMATCH_MATCH: 'BETS_FAVORITE_TOGGLE_PREMATCH_MATCH',
  TOGGLE_LIVE_MATCH: 'BETS_FAVORITE_TOGGLE_LIVE_MATCH',
  TOGGLE_PREMATCH_LEAGUE: 'BETS_FAVORITE_TOGGLE_PREMATCH_LEAGUE',
  TOGGLE_LIVE_LEAGUE: 'BETS_FAVORITE_TOGGLE_LIVE_LEAGUE',
};

export const hotDaysConstants = {
  LOADED: 'BETS_HOT_DAYS_LOADED',
  FETCH: 'BETS_HOT_DAYS_FETCH',
};

export const configConstants = {
  LOADED: 'BETS_CONFIG_LOADED',
  RELOAD: 'BETS_CONFIG_RELOAD',
  UPDATE: 'BETS_CONFIG_UPDATE',
  UPDATE_DAY_MULTI_BETS: 'BETS_CONFIG_UPDATE_DAY_MULTI_BETS',
  LOAD_BETS_MARKETS_ORDER: 'BETS_CONFIG_LOAD_BETS_MARKETS_ORDER',
  LOAD_UK_SETTINGS_MARKETS: 'BETS_CONFIG_LOAD_UK_SETTINGS_MARKETS',
  SET_BETS_MARKETS_ORDER: 'BETS_CONFIG_SET_BETS_MARKETS_ORDER',
  SET_ALL_BETS_MARKETS_ORDER: 'BETS_CONFIG_SET_ALL_BETS_MARKETS_ORDER',
};

export const nSoftConstants = {
  CHANGE_DAY_INDEX: 'BETS_NSOFT_CHANGE_DAY_INDEX',
  SET_CONFIG: 'BETS_NSOFT_SET_CONFIG',
  SET_BONUS: 'BETS_NSOFT_SET_BONUS',
  SET_COMBINING_ALLOWED: 'BETS_NSOFT_SET_COMBINING_ALLOWED',
};

export const layoutConstants = {
  CHANGE_LAYOUT: 'BETS_LAYOUT_CHANGE_LAYOUT',
  CHANGE_LAYOUT_WITHOUT_SHOW: 'BETS_LAYOUT_CHANGE_LAYOUT_WITHOUT_SHOW',
  HIDE_LAYOUT_HIGHLIGHTER: 'BETS_LAYOUT_HIDE_LAYOUT_HIGHLIGHTER',
};

export const calendarConstants = {
  SAVE: 'BEST_PREMATCH_CALENDAR_SAVE_SELECTION',
};

export const statScoreConstants = {
  SAVE: 'BEST_STAT_SCORE_SAVE',
};

export const cashoutConstants = {
  INITIALIZE: 'BETS_CASHOUT_INITIALIZE',
  INITIALIZED: 'BETS_CASHOUT_INITIALIZED',
  SUBSCRIBE: 'BETS_CASHOUT_SUBSCRIBE',
  UPDATES: 'BETS_CASHOUT_UPDATES',
  MULTIPLE_UPDATES: 'BETS_CASHOUT_MULTIPLE_UPDATES',
  UNSUBSCRIBE: 'BETS_CASHOUT_UNSUBSCRIBE',
  CASHOUT: 'BETS_CASHOUT_CASHOUT',
  CASHOUT_UPDATE_PROGRESS: 'BETS_CASHOUT_CASHOUT_UPDATE_PROGRESS',
  CASHOUT_RESULT: 'BETS_CASHOUT_CASHOUT_RESULT',
  CASHOUT_CLEAR_STATE: 'BETS_CASHOUT_CASHOUT_CLEAR_STATE',
  CASHOUT_CLEAR_TICKETS: 'BETS_CASHOUT_CLEAR_TICKETS',
  START_PROCESS: 'BETS_CASHOUT_START_PROCESS',
  STOP_PROCESS: 'BETS_CASHOUT_STOP_PROCESS',
  REFETCH_CASHOUT_OFFER: 'BETS_CASHOUT_REFETCH_CASHOUT_OFFER',
};

export const betBuilderConstants = {
  INITIALIZE: 'BETS_BET_BUILDER_INITIALIZE',
  OPEN_UPDATE: 'BETS_BET_BUILDER_OPEN_UPDATE',
  BETS_UPDATE: 'BETS_BET_BUILDER_BETS_UPDATE',
  START_UPDATE_BBF: 'BETS_BET_BUILDER_START_UPDATE_BBF',
  MUST_UPDATE_BBF: 'BETS_BET_BUILDER_MUST_UPDATE_BBF',
  UPDATE_BBF: 'BETS_BET_BUILDER_UPDATE_BBF',
};
