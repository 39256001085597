import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { shallowEqual } from 'react-redux';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { tournamentActivate, tournamentEnroll } from '../../../modules/tournaments-missions/store/actions/tournament';
import { loadTournamentsGroups } from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { loadPlayerLeaderBoard } from '../../../modules/tournaments-missions/store/actions/leader_board';

import { getText, getData } from '../../../modules/lobby/utils/functions';

import { betsBonusRequestEvaluation } from '../../../modules/bets/store/actions/betsSlip';
import { Group } from '../tournament-group';

import './index.scss';

type TournamentBetslipProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

type StateProps = {
  tournament: any;
  group: Group | null;
  activeTournaments: any;
  groupsLoaded: boolean;
};

type AppSelectorState = {
  authentication?: any;
  groups?: any[];
  groupsLoaded?: boolean;
  rank?: any;
  allGames?: any[];
  //
  tournamentActivate?: any;
  tournamentEnroll?: any;
  history?: any;
  doLogin?: any;
  loadTournamentsGroups?: any;
  tournamentEvaluate?: any;
  tournamentEvaluateInProgress?: any;
  eventNo?: any;
  stake?: any;
  odd?: any;
  betsBonusRequestEvaluation?: any;
  // ranking?: any;
  loadPlayerLeaderBoard?: any;

  playerProfile?: { client_player_id?: string };
};

const transformTournament = (tournament: any, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournament));

  const newMetaUI: any = {};

  copy.meta.ui &&
    Object.keys(copy.meta.ui).forEach((metaUIKey) => {
      if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].url;
      } else if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].text != null) {
        if (copy.meta.ui[metaUIKey].text[lang] != null && copy.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(copy.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (copy.meta.ui[metaUIKey].text[k]) {
              value = copy.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;
  return copy;
};

const transformTournaments = (tournaments: any[], lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournaments));

  copy &&
    copy.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy[index].meta.ui = newMetaUI;
      // copy[index].groupName = groupNames[index];
    });

  return copy;
};

const transformGroup = (group: Group, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(group));

  copy.tournaments &&
    copy.tournaments.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy.tournaments[index].meta.ui = newMetaUI;
    });

  const newMetaUI: any = {};

  group.meta.ui &&
    Object.keys(group.meta.ui).forEach((metaUIKey) => {
      if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].url;
      } else if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].text != null) {
        if (group.meta.ui[metaUIKey].text[lang] != null && group.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(group.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (group.meta.ui[metaUIKey].text[k]) {
              value = group.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;
  return copy;
};

export const fetchTournamentInfo = (lang = 'en') => {
  const [state, setState] = React.useState<StateProps>({
    tournament: null,
    activeTournaments: [],
    group: null,
    groupsLoaded: false,
  });

  const dispatch = useAppDispatch();
  const { authentication, groups, groupsLoaded, rank } = useAppSelector<AppSelectorState>(
    (state) => ({
      authentication: state.authentication,
      groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
      groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
      rank: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
    }),
    shallowEqual,
  );

  React.useEffect(() => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    }
  }, []);

  React.useEffect(() => {
    if (!groups || !groups.length) return;

    const filteredGroups = groups.filter((g) => g.type === 3);
    let done = false;

    if (filteredGroups.length) {
      filteredGroups.forEach((g) => {
        if (done) return;

        if (!g) return;

        const tournaments = [...g.tournaments];

        tournaments.sort((a, b) => {
          if (a && a.start_date && b && b.end_date) {
            return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
          }
          return 0;
        });

        const now = moment().valueOf();
        const activeTournaments = tournaments.filter((t) => {
          if (t && parseInt(t.end_date, 10) > now) return true;
          return false;
        });
        if (!activeTournaments.length) return;
        const tournament = activeTournaments[0];

        let isAuthenticated = false;
        if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
          isAuthenticated = true;
        }

        if (tournament) {
          if (rank && !rank[tournament.id]) {
            dispatch(loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true }));
          }
        }

        setState({
          tournament: transformTournament(tournament, lang),
          activeTournaments: transformTournaments(activeTournaments, lang),
          group: transformGroup(g, lang),
          groupsLoaded: true,
        });
        // setGroup(g);
        // setTournament(t);
        done = true;
      });
    }
  }, [authentication, groups, rank, tournamentActivate, lang]);

  return state;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TournamentBetslip = (componentProps: TournamentBetslipProps) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // *************************************************************************
  const ERROR = 'ERROR';
  const NOT_ENROLLED = 'NOT_ENROLLED';
  const NOT_ACTIVE = 'NOT_ACTIVE';
  const INFO = 'INFO';

  const [rulesOpen, setRulesOpen] = React.useState(false);
  const [showErrorDetails, setShowErrorDetails] = React.useState(false);
  const [textIndex, setTextIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setTextIndex((index) => index + 1),
      16000, // every 6 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const convertArgs = (args: any[], t: any) => {
    let index = 0;
    const txt = args[0].replace(/%s/g, (...args: any[]) => {
      return `{{PARM${index++}}}`;
    });
    const params: { [key: string]: any } = {};

    for (let i = 1; i < args.length; i++) {
      params[`PARM${i - 1}`] = args[i];
    }

    return t(txt, params);
  };

  // *************************************************************************

  const { authentication, rank, playerProfile, eventNo, stake, odd, tournamentEvaluateInProgress, tournamentEvaluate } =
    useAppSelector<AppSelectorState>((state) => {
      const currentTicket = state.bets.betsSlip.currentTicket ?? 0;
      const ct = state.bets?.betsSlip?.tickets[currentTicket];

      return {
        authentication: state.authentication,
        rank: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
        playerProfile: state.profile,
        eventNo: ct ? ct.live.selected.length + ct.prematch.selected.length : 0,
        stake: ct.amount,
        odd: ct.totalOdds,
        tournamentEvaluateInProgress: ct.tournamentEvaluateInProgress,
        tournamentEvaluate: ct.tournamentEvaluate,
      };
    }, shallowEqual);

  const { tournament, activeTournaments, group, groupsLoaded } = fetchTournamentInfo(i18n.language);

  let isAuthenticated = false;
  if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) isAuthenticated = true;
  if (!isAuthenticated) return null;

  if (!tournament) return null;

  const now = new Date().valueOf();
  if (tournament && (tournament.start_date > now || tournament.end_date < now)) return null;

  // *************************************************************************
  const supportedTypes = ['stake', 'odd', 'eventNo'];
  const starsData: any[] = [];
  let stakeData = { starsTotal: 0, stars: 0 };
  let oddData = { starsTotal: 0, stars: 0 };
  let eventNoData = { starsTotal: 0, stars: 0 };
  const descriptions: any[] = [];
  const descriptionsIndex: any[] = [];
  const max_level_text = getText(tournament, `meta.stars.max_level_text.${i18n.language}`, '');
  // *************************************************************************
  let stakesData: any[] = [];
  let oddsData: any[] = [];
  let eventNosData: any[] = [];

  // *************************************************************************

  if (
    tournament &&
    tournament.meta &&
    tournament.meta.stars &&
    tournament.meta.stars.items &&
    Array.isArray(tournament.meta.stars.items)
  ) {
    tournament.meta.stars.items.forEach((item: any, itemIndex: number) => {
      const container = {
        text: getText(item, `container_name.${i18n.language}`, ''),
        stars: 0,
        value: '',
        starsTotal: item.stars.length,
        type: item.container_type,
        description: '', // test this
        descriptionIndex: 0, // test this
      };

      // console.log('TournamentBetslip ITEM: ', item, 'container: ', container);

      if (!item.container_type || supportedTypes.indexOf(item.container_type) === -1) return;
      let compareWith = 0;

      switch (item.container_type) {
        case 'stake':
          compareWith = stake;
          break;
        case 'odd':
          compareWith = odd;
          break;
        case 'eventNo':
          compareWith = eventNo;
          break;
        default:
        /* noop */
      }

      if (!compareWith) compareWith = 0;

      let gotIt = false;

      for (let i = 0; i < item.stars.length; i++) {
        // console.log('TournamentBetslip item.stars[i]: index ', i, 'item: ', item.stars[i]);

        const value =
          item.stars && Array.isArray(item.stars) && item.stars[i].value ? parseFloat(item.stars[i].value) : 0;

        // console.log('DeBUG tournament VALUE: ', value, '>', compareWith);

        if (value && value > compareWith) {
          container.stars = i;
          // container.value = getText(item.stars[i], `value_string.${i18n.language}`, item.stars[i].value);
          container.value = getText(item.stars[i], `value_string.${i18n.language}`, '');

          const description = getText(item.stars[i], `description.${i18n.language}`, '');
          if (description) {
            descriptions.push(description);
            descriptionsIndex.push(itemIndex);
            container.description = description; // test this
            container.descriptionIndex = itemIndex; // test this
          }
          gotIt = true;
          break;
        }
      }

      if (!gotIt) {
        container.stars = item.stars.length;
        // container.value = getText(
        //   item.stars[item.stars.length - 1],
        //   `value_string.${i18n.language}`,
        //   item.stars[item.stars.length - 1].value,
        // );
        container.value = 'MAX';
      }

      // console.log('DeBUG tournament CONTAINER: ', container, gotIt);

      starsData.push(container);
    });

    stakeData = Object.assign(
      {},
      starsData.find((s) => s.type === 'stake'),
    );
    if (stakeData && stakeData.starsTotal > 0) {
      stakesData = Array.from(Array(stakeData.starsTotal).keys()).map((i) => {
        if (i < stakeData.stars) {
          return {
            ...stakeData,
            isStarFilled: true,
            starIndex: i,
          };
        } else if (i === stakeData.stars) {
          return {
            ...stakeData,
            isStarFilled: false,
            isNextStar: true,
            starIndex: i,
          };
        } else {
          return {
            ...stakeData,
            isStarFilled: false,
            starIndex: i,
          };
        }
      });
    }

    oddData = Object.assign(
      {},
      starsData.find((s) => s.type === 'odd'),
    );
    if (oddData && oddData.starsTotal > 0) {
      oddsData = Array.from(Array(oddData.starsTotal).keys()).map((i) => {
        if (i < oddData.stars) {
          return {
            ...oddData,
            isStarFilled: true,
            starIndex: i,
          };
        } else if (i === oddData.stars) {
          return {
            ...eventNoData,
            isStarFilled: false,
            isNextStar: true,
            starIndex: i,
          };
        } else {
          return {
            ...oddData,
            isStarFilled: false,
            starIndex: i,
          };
        }
      });
    }

    eventNoData = Object.assign(
      {},
      starsData.find((s) => s.type === 'eventNo'),
    );
    if (eventNoData && eventNoData.starsTotal > 0) {
      eventNosData = Array.from(Array(eventNoData.starsTotal).keys()).map((i) => {
        if (i < eventNoData.stars) {
          return {
            ...eventNoData,
            isStarFilled: true,
            isNextStar: false,
            starIndex: i,
          };
        } else if (i === eventNoData.stars) {
          return {
            ...eventNoData,
            isStarFilled: false,
            isNextStar: true,
            starIndex: i,
          };
        } else {
          return {
            ...eventNoData,
            isStarFilled: false,
            starIndex: i,
            isNextStar: false,
          };
        }
      });
    }
  }

  const toggleShowErrorDetails = () => {
    setShowErrorDetails((v) => !v);
  };

  const showRules = () => setRulesOpen(true);
  const hideRules = () => setRulesOpen(false);

  const checkIfLogin = () => {
    // check if suer is logged in. if not redirect to the login page (with redirect back configured)
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      navigate('/login');
      return true;
    }
    return false;
  };

  // same as tournament-group
  const handleJoinPlay = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (checkIfLogin()) return;
    if (!tournament.enrolled) {
      dispatch(
        tournamentEnroll({
          tournamentId: tournament.id,
          activate: true,
          cb: () => {
            dispatch(betsBonusRequestEvaluation(true));
          },
        }),
      );
    } else if (!tournament.activated) {
      dispatch(
        tournamentActivate({
          tournamentId: tournament.player_mission_id,
          cb: () => {
            dispatch(betsBonusRequestEvaluation(true));
          },
        }),
      );
    } else {
      dispatch(betsBonusRequestEvaluation(true));
    }
  };

  let tournamentState = NOT_ENROLLED;

  const enrolled = tournament.enrolled;
  const activated = tournament.activated;
  const name = tournament.name;
  const total_prizes = tournament.meta.levels ? tournament.meta.levels : 0;
  const eligible = tournamentEvaluate && tournamentEvaluate.data && tournamentEvaluate.data.eligible ? true : false;
  const eligibility_errors =
    tournamentEvaluate && tournamentEvaluate.data && tournamentEvaluate.data.errors
      ? tournamentEvaluate.data.errors
      : [];
  const boostScore = eligible && tournamentEvaluate.data.boosters ? tournamentEvaluate.data.boosters.boostScore : 0;
  let extraScore = 0;
  const player =
    rank && typeof rank[tournament.id] !== 'undefined' && typeof rank[tournament.id].player !== 'undefined'
      ? rank[tournament.id].player
      : null;
  let me = null;
  if (player) {
    me = player.find((p: any) => p.me);
  }
  const top =
    rank && typeof rank[tournament.id] !== 'undefined' && typeof rank[tournament.id].top !== 'undefined'
      ? rank[tournament.id].top
      : null;
  const next_prize =
    rank && typeof rank[tournament.id] !== 'undefined' && typeof rank[tournament.id].next_prize !== 'undefined'
      ? rank[tournament.id].next_prize
      : null;
  const avatar_image = getData(tournament, 'meta.ui.avatar_image.url', 'https://picsum.photos/40');

  try {
    const base_value_per_leu = parseFloat(getData(tournament, 'meta.base_value_per_leu', 0));
    const current_value_per_leu = boostScore / stake;
    const bonus_points_percentage = current_value_per_leu / base_value_per_leu - 1;
    const bonus_points_scalar = (bonus_points_percentage * boostScore) / (1 + bonus_points_percentage);

    if (bonus_points_scalar) extraScore = bonus_points_scalar;
    if (extraScore < 0) extraScore = 0;
  } catch (err) {
    /*noop*/
  }
  let boosterInfo = null;
  const rules =
    tournament && tournament.meta && tournament.meta.ui && tournament.meta.ui.extended_rules
      ? tournament.meta.ui.extended_rules
      : {};

  if (
    tournamentEvaluate &&
    tournamentEvaluate.data &&
    tournamentEvaluate.data.boosters &&
    tournamentEvaluate.data.boosters.objectives
  ) {
    let total = 0;
    try {
      const keys = Object.keys(tournamentEvaluate.data.boosters.objectives);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const objective = tournamentEvaluate.data.boosters.objectives[key];

        for (let j = 0; j < objective.boosters.length; j++) {
          const booster = objective.boosters[j];

          if (booster && booster.type === 2 && booster.ui_meta && booster.ui_meta.type === 'general') {
            if (!boosterInfo) boosterInfo = cloneDeep(booster.ui_meta);
            total += booster.value;
          }
        }
      }
    } catch (err) {
      /* noop */
    }

    if (boosterInfo) boosterInfo.value = total;
  }

  if (enrolled) tournamentState = NOT_ACTIVE;
  if (activated) tournamentState = INFO;

  // @ts-ignore
  const enroll_blocked = group && group.block_enroll === true;

  if (!eligible && activated) tournamentState = ERROR;

  const getSingleDescription = (descriptions: any[]) => {
    if (descriptions && descriptions.length > 0) {
      return [descriptions[textIndex % descriptions.length]];
    }
    return [max_level_text];
  };

  const getSingleDescriptionIndex = (descriptionsIndex: any[]) => {
    if (descriptionsIndex && descriptionsIndex.length > 0) {
      return descriptionsIndex[textIndex % descriptionsIndex.length];
    }
    return -1;
  };

  const contextValue = {
    currentTournament: {
      element_type: 'internal',
      data: tournament
        ? {
            ...tournament,
            meExists: me && me.pos != null,
            mePos: me && me.pos != null ? me.pos : '?',
            meScore: me && me.score != null ? me.score : '',
            meAward: me && me.award != null ? me.award : '',

            meta: tournament.meta
              ? {
                  ...tournament.meta,
                }
              : {},
          }
        : null,
    },
    group: group,
    groupLoaded: groupsLoaded,
    hasGroup: !!group,
    tournaments: activeTournaments
      ? activeTournaments.map((t: any) => ({
          ...t,
          // handleJoinEnrollTournament: handleJoinEnrollTournament,
        }))
      : [],
    // ***********************************************************************
    handleJoinPlay,
    showErrorDetails,
    toggleShowErrorDetails,
    rulesOpen,
    showRules,
    hideRules,
    rules,
    tournamentState,
    enrolled,
    name,
    total_prizes,
    eligible,
    eligibility_errors: eligibility_errors.map((err: any) => {
      return convertArgs(err['args'], t);
    }),
    enroll_blocked,
    isMe: me,

    boostScore: isNaN(boostScore) ? '-' : Math.floor(boostScore),
    extraScore: Math.floor(extraScore),
    // extraScore: extraScore >= 1 ? `+ ${Math.floor(extraScore)} extra ` : null,
    player,
    top,
    next_prize,
    avatar_image,
    boosterInfo,
    activated,
    descriptions:
      Array.isArray(descriptions) && descriptions.length > 0 ? descriptions : max_level_text ? [max_level_text] : [],
    descriptionsIndex,

    singleDescription: getSingleDescription(descriptions),
    singleDescriptionIndex: getSingleDescriptionIndex(descriptionsIndex),

    max_level_text,
    // stars
    starsData,
    stakeData,
    oddData,
    eventNoData,
    stakesData,
    oddsData,
    eventNosData,
  };

  false &&
    console.log('Tournament Betslip contextValue: ', {
      contextValue,
      // tournamentEvaluate,
      // tournamentEvaluateInProgress,
      // rank,
      tournament,
      // stake,
      // player,
    });
  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TournamentBetslip;
