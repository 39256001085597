import React, { useCallback, useTransition } from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { PopupBonus, fetchPopupBonuses } from '../../../store/slices/popupBonuses';
import { productID, productMainURL, serverProductID } from './constants';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { getEligibleBonuses } from '../../../modules/casino/store/actions/application';
import { getText } from '../wheel/utils';
import { setMarketingAgreements } from '@/modules/casino/store/actions';

type TemplateProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const FETCHING_POPUPS_DATA_TIMEOUT = 5000;
const SHOW_POPUP_TIMEOUT = 5000;

const localStorageKey = 'popup_bonus_';
const termsAndConditionsKey = `${localStorageKey}_tc_agreement`;

const getBonusCooldownKey = (product: any, bonus: any) => {
  return `${localStorageKey}_product_${product}_bonus_${bonus}_coolDown`;
};

const getProductCooldownKey = (product: any) => {
  return `${localStorageKey}_product_${product}_coolDown`;
};
const getBonusShownKey = (product: any, bonus: any) => {
  return `${localStorageKey}_product_${product}_bonus_${bonus}_shown`;
};

let timerID: any = 0;

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Template = (componentProps: TemplateProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const loaded = useAppSelector((state) => state.popupBonuses.loaded);
  const inProgress = useAppSelector((state) => state.popupBonuses.inProgress);
  const list = useAppSelector((state) => state.popupBonuses.list);
  const profile = useAppSelector((state) => state.profile);
  const uriMatches = useMatches();
  const { i18n } = useTranslation();

  const [popupData, setPopupData] = React.useState<{ show: boolean; tcAgreement: boolean; data: PopupBonus | null }>({
    show: false,
    tcAgreement: false,
    data: null,
  });

  const marketing_agreements = useAppSelector((state) => state.alerts.settings.marketing_agreements);
  const eligibleBonuses = useAppSelector((state) => state.application.eligibleBonuses);
  const authentication = useAppSelector((state) => state.authentication);

  const hasMarketingAggreement =
    marketing_agreements?.marketing_receive_sms === 1 ||
    marketing_agreements?.marketing_receive_email === 1 ||
    marketing_agreements?.marketing_receive_phone === 1 ||
    marketing_agreements?.marketing_partners === 1;

  const [marketingData, setMarketingData] = React.useState({
    marketing: {
      checked: hasMarketingAggreement,
    },
    marketing_sms: {
      checked: marketing_agreements?.marketing_receive_sms === 1,
    },
    marketing_email: {
      checked: marketing_agreements?.marketing_receive_email === 1,
    },
    marketing_phone: {
      checked: marketing_agreements?.marketing_receive_phone === 1,
    },
    marketing_partners: {
      checked: marketing_agreements?.marketing_partners === 1,
    },
  });

  const onHidePopup = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    setPopupData((v) => ({
      ...v,
      show: false,
      tcAgreement: true,
      data: null,
    }));
  }, []);

  const handleCTAClick = React.useCallback(() => {
    const postData: any = {
      all: true,
      value: 0,
    };

    if (
      marketingData.marketing_sms.checked &&
      marketingData.marketing_email.checked &&
      marketingData.marketing_phone.checked &&
      marketingData.marketing_partners.checked
    ) {
      postData.value = 1;
    } else {
      if (marketingData.marketing_sms.checked) postData.marketing_receive_sms = 1;
      if (marketingData.marketing_email.checked) postData.marketing_receive_email = 1;
      if (marketingData.marketing_phone.checked) postData.marketing_receive_phone = 1;
      if (marketingData.marketing_partners.checked) postData.marketing_partners = 1;
    }

    if (
      marketingData.marketing_sms.checked ||
      marketingData.marketing_email.checked ||
      marketingData.marketing_phone.checked ||
      marketingData.marketing_partners.checked
    ) {
      dispatch(setMarketingAgreements(postData));
    }

    let tcAgreement = ExpiringLocalStorage.get(termsAndConditionsKey);

    if (!Array.isArray(tcAgreement)) {
      // handle old way when tcAgreement was a boolean
      tcAgreement = null;
    }

    if (tcAgreement) {
      tcAgreement.push(profile?.global_player_id);
    } else {
      tcAgreement = [profile?.global_player_id];
    }

    // store the cooldown for shown
    // @ts-ignore
    ExpiringLocalStorage.set(termsAndConditionsKey, tcAgreement, 365 * 24 * 60 * 60);

    setPopupData((v) => ({
      ...v,
      show: false,
      tcAgreement: true,
      data: null,
    }));
  }, [marketingData, dispatch, profile?.global_player_id]);

  React.useEffect(() => {
    if (!loaded && !inProgress) {
      setTimeout(() => {
        const tenantId = window.config.clientId;
        const siteId = window.config.siteId;

        dispatch(fetchPopupBonuses({ tenantId, siteId }));
      }, FETCHING_POPUPS_DATA_TIMEOUT);
    }
  }, [dispatch, loaded, inProgress]);

  const urlChanged = useCallback(
    (eligibleBonuses: any, bonusPopups: any, pathname: any) => {
      clearTimeout(timerID);

      let currentProductId;

      if (pathname === productMainURL.HOME) {
        currentProductId = productID.HOME;
      } else if (pathname.indexOf(productMainURL.LIVE_CASINO) === 0 || pathname.indexOf('cazino-live') === 1) {
        currentProductId = productID.LIVE_CASINO;
      } else if (pathname.indexOf(productMainURL.CASINO) === 0 || pathname.indexOf('/play') === 0) {
        currentProductId = productID.CASINO;
      } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
        currentProductId = productID.LOTTO;
      } else if (pathname.indexOf(productMainURL.LIVE_SPORT) === 0) {
        currentProductId = productID.LIVE_SPORT;
      } else if (pathname.indexOf(productMainURL.SPORT) === 0) {
        currentProductId = productID.SPORT;
      } else if (pathname.indexOf(productMainURL.WINNER_FUN) === 0) {
        currentProductId = productID.WINNER_FUN;
      } else if (pathname.indexOf(productMainURL.CUSTOMER_SUPPORT) === 0 || pathname.indexOf('locations') === 1) {
        currentProductId = productID.CUSTOMER_SUPPORT;
      } else if (pathname === '/test') {
        currentProductId = productID.CASINO;
      } else {
        currentProductId = productID.ALL;
      }

      const found = [
        productMainURL.CASINO,
        productMainURL.LIVE_CASINO,
        productMainURL.SPORT,
        productMainURL.LIVE_SPORT,
        productMainURL.LOTTO,
        productMainURL.WINNER_FUN,
        productMainURL.VIRTUALS,
        productMainURL.HOME,
        '/test',
      ].find((element) => {
        if (element === '/') {
          if (element === pathname) return true;
          return false;
        }
        return pathname.indexOf(element) === 0;
      });

      const ignoreCooldown = false;

      if (found && currentProductId && bonusPopups.length) {
        let activeProduct = serverProductID[currentProductId] ? serverProductID[currentProductId] : false;
        if (!activeProduct) return;

        let productKey = getProductCooldownKey(activeProduct);
        const productCooldown = ExpiringLocalStorage.get(productKey);
        if (productCooldown && !ignoreCooldown) return;

        for (let i = 0; i < bonusPopups.length; i++) {
          const pb = bonusPopups[i];

          // Don't show any popup type if the user is logged out
          if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
            continue;
          }

          if (
            !(
              pb &&
              pb.product_id &&
              activeProduct &&
              (pb.product_id === productID.ALL || parseInt(pb.product_id) === parseInt(activeProduct))
            )
          )
            continue;

          let tcAgreement = ExpiringLocalStorage.get(termsAndConditionsKey);

          if (!Array.isArray(tcAgreement)) {
            // handle old way when tcAgreement was a boolean
            tcAgreement = [];
          }

          if (
            !profile?.global_player_id ||
            (pb.type === 'marketing' && tcAgreement && tcAgreement?.includes?.(profile?.global_player_id)) ||
            hasMarketingAggreement
          ) {
            continue;
          }

          if (pb.product_id === productID.ALL) {
            activeProduct = productID.ALL;
            productKey = getProductCooldownKey(activeProduct);

            const productCooldown = ExpiringLocalStorage.get(productKey);
            if (productCooldown && !ignoreCooldown) continue;
          }

          if (pb.type === 'website_switch') continue;

          if (window?.config?.useBonusPopups !== '1' && pb.type === 'bonus') continue;

          if (
            eligibleBonuses &&
            pb.bonus_preset_id &&
            typeof eligibleBonuses[pb.bonus_preset_id.toString()] !== 'undefined'
          ) {
            // if it has a bonus
            const bonusKey = getBonusCooldownKey(activeProduct, pb.bonus_preset_id);
            const bonusCooldown = ExpiringLocalStorage.get(bonusKey);
            if (bonusCooldown && !ignoreCooldown) continue;

            const shownKey = getBonusShownKey(activeProduct, pb.bonus_preset_id);
            const bonusShown = ExpiringLocalStorage.get(shownKey);

            if (bonusShown >= pb.display_count_cap) continue;

            const now = +new Date();

            if (pb.timer_start && pb.timer_start > now) {
              // the popup has not started yet
              continue;
            }

            if (pb.timer && pb.timer < now) {
              // the popup has an expired timer
              continue;
            }

            //console.log("PopupBonusEntry[setTimeout]", { hasMarketingAggreement, timerID });
            timerID = setTimeout(() => {
              const data = { ...pb };

              if (pb.type !== 'marketing') {
                // store the cooldown for product if not market
                ExpiringLocalStorage.set(productKey, 1, pb.product_cooldown);

                // store the cooldown for bonus
                ExpiringLocalStorage.set(bonusKey, 1, pb.item_cooldown);

                // store the cooldown for shown
                // @ts-ignore
                ExpiringLocalStorage.set(shownKey, bonusShown + 1, 7 * 24 * 60 * 60);

                data.timer = pb.timer
                  ? Math.floor(parseInt(pb.timer) / 1000)
                  : eligibleBonuses[pb.bonus_preset_id.toString()].bonus_campaign_end
                    ? eligibleBonuses[pb.bonus_preset_id.toString()].bonus_campaign_end
                    : null;

                setPopupData((v) => ({
                  ...v,
                  show: true,
                  data: data,
                }));
              }
            }, SHOW_POPUP_TIMEOUT);

            break;
          } else if (!pb.bonus_preset_id) {
            // null bonus
            if (pb.type === 'marketing' && !popupData.tcAgreement) {
              setPopupData((v) => ({
                ...v,
                show: true,
                data: pb,
              }));
            } else {
              const bonusKey = getBonusCooldownKey(activeProduct, pb.id);
              const bonusCooldown = ExpiringLocalStorage.get(bonusKey);
              if (bonusCooldown && !ignoreCooldown) continue;

              const shownKey = getBonusShownKey(activeProduct, pb.id);
              const bonusShown = ExpiringLocalStorage.get(shownKey);

              if (bonusShown >= pb.display_count_cap) continue;

              const now = +new Date();

              if (pb.timer_start && pb.timer_start > now) {
                // the popup has an expired timer
                continue;
              }

              if (pb.timer && pb.timer < now) {
                // the popup has an expired timer
                continue;
              }

              //console.log("PopupBonusEntry[setTimeout]", { hasMarketingAggreement, timerID });
              timerID = setTimeout(() => {
                const data = { ...pb };

                if (pb.type !== 'marketing') {
                  // store the cooldown for product if not market
                  ExpiringLocalStorage.set(productKey, 1, pb.product_cooldown);

                  // store the cooldown for bonus
                  ExpiringLocalStorage.set(bonusKey, 1, pb.item_cooldown);

                  // store the cooldown for shown
                  // @ts-ignore
                  ExpiringLocalStorage.set(shownKey, bonusShown + 1, 7 * 24 * 60 * 60);

                  data.timer = pb.timer ? Math.floor(parseInt(pb.timer) / 1000) : null;

                  setPopupData((v) => ({
                    ...v,
                    show: true,
                    data: data,
                  }));
                }
              }, SHOW_POPUP_TIMEOUT);

              break;
            }
          }
        }
      }
    },
    [popupData.tcAgreement, profile.global_player_id, hasMarketingAggreement, authentication],
  );

  React.useEffect(() => {
    if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      if (!eligibleBonuses) {
        dispatch(getEligibleBonuses());
      }
    } else {
      setPopupData({ show: false, tcAgreement: false, data: null });
    }

    let uriMatch = null;
    if (uriMatches && uriMatches.length) {
      uriMatch = uriMatches[uriMatches.length - 1];
    }
    urlChanged(eligibleBonuses, list, uriMatch?.pathname);

    if (marketing_agreements) {
      setMarketingData({
        marketing: {
          checked: hasMarketingAggreement,
        },
        marketing_sms: {
          checked: marketing_agreements?.marketing_receive_sms === 1,
        },
        marketing_email: {
          checked: marketing_agreements?.marketing_receive_email === 1,
        },
        marketing_phone: {
          checked: marketing_agreements?.marketing_receive_phone === 1,
        },
        marketing_partners: {
          checked: marketing_agreements?.marketing_partners === 1,
        },
      });
    }
  }, [
    eligibleBonuses,
    list,
    uriMatches,
    authentication,
    marketing_agreements,
    dispatch,
    hasMarketingAggreement,
    urlChanged,
  ]);

  const contextValue = React.useMemo(() => {
    const toggleCheckbox = (e: React.MouseEvent<HTMLElement>) => {
      const newMarketingData: any = { ...marketingData };
      const target = e.target as HTMLInputElement;

      newMarketingData[target.name] = {
        checked: target.checked,
      };

      switch (target.name) {
        case 'marketing':
          newMarketingData.marketing_sms.checked = target.checked;
          newMarketingData.marketing_email.checked = target.checked;
          newMarketingData.marketing_phone.checked = target.checked;
          newMarketingData.marketing_partners.checked = target.checked;

          break;
        case 'marketing_sms':
        case 'marketing_phone':
        case 'marketing_email':
        case 'marketing_partners':
          newMarketingData.marketing.checked =
            newMarketingData.marketing_sms.checked ||
            newMarketingData.marketing_email.checked ||
            newMarketingData.marketing_phone.checked ||
            newMarketingData.marketing_partners.checked;
          break;
        default:
      }
      setMarketingData(newMarketingData);
    };

    // show popup only on /test page during dev so that other people won't see them
    let uriMatch = null;
    if (uriMatches && uriMatches.length) {
      uriMatch = uriMatches[uriMatches.length - 1];
    }

    const showPopup = popupData.show;
    if (uriMatch?.pathname !== '/test') {
      // showPopup = false;
    }

    const data: any = popupData.data ? { ...popupData.data } : null;

    if (data) {
      data.cta_text_label = getText(JSON.parse(data.cta_text_label), `text.${i18n.language}`, '');
      data.tc_text_label = getText(JSON.parse(data.tc_text_label), `text.${i18n.language}`, '');
      if (data?.rules.length > 0) {
        const rules: any = [];
        data.rules.map((rule: any) => rules.push({ value: getText(rule, `${i18n.language}`, '') }));
        data.rules = rules;
      }
      data.start_date = data.timer_start;
      data.end_date = data.timer * 1000;
    }

    return {
      list,
      showPopup: showPopup,
      onHidePopup: onHidePopup,
      popupData: data,
      toggleCheckbox: toggleCheckbox,
      marketingData: marketingData,
      marketing_agreements: marketing_agreements,
      handleCTAClick: handleCTAClick,
    };
  }, [list, popupData, onHidePopup, marketingData, marketing_agreements, uriMatches, i18n.language, handleCTAClick]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Template;
