import React from 'react';
import styled from 'styled-components';
import { createSelector } from 'reselect';
import 'moment/dist/locale/ro';

import { useAppSelector, useAppDispatch, RootState } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { betsSlipLoadMultiBetDay } from '../../../modules/bets/store/actions/betsSlip';
//import { formatTotalOddsValue, formatDate, formatOddName } from '../../../modules/bets/utils/formatters';
import { formatTotalOddsValue, formatDate, formatOddName } from '@/components/modules/bets/utils/functions';
// import { prematchFetchMatches } from '@/modules/bets/store/actions/prematch';
// import { fetchEventsWithMarkets } from '@/modules/bets/store/actions/bets';
import { EventWithMarkets, fetchEventsWithMarkets } from '@/modules/bets/utils/eventsFetcher';

import crests from '../../../modules/bets/utils/crests';
import moment from 'moment';

//import './index.scss';

type MultiBetOfTheDateProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const emptyArray: any = [];
const buildMultiBets = (cfg: any, pm: any, language: string) => {
  if (!('dayMultiBets' in cfg)) {
    return emptyArray;
  }

  if (!Array.isArray(cfg.dayMultiBets)) {
    return emptyArray;
  }

  const dayMultiBets = [];

  for (let i = 0; i < cfg.dayMultiBets.length; i++) {
    const dmb = cfg.dayMultiBets[i];
    const n = { ...dmb, endOffer: Infinity };
    n.stakes = JSON.parse(JSON.stringify(dmb.stakes));

    let hasError = false;

    const dates = [];
    let totalOdds = 1;

    for (const s of n.stakes) {
      if (!(s.event_id in pm)) {
        hasError = true;
        break;
      }

      const m = pm[s.event_id];

      n.endOffer = Math.min(n.endOffer, m.matchDateTime);
      dates.push(m.matchDateTime);

      if (!(m.matchBets && Array.isArray(m.matchBets))) {
        hasError = true;
        break;
      }

      let stake = null;

      for (const mb of m.matchBets) {
        for (const mbo of mb.mbOutcomes) {
          if (mbo.idMbo === s.stake_id) {
            stake = { ...mbo };
            stake.idMb = mb.idMb;
            stake.mboDisplayName = formatOddName(mb.idBet, mbo, m, null, language);
            break;
          }
        }
      }

      if (!stake && m.periods?.length) {
        // let's check it in match periods
        for (const period of m.periods) {
          for (const mb of period.matchBets) {
            for (const mbo of mb.mbOutcomes) {
              if (mbo.idMbo === s.stake_id) {
                stake = { ...mbo };
                stake.idMb = mb.idMb;
                stake.mboDisplayName = formatOddName(mb.idBet, mbo, m, period, language);
                break;
              }
            }
          }
        }
      }

      if (!stake) {
        hasError = true;
        break;
      }

      s.stake = stake;
      s.match = m;
      s.matchFormatted = {
        sportName: m.sportName,
        team1LogoUrl: crests(m.team1Name, n, 1),
        team1Name: m.team1Name,
        team2LogoUrl: crests(m.team2Name, n, 2),
        team2Name: m.team2Name,
        matchDateTimeString: formatDate(m.matchDateTime, language),
      };

      totalOdds *= stake.mboOddValue;
    }

    dates.sort((a, b) => a - b);
    const dateTxtArr = [];

    if (dates.length > 0) {
      if (dates[0]) {
        let dt;
        if (language === 'ro') {
          dt = moment(dates[0]).locale('ro');
        } else {
          dt = moment(dates[0]).locale('en');
        }

        dateTxtArr.push(dt.format('DD MMM'));
      }

      if (dates[dates.length - 1] && dates.length) {
        let ds;
        if (language === 'ro') {
          ds = moment(dates[dates.length - 1]).locale('ro');
        } else {
          ds = moment(dates[dates.length - 1]).locale('en');
        }
        dateTxtArr.push(ds.format('DD MMM'));
      }
    }

    n.index = n.number;
    n.dates = dateTxtArr.join(' - ');
    n.totalOdds = formatTotalOddsValue(totalOdds, true);
    n.totalOddsBoosted = formatTotalOddsValue(totalOdds * n.express_factor, true);

    if (hasError) {
      continue;
    }

    dayMultiBets.push(n);
  }

  dayMultiBets.sort((a, b) => a.priority - b.priority);

  return dayMultiBets;
};

const requestedMatches: any = {};

export const getPrematchMatches = (state: RootState) => state.bets.prematch.matches;
export const getConfig = (state: RootState) => state.bets.config;
export const getLanguage = (state: RootState) => state.application.language;

export const getMultiBets = createSelector([getConfig, getPrematchMatches, getLanguage], buildMultiBets);

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const MultiBetOfTheDate = (componentProps: MultiBetOfTheDateProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const dayMultiBets = useAppSelector((state) => getMultiBets(state));
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);
  const prematchBets = useAppSelector(
    (state) => state.bets.betsSlip.tickets?.[state.bets.betsSlip.currentTicket]?.prematch?.selected,
  );
  const liveBets = useAppSelector(
    (state) => state.bets.betsSlip.tickets?.[state.bets.betsSlip.currentTicket]?.live?.selected,
  );

  const doneFetch = React.useRef(false);

  const configMultiBetLoaded = useAppSelector((state) => state.bets.config.dayMultiBetsLoaded);
  const configMultiBet = useAppSelector((state) => state.bets.config.dayMultiBets);
  const prematchMatches: any = useAppSelector((state) => state.bets.prematch.matches);
  const fullPrematchStateLoaded = useAppSelector((state) => state.bets.prematch.fullStateLoaded);

  const [selected, setSelected] = React.useState(0);
  const [dialog, setDialog] = React.useState<any>({
    open: false,
    index: -1,
    data: null,
  });

  React.useEffect(() => {
    if (!doneFetch.current && fullPrematchStateLoaded && configMultiBet?.length) {
      doneFetch.current = true;
      console.log('MultiBetOfTheDate[useEffect]', configMultiBet);

      const eventsToLoad: { [index: string]: EventWithMarkets } = {};
      for (const dmb of configMultiBet) {
        // @ts-ignore
        for (const st of dmb.stakes) {
          // if (!prematchMatches[st.event_id] && requestedMatches[st.event_id] !== true) {
          let ev: EventWithMarkets;
          if (!(st.event_id in eventsToLoad)) {
            ev = eventsToLoad[st.event_id] = {
              id: st.event_id,
              marketIds: [],
            };
          } else {
            ev = eventsToLoad[st.event_id];
          }
          if (ev.marketIds && ev.marketIds.indexOf(st.bet_id) === -1) {
            ev.marketIds.push(st.bet_id);
          }
          // requestedMatches[st.event_id] !== true;
          // }
        }
      }

      const events: any = Object.keys(eventsToLoad);

      if (events.length) {
        // dispatch(prematchFetchMatches(events));
        // dispatch(fetchEventsWithMarkets(Object.values(eventsToLoad)));
        fetchEventsWithMarkets({ events: Object.values(eventsToLoad) });
      }
    }
  }, [configMultiBet, prematchMatches, fullPrematchStateLoaded]);

  React.useEffect(() => {
    let setTicket = false;

    if (!isWinnerFun && prematchBets.length && !liveBets.length) {
      const exists: any = {};

      prematchBets.forEach((bet: any) => {
        exists[`${bet.idSport}-${bet.idMatch}-${bet.idBet}-${bet.idMb}-${bet.idMbo}-${bet.idBo}`] = true;
      });

      dayMultiBets.find((multiBet: any, i: number) => {
        if (multiBet.stakes.length === prematchBets.length) {
          let isComplete = true;

          multiBet.stakes.forEach((ticket: any) => {
            const key = `${ticket.match.idSport}-${ticket.stake.idMatch}-${ticket.stake.idBet}-${ticket.stake.idMb}-${ticket.stake.idMbo}-${ticket.stake.idBo}`;
            if (!exists[key]) isComplete = false;
          });

          if (isComplete) {
            setSelected(multiBet.number);
            setTicket = true;
            return true;
          }
        }

        return false;
      });
    }

    if (!setTicket) {
      setSelected(0);
    }
  }, [dayMultiBets, isWinnerFun, liveBets, prematchBets]);

  const onMultiBetClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      if (e?.currentTarget?.dataset?.index) {
        const index = parseInt(e.currentTarget.dataset.index, 10);
        if (dayMultiBets?.length) {
          const multiBet = dayMultiBets.find((mb: any) => mb.number === index);
          if (multiBet) {
            dispatch(betsSlipLoadMultiBetDay(multiBet));
            setDialog({ open: false, index: -1, data: null });
          }
        }
      }
    },
    [dayMultiBets],
  );

  const onShowMore = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      if (e?.currentTarget?.dataset?.index) {
        const index = parseInt(e.currentTarget.dataset.index, 10);
        setDialog((v: any) => {
          if (v.open) {
            return { open: false, index: -1, data: null };
          }
          return { open: true, index: index, data: dayMultiBets[index] };
        });
      } else {
        setDialog({ open: false, index: -1, data: null });
      }
    },
    [dayMultiBets],
  );

  const contextValue = React.useMemo(() => {
    return {
      dayMultiBets,
      onMultiBetClick,
      onShowMore,
      dialogShow: dialog.open,
      dialogIndex: dialog.index,
      dialogData: dialog.data,
      selectedNumber: selected,
    };
  }, [dayMultiBets, onMultiBetClick, onShowMore, dialog, selected, isWinnerFun, liveBets, prematchBets]);

  //console.log('MultiBetOfTheDate[configMultiBet]', configMultiBet);

  if (configMultiBetLoaded && configMultiBet.length === 0) {
    return null;
  }

  if (!props.properties.showSkeleton && dayMultiBets.length === 0) {
    return null;
  }

  //console.log('MultiBetOfTheDate[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MultiBetOfTheDate;
