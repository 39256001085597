import axios from 'axios';
import { debug } from './index';

import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';

import { prematchMergeMatchesWithMarkets } from '../store/actions/prematch';
import { liveMergeMatchesWithMarkets } from '../store/actions/live';

import { digitainConfig } from '../api/config/digitain';
import { stdMatchStartTime } from './nsoft';
import { betsSlipPrematchMatchesLoaded } from '../store/actions/betsSlip';

const DEBUG = (localStorage.getItem('debug')?.indexOf('eventsFetcher') ?? -1) !== -1;

export type PeriodWithMarkets = {
  id: string;
  marketIds: string[];
};

export type EventWithMarkets = {
  id: string;
  mType?: string;
  marketIds?: string[];
  periods?: PeriodWithMarkets[];
};

export type FetchCallback = (events: any) => void;

export type EventFetchRequest = {
  events?: EventWithMarkets[];
  requestId?: string;
  cb?: FetchCallback;
};

type ProcessedEventFetchRequest = {
  id: string;
  events: EventWithMarkets[];
  requestId?: string;
  cb?: FetchCallback;
  scheduleTime?: number;
};

const inProgress: ProcessedEventFetchRequest[] = [];

export const fetchEventsWithMarkets = (request: EventFetchRequest) => {
  setTimeout(() => {
    _fetchEventsWithMarkets(request);
  }, 0);
};

const _fetchEventsWithMarkets = (request: EventFetchRequest) => {
  DEBUG && debug('new request', request);

  const { events: newEvents, requestId, cb } = request;

  const store = getStore();
  const state = store.getState();
  const bst = getBetsState(state);
  const { live, prematch } = bst;

  const toFetch: EventWithMarkets[] = [];

  for (const ne of newEvents || []) {
    DEBUG && debug('required event', ne);

    if (!ne.mType) {
      ne.mType = 'prematch';
    }

    if (ne.mType !== 'prematch' && ne.mType !== 'live') {
      console.error('bad mType', ne);
      continue;
    }

    let newMarkets: { [index: string]: string } = {};

    if (ne.marketIds && ne.marketIds.length > 0) {
      newMarkets = ne.marketIds.reduce((acc: any, m: string) => {
        acc[m] = true;
        return acc;
      }, {});
    }

    const stateMatches = ne.mType === 'live' ? live.matches : prematch.matches;

    // check if the event is already in state
    if (stateMatches[ne.id]) {
      DEBUG && debug(`event ${ne.id} already in state`);

      const sm = stateMatches[ne.id];

      // check if the requested markets are already in state
      if (ne.marketIds && ne.marketIds.length > 0) {
        for (const m of ne.marketIds) {
          const mb = sm.matchBets.find((mb: any) => mb.idBet === m);
          if (mb) {
            DEBUG && debug(`market ${ne.id}/${m} already in state`);
            delete newMarkets[m];
          }
        }
      }

      if (ne.periods && ne.periods.length > 0) {
        const newPeriods: PeriodWithMarkets[] = [];

        for (const p of ne.periods) {
          if (p.marketIds.length === 0) {
            console.error('period without markets', p);
            continue;
          }

          let periodMarkets: { [index: string]: string } = {};

          if (p.marketIds && p.marketIds.length > 0) {
            periodMarkets = p.marketIds.reduce((acc: any, m: string) => {
              acc[m] = true;
              return acc;
            }, {});
          }

          const sp = sm.periods.find((sp: any) => sp.idMatch === p.id);
          if (sp) {
            for (const m of p.marketIds) {
              const mb = sp.matchBets.find((mb: any) => mb.idBet === m);
              if (mb) {
                DEBUG && debug(`market ${ne.id}/${p.id}/${m} already in state`);
                delete periodMarkets[m];
              }
            }
          }

          const pm = Object.keys(periodMarkets).map((m) => m.toString());

          if (pm.length > 0) {
            newPeriods.push({
              id: p.id,
              marketIds: pm,
            });
          }
        }

        if (newPeriods.length > 0) {
          ne.periods = newPeriods;
        } else {
          delete ne.periods;
        }
      }
    } else {
      DEBUG && debug(`event ${ne.id} not in state`, stateMatches);
    }

    // check if any markets are left to fetch
    let nms = Object.keys(newMarkets);

    if (nms.length === 0) {
      DEBUG && debug(`no new markets for event ${ne.id}, all already in state`);
      continue;
    }

    for (const rip of inProgress) {
      const ep = rip.events.find((p) => p.id === ne.id);
      if (ep) {
        for (const m of nms) {
          if (ep.marketIds?.includes(m.toString())) {
            delete newMarkets[m];
          }
        }

        if (ne.periods && ne.periods.length > 0) {
          const newPeriods: PeriodWithMarkets[] = [];

          for (const p of ne.periods) {
            let periodMarkets: { [index: string]: string } = {};

            if (p.marketIds && p.marketIds.length > 0) {
              periodMarkets = p.marketIds.reduce((acc: any, m: string) => {
                acc[m] = true;
                return acc;
              }, {});
            }

            const pp = ep.periods?.find((pp) => pp.id === p.id);
            if (pp) {
              for (const m of p.marketIds) {
                if (pp.marketIds.includes(m)) {
                  delete periodMarkets[m];
                }
              }
            }

            const pm = Object.keys(periodMarkets).map((m) => m.toString());

            if (pm.length > 0) {
              newPeriods.push({
                id: p.id,
                marketIds: pm,
              });
            }
          }

          if (newPeriods.length > 0) {
            ne.periods = newPeriods;
          } else {
            delete ne.periods;
          }
        }
      }
    }

    nms = Object.keys(newMarkets);

    if (nms.length === 0) {
      DEBUG && debug(`no new markets for event ${ne.id}, all already in state/progress`);
      continue;
    }

    DEBUG && debug(`new markets for new request ${ne.id}`, nms);

    toFetch.push({
      id: ne.id,
      mType: ne.mType,
      marketIds: nms.map((m) => m.toString()),
      periods: ne.periods,
    });
  }

  if (toFetch.length > 0 || requestId || cb) {
    const newRequest: ProcessedEventFetchRequest = {
      id: window.crypto.randomUUID(),
      events: [],
    };

    if (toFetch.length > 0) {
      newRequest.events = toFetch;
    }

    if (requestId) {
      newRequest.requestId = requestId;
    }

    if (cb) {
      newRequest.cb = cb;
    }

    DEBUG && debug('new processed request', newRequest);

    execute(newRequest);
  }
};

async function execute(req: ProcessedEventFetchRequest) {
  DEBUG && debug('fetchEvents execute', req);

  const store = getStore();

  inProgress.push(req);

  const dCfg = digitainConfig();

  try {
    let events = [];

    if (req.events && req.events.length > 0) {
      const { data } = await axios.post(dCfg.apiURL + '/events-with-markets', { events: req.events });

      DEBUG && debug('fetched matches', data);

      events = data.data?.events || [];
      const live = events.filter((e: any) => e.mType === 'live');
      let prematch = events.filter((e: any) => e.mType === 'prematch');

      // if this is a betslip load request, filter matches that have already begun
      if (req.requestId && (req.requestId.indexOf('betslip') === 0 || req.requestId === 'favorites')) {
        DEBUG && debug('filtering betslip matches');

        const now = Date.now();

        prematch = prematch.filter((m: any) => {
          if (typeof m.idMatch !== 'undefined') {
            return stdMatchStartTime(m, null, 'matchDateTime') >= now;
          }
          return false;
        });
      }

      if (live.length) {
        store.dispatch(liveMergeMatchesWithMarkets(live));
      }

      if (prematch.length) {
        store.dispatch(prematchMergeMatchesWithMarkets(prematch));
      }
    }

    if (req.requestId && req.requestId.indexOf('betslip') === 0) {
      DEBUG && debug('betslip matches loaded', req.requestId);

      store.dispatch(betsSlipPrematchMatchesLoaded(req.requestId));
    }

    if (req.cb) {
      DEBUG && debug('calling cb', req);

      req.cb({
        events,
        requestId: req.requestId,
        dispatch: store.dispatch,
      });
    }
  } catch (error) {
    console.error('failed to fetch events with markets', error);
  } finally {
    inProgress.splice(inProgress.indexOf(req), 1);
  }
}
