import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { betsSlipConstants } from '@/modules/bets/store/actions/constants';
import store, { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import evBusMain from '@/utils/evbus';
import { MatchType } from '@/components/modules/bets/utils/types';
import { prematchFetchMatches } from '@/modules/bets/store/actions/prematch';
import { betsSlipToggle } from '@/modules/bets/store/actions/betsSlip';
import { cloneDeep, isEqual } from 'lodash-es';
import { EventWithMarkets, fetchEventsWithMarkets } from '@/modules/bets/utils/eventsFetcher';
import { pushUnique } from '@/modules/bets/utils';

//import './index.scss';

type CheckDProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const DEBUG = true;

const CheckD = (componentProps: CheckDProps) => {
  let props = componentProps;

  const [height, setHeight] = React.useState(100);
  const { i18n } = useTranslation();
  const [initialLanguage, setInitialLanguage] = React.useState<string | null>(i18n.language ?? 'en');
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);
  const selectedBets = useAppSelector((state) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket]);

  const addSelectionsToBetslip = React.useCallback((selections: any[]) => {
    const bst: any = store.getState().bets;
    const eventsById: { [index: string]: MatchType } = bst.prematch.matches;

    selections?.forEach?.((selection) => {
      const event = eventsById[selection.eventId];
      if (!event) return;

      let market = event.matchBets?.find((market: any) => market.idBet == selection.marketId);
      if (!market) {
        event?.periods?.find?.((period: any) => {
          market = period.matchBets?.find((market: any) => market.idBet == selection.marketId);
          if (market) return true;
          return false;
        });

        if (!market) return;
      }

      const outcome = market.mbOutcomes?.find((outcome: any) => outcome.idMbo == selection.selectionId);
      if (!outcome) return;

      let m = null;

      if (selection.eventId in bst.prematch.matches) {
        m = bst.prematch.matches[selection.eventId];
      }

      if (!m) return;
      // const clonedMatch = cloneDeep(m);

      //store.dispatch(betsSlipLoadMultiBetDay(event, market)
      // store.dispatch({
      //   type: betsSlipConstants.TOGGLE,
      //   mType: m.mType,
      //   idSport: m.idSport,
      //   idMatch: m.idMatch,
      //   idBet: market.idBet,
      //   idMb: `${m.idMatch}/${market.idBet}`,
      //   idBo: outcome.idBo,
      //   idMbo: outcome.idMbo,
      //   match: clonedMatch,
      //   matches: {
      //     live: bst.live.matches,
      //     prematch: bst.prematch.matches,
      //   },
      //   combiningAllowed: bst.nsoft.combiningAllowed,
      // });
      store.dispatch(
        betsSlipToggle(
          m.mType,
          m.idSport,
          m.idMatch,
          market.idBet,
          `${m.idMatch}/${market.idBet}`,
          outcome.idBo,
          outcome.idMbo,
        ),
      );
    });
  }, []);

  const oldBets = React.useRef<any[]>([]);
  React.useEffect(() => {
    console.log('selectedBets', selectedBets);

    const bets: any[] = [];
    selectedBets?.prematch?.selected?.forEach((bet: any) => {
      bets.push({
        eventId: bet.idMatch,
        marketId: bet.idBet,
        selectionId: bet.idMbo,
      });
    });

    if (isEqual(bets, oldBets.current)) {
      return;
    }
    oldBets.current = bets;

    const iframe = document.getElementById(props.properties?.iframeId ?? 'checkdFrame') as HTMLIFrameElement;
    if (iframe?.contentWindow != null) {
      const messageData = {
        type: 'UpdateToBetslip',
        payload: {
          selections: bets,
        },
      };

      DEBUG && console.log('CheckD[bets selected message]', messageData);
      iframe.contentWindow.postMessage(messageData, window.location.origin);
    }
  }, [selectedBets]);

  const checkdReceivedMessage = (event: MessageEvent) => {
    if (event.origin !== window.config.checkDUrl) {
      return;
    }
    DEBUG && console.log('checkdReceivedMessage', event.data);

    const data = event.data;

    switch (data?.type) {
      case 'HeightChanged':
        if (data?.payload?.height) setHeight(data.payload.height);
        DEBUG && console.log('HeightChanged', data?.payload?.height);
        break;
      case 'NavigateToEvent':
        DEBUG && console.log('NavigateToEvent', data?.payload);

        const prematchMatches: { [id: string]: MatchType } = store.getState().bets.prematch.matches;
        const payload = data?.payload;

        if (!payload?.eventId) return;

        if (prematchMatches?.[payload?.eventId] && prematchMatches?.[payload?.eventId].idSport == payload?.sportId) {
          const match = prematchMatches?.[payload?.eventId];
          const url = `/bets/match/pre-match/${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`;
          if (url) evBusMain.emit('route-change', { url: url });
        } else {
          store.dispatch(
            prematchFetchMatches(
              [payload?.eventId],
              null,
              null,
              null,
              (matches: any, data: any) => {
                const match = matches?.[0];
                if (match && match.idMatch == data?.eventId) {
                  const url = `/bets/match/pre-match/${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`;
                  if (url) evBusMain.emit('route-change', { url: url });
                }
              },
              payload,
            ),
          );
        }
        break;
      case 'AddSelections':
        const selections = data?.payload?.selections;

        DEBUG && console.log('AddSelections', selections);

        // const events = [];
        // const missingEvents = [];
        // for (const selection of selections) {
        //   if (selection?.eventId) {
        //     const prematchMatches: { [id: string]: MatchType } = store.getState().bets.prematch.matches;
        //     if (prematchMatches?.[selection?.eventId]?._loaded) {
        //       events.push(prematchMatches?.[selection?.eventId]);
        //     } else {
        //       missingEvents.push(selection?.eventId);
        //     }
        //   }
        // }

        // if (missingEvents.length) {
        //   store.dispatch(
        //     prematchFetchMatches(
        //       missingEvents,
        //       null,
        //       null,
        //       null,
        //       (matches: any, data: any) => {
        //         const allMatches = [...(matches ?? []), ...data.events];
        //         addSelectionsToBetslip(allMatches, selections);
        //       },
        //       { selections, events },
        //     ),
        //   );
        // } else {
        //   addSelectionsToBetslip(events, selections);
        // }

        const events: { [index: string]: EventWithMarkets } = {};

        for (const sel of selections) {
          let ev: EventWithMarkets;
          if (sel.eventId in events) {
            ev = events[sel.eventId];
          } else {
            ev = {
              id: sel.eventId,
              mType: 'prematch',
              marketIds: [],
            };
            events[sel.eventId] = ev;
          }
          pushUnique(ev.marketIds, sel.marketId);
        }

        fetchEventsWithMarkets({
          events: Object.values(events),
          cb: () => {
            addSelectionsToBetslip(selections);
          },
        });

        break;
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', checkdReceivedMessage, false);

    return () => {
      window.removeEventListener('message', checkdReceivedMessage, false);
    };
  }, []);

  const oldLanguage = React.useRef<string | null>(initialLanguage);
  React.useEffect(() => {
    if (oldLanguage.current !== i18n.language) {
      const iframe = document.getElementById(props.properties?.iframeId ?? 'checkdFrame') as HTMLIFrameElement;
      if (iframe?.contentWindow != null) {
        const messageData = {
          type: 'ChangeLanguage',
          payload: {
            language: i18n.language,
          },
        };

        DEBUG && console.log('CheckD[Language change message]', messageData);
        iframe.contentWindow.postMessage(messageData, window.location.origin);
      }
      oldLanguage.current = i18n.language;
    }
  }, [i18n.language, props.properties?.iframeId]);

  const contextValue = React.useMemo(() => {
    return {
      url: `${window.config.checkDUrl}?brand=${window.config.checkDBrand}&lang=${initialLanguage}&bookmaker=digitain`,
      height,
      iframeId: props.properties?.iframeId ?? 'checkdFrame',
    };
  }, [dataElementContext, componentProps, height, initialLanguage]);

  if (window.config.useCheckD !== '1') return null;
  if (!window.config.checkDUrl) return null;
  if (!window.config.checkDBrand) return null;

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default CheckD;
