import { isEqual } from 'lodash-es';

import { BetslipBetType, MatchType } from '@/components/modules/bets/utils/types';
import { betsRootReducer } from '@/modules/bets/store/reducers';
import { RecommendedBetsState } from '.';

interface Motivation {
  text: Record<string, string>;
}

export interface RecommendedMatchType extends MatchType {
  brmId: string;
  brmIdMb: string;
  brmIdMbo: string;
  brmMotivations: Motivation[];
  brmNumbers: string;
  brmPeriodIdMatch: string;
}

const languages: Record<string, string> = window.config.sportsLanguagesMap;

const FORMAT_PATTERN = /{([^}]+)}/g;

export function replaceParams(
  text: string,
  data: Record<string, any>,
  languageId: string,
  replacePattern = FORMAT_PATTERN,
): string {
  return text.replace(replacePattern, (placeholder, param) => data[param][languages[languageId]] ?? '');
}

export function formatMotivations(match: RecommendedMatchType): RecommendedMatchType {
  const brmMotivations: Motivation[] = [];

  match.brmMotivations.forEach((brmMotivation) => {
    const motivation: Motivation = {
      text: {},
    };

    Object.keys(brmMotivation.text).forEach((language) => {
      motivation.text[language] = replaceParams(brmMotivation.text[language], match, language);
    });

    brmMotivations.push(motivation);
  });

  return {
    ...match,
    brmMotivations,
  };
}

export function isMatchAdded(
  betsSlip: ReturnType<typeof betsRootReducer>['betsSlip'],
  idMatch: string,
  idMb: string,
  idMbo: string,
): boolean {
  const currentTicket = betsSlip.tickets[betsSlip.currentTicket];

  const isMatchAdded = [...currentTicket.live.selected, ...currentTicket.prematch.selected].find(
    (bet: BetslipBetType) => bet.idMatch === idMatch && bet.idMb === idMb && bet.idMbo === idMbo,
  );

  return !!isMatchAdded;
}

export function crossListsFilter(match: RecommendedMatchType): (match: RecommendedMatchType) => boolean {
  return ({ idMatch, brmIdMb, brmIdMbo }: RecommendedMatchType) =>
    idMatch === match.idMatch && brmIdMb === match.brmIdMb && brmIdMbo === match.brmIdMbo;
}

export function getBetSlipList(
  betsSlip: ReturnType<typeof betsRootReducer>['betsSlip'],
  originalList: RecommendedMatchType[],
): RecommendedMatchType[] {
  return originalList.filter((match) => isMatchAdded(betsSlip, match.idMatch, match.brmIdMb, match.brmIdMbo));
}

export function getFullList(
  originalList: RecommendedMatchType[],
  betsSlipList: RecommendedMatchType[],
  newList: RecommendedMatchType[] = [],
): RecommendedMatchType[] {
  const filtered = originalList.filter((match) => !betsSlipList.find(crossListsFilter(match)));

  return newList.length ? [...filtered, ...newList] : filtered;
}

export function getCommonStateSlice(
  prevState: RecommendedBetsState,
  newList: RecommendedMatchType[],
  pageSize: number,
): RecommendedBetsState {
  const lastPage = newList.length ? Math.ceil(newList.length / pageSize) - 1 : 0;
  const nextPage = lastPage < prevState.currentPage ? 0 : prevState.currentPage;
  const pageStartIndex = nextPage * pageSize;

  return {
    ...prevState,
    currentPage: nextPage,
    lastPage,
    currentList: newList.slice(pageStartIndex, pageStartIndex + pageSize),
    displayCount: `(${newList.length})`,
    showLoader: newList.length > 1 && newList.length > pageSize,
  };
}

// On state updates, match bets and outcomes are pulled from Redux state.
// If any of the bets are added in bet slip, the selected property changes,
// which triggers diff between old list and the new list of matches
function resetOutcomeProperty(list: RecommendedMatchType[]): RecommendedMatchType[] {
  return list.map((match) => ({
    ...match,
    outcomes: match.outcomes.map((outcome) => ({
      ...outcome,
      selected: false,
    })),
  }));
}

export function isListEqual(a: RecommendedMatchType[], b: RecommendedMatchType[]): boolean {
  return isEqual(resetOutcomeProperty(a), resetOutcomeProperty(b));
}

export function matchListToMap(list: MatchType[]): Record<string, MatchType> {
  const map: Record<string, MatchType> = {};

  list.forEach((match) => (map[match.idMatch] = match));

  return map;
}

export function getBetData(
  prematchMatches: Record<string, MatchType> | MatchType[],
  idMatch: string,
  periodIdMatch: string | undefined,
  idMb: string,
  idMbo: string,
) {
  const matches = Array.isArray(prematchMatches) ? matchListToMap(prematchMatches) : prematchMatches;

  let match: MatchType | undefined = matches[idMatch];

  if (match && periodIdMatch && periodIdMatch.length) {
    match = match.periods.find((period) => period.idMatch === periodIdMatch);
  }

  const matchBet = match?.matchBets.find((matchBet) => matchBet.idMb === idMb);

  const matchBetOutcome = matchBet?.mbOutcomes.find((mbOutcome) => mbOutcome.idMbo === idMbo);

  return { match, matchBet, matchBetOutcome };
}

export function stripRecommendedMatch(recommendedMatch: RecommendedMatchType): MatchType {
  const { brmId, brmIdMb, brmIdMbo, brmMotivations, brmNumbers, brmPeriodIdMatch, ...match } = recommendedMatch;

  return match;
}

export function groupMatches(
  dataSourceMatches: MatchType[] = [],
  stateMatches: Record<string, MatchType> = {},
  processedMatches: Record<string, boolean> = {},
): MatchType[] {
  if (dataSourceMatches.length === 0) {
    return [];
  }

  try {
    const newMatches: Record<string, MatchType> = {};

    // Get list of unique IDs
    const allMatchIds = dataSourceMatches
      .filter((match) => !processedMatches[match.idMatch])
      .map((match) => match.idMatch);
    const uniqueMatchIds = Array.from(new Set(allMatchIds));

    if (uniqueMatchIds.length === 0) {
      return [];
    }

    // Check if some of the recommendations are already in state and push them to the list, in order to merge match bets
    uniqueMatchIds.forEach((idMatch) => {
      if (stateMatches[idMatch]) {
        dataSourceMatches.push(stateMatches[idMatch]);
      }
    });

    // Iterate and group the matches from dataSource and state
    dataSourceMatches.forEach((match) => {
      const existingMatch = newMatches[match.idMatch];

      if (existingMatch) {
        const newMatchBets = Object.fromEntries(existingMatch.matchBets.map((matchBet) => [matchBet.idMb, matchBet]));

        match.matchBets.forEach((matchBet) => {
          const existingMatchBet = newMatchBets[matchBet.idMb];

          if (existingMatchBet) {
            const newMatchBetOutcomes = Object.fromEntries(
              existingMatchBet.mbOutcomes.map((matchBetOutcome) => [matchBetOutcome.idMbo, matchBetOutcome]),
            );

            matchBet.mbOutcomes.forEach((matchBetOutcome) => {
              const existingMatchBetOutcome = newMatchBetOutcomes[matchBetOutcome.idMbo];

              if (existingMatchBetOutcome) {
                /// do nothing
              } else {
                newMatchBetOutcomes[matchBetOutcome.idMbo] = matchBetOutcome;
              }
            });

            newMatchBets[matchBet.idMb].mbOutcomes = Object.values(newMatchBetOutcomes);
          } else {
            newMatchBets[matchBet.idMb] = { ...matchBet };
          }
        });

        newMatches[match.idMatch].matchBets = Object.values(newMatchBets);

        newMatches[match.idMatch].periods = [...existingMatch.periods, ...match.periods];
      } else {
        newMatches[match.idMatch] = stripRecommendedMatch(match as RecommendedMatchType);
      }
    });

    Object.keys(newMatches).forEach((idMatch) => {
      newMatches[idMatch].periods = groupMatches(newMatches[idMatch].periods, stateMatches, processedMatches);
    });

    return Object.values(newMatches);
  } catch (error) {
    console.log(error);

    return [];
  }
}
