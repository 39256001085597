import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { useAppDispatch, useAppSelector } from '@/store';
import { fetchWheelsDataSource } from '@/store/slices/wheelsDataSource';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

//import './index.scss';

type PromoBubbleProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    dsId: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
    dsId: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PromoBubble = (componentProps: PromoBubbleProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const apiUrl = window.config.betsApiUrl + '/wheel/player';

  const authentication = useAppSelector((state) => state.authentication);
  const dataSources = useAppSelector((state) => state.dataSources.items);
  const promotionsAPI = props?.properties?.dsId ? dataSources[props?.properties?.dsId] : null;

  const [showPromoBubble, setShowPromoBubble] = React.useState<boolean>(false);
  const [promoBubbleData, setPromoBubbleData] = React.useState<any>(null);
  const [showChest, setShowChest] = React.useState<any>(false);
  const [loggedIn, setLoggedIn] = React.useState<any>(false);

  React.useEffect(() => {
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      setLoggedIn(false);
      setShowChest(false);
      if (promotionsAPI?.data?.length) {
        if (promotionsAPI?.data?.length <= 2) {
          promotionsAPI.data.forEach((promotion: any) => {
            const { metadata_product_display_scope } = promotion;
            const displayScope = metadata_product_display_scope[0];
            if ((location?.pathname?.includes('bet') && displayScope === 'SPORTBOOK') || displayScope === 'CASINO') {
              setPromoBubbleData(promotion);
            }
          });
        } else {
          console.error('An error has occurred in Welcome Bonus data source');
          setShowPromoBubble(false);
        }
      }
    } else {
      setLoggedIn(true);
      dispatch(fetchWheelsDataSource({ id: 'player_wheel_setups' }));
      Axios.get(apiUrl + '/status?scope=MYSTERY_CHEST', {
        headers: {
          Authorization: 'Bearer ' + authentication.access_token,
        },
      })
        .then((response) => {
          if (
            response?.data?.status === 'available' ||
            (response?.data?.status === 'not_available' && response?.data?.free_spin?.next_spin_after === null)
          ) {
            setShowChest(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [apiUrl, authentication, dispatch, promotionsAPI, location]);

  const contextValue = React.useMemo(() => {
    return {
      showPromoBubble,
      promoBubbleData,
      loggedIn,
      showChest: showChest,
      togglePromoBubble: () => setShowPromoBubble(!showPromoBubble),
    };
  }, [showPromoBubble, showChest, loggedIn, promoBubbleData]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PromoBubble;
