import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { logout } from '../../../modules/casino/store/actions/login';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';

import './index.scss';

const ACCOUNT_SUSPENDED = 21;
const ACCOUNT_CLOSED = 22;
const ACCOUNT_SELF_EXCLUDED = 23;

const AccountProblem = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      authentication?.accountProblemCode === ACCOUNT_CLOSED ||
      authentication?.accountProblemCode === ACCOUNT_SELF_EXCLUDED
    ) {
      dispatch(logout());
      navigate('/profile/take-a-break/self-excluded');
    }
  }, [authentication.accountProblemCode, dispatch, navigate]);

  const onClose = () => {
    dispatch(logout());
    navigate('/');
  };

  const supportHandler = () => {
    dispatch(logout());
    navigate('/customer-support/contact');
  };

  const getHeaderAdditions = (code: number) => {
    switch (code) {
      case ACCOUNT_SUSPENDED:
        return t('Suspended account');
      case ACCOUNT_CLOSED:
        return t('Closed account');
      case ACCOUNT_SELF_EXCLUDED:
        return t('Self excluded account');
      default:
        return t('Account problem');
    }
  };

  const getMessageAdditions = (code: number) => {
    let htmlMessage = (
      <React.Fragment>
        {window?.config?.siteId === '100' && window?.config?.clientId === '201' && (
          <i className="user-icon fi fi-user-male" />
        )}
        <span>{t(ResponseErrorMessages.get(authentication.accountProblemCode))}</span>
      </React.Fragment>
    );

    if (ACCOUNT_SELF_EXCLUDED === code) {
      if (authentication.accountProblemMessage) {
        const exclusionInterval = authentication.accountProblemMessage.split('-');
        htmlMessage = (
          <React.Fragment>
            <span>
              {t('Self exclusion period') + ' ' + t('from') + ' '}
              {moment.unix(exclusionInterval[0]).format('DD/MM/YYYY')}
              {' ' + t('to') + ' '}
              {moment.unix(exclusionInterval[1]).format('DD/MM/YYYY')}
            </span>
            <br />
            <span>{t('If you want to cancel this action, please contact support.')}</span>
          </React.Fragment>
        );
      }
    }

    return htmlMessage;
  };

  if (!authentication.accountProblemModal) return null;

  return (
    <Modal isOpen={true} toggle={onClose} className="account-problem">
      <ModalHeader toggle={onClose} close={<i className="btn-close bi bi-x" onClick={onClose} />}>
        {getHeaderAdditions(authentication.accountProblemCode)}
      </ModalHeader>
      <ModalBody>{getMessageAdditions(authentication.accountProblemCode)}</ModalBody>
      <ModalFooter>
        {authentication.accountProblemCode !== ACCOUNT_SUSPENDED && (
          <Button color="primary" onClick={onClose}>
            {t('Close')}
          </Button>
        )}
        <Button color="secondary" onClick={supportHandler}>
          {authentication.accountProblemCode !== ACCOUNT_SUSPENDED ? t('Open support') : t('Go to Help Center')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountProblem;
