import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import {
  momentumProgressDetails,
  starThresholds,
  detectBrowser,
  triggerGTMEvent,
} from '../../../../modules/momentum/utils/functions.js';
import {
  getMomentumBonuses,
  forfeitMomentumBonus,
  clearMomentumState,
  setMomentumSource,
} from '../../../../modules/momentum/store/actions/momentum';
import { exitSlotGame } from '../../../action-handler/actions';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import { useMediaQuery } from '../../../utils/useQueryMedia';
import { setInGameHeaderSelection } from '@/modules/casino/store/actions/application';

import './index.scss';
import { FeatureCategory, FeatureSubcategory, logEvent, trackFunction } from '@/analytics';
import { FeatureLocation } from '@/analytics/types/eventData';

type MomentumGameHeaderProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const MomentumGameHeader = (componentProps: MomentumGameHeaderProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const prize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumStatus = useAppSelector<any>((state) => state.momentum.prize?.momentumDown);
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum?.prize?.loaded);
  const momentumPrizeLoading = useAppSelector<any>((state) => state.momentum?.prize?.loading);
  const source = useAppSelector<any>((state) => state.momentum.prize.source);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const authentication = useAppSelector((state) => state.authentication);

  const initialStarProgress = momentumProgressDetails(prize?.wager_progress ?? 0, starThresholds);
  const isNoAnim = detectBrowser();

  const [state, setState] = React.useState({
    prize: prize,
    expired: false,
    showStartDialog: source === 'ws' && prize?.status !== 'wagered',
    showInfoDialog: false,
    showWonDialog: false,
    showWarningDialog: false,
    showOverlay: false,
    showExpired: false,
    showWarning: false,
    progressAnimation: false,
    starProgress: initialStarProgress.stars,
  });
  const tid = React.useRef<any>(0);
  const tidStart = React.useRef<any>(0);
  const tidExpired = React.useRef<any>(0);

  const idleTime = 10000;
  const expiredTime = 5000;

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  React.useEffect(() => {
    setState((v) => {
      const showWonDialog = prize?.status === 'wagered';
      const showStartDialog = source === 'ws' && !showWonDialog;
      let showExpired = prize?.status === 'expired';
      const showWarning = momentumStatus === true;

      let expired = false;

      if (prize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
        expired = now > prizeExpiresAt;
        if (expired) showExpired = true;
      }

      return {
        ...v,
        expired,
        prize,
        showWonDialog,
        showStartDialog,
        showWarning,
        showExpired,
        showOverlay: false,
      };
    });

    if (tid.current) clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      setState((v) => {
        let expired = false;

        if (prize?.bonus_expires_at) {
          const now = new Date().valueOf();
          const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
          expired = now > prizeExpiresAt;
        }

        if (expired) {
          return v;
        }

        return {
          ...v,
          showOverlay: true,
        };
      });
    }, idleTime);
  }, [prize, source, momentumStatus]);

  React.useEffect(() => {
    if (state.showStartDialog) {
      triggerGTMEvent({ event: 'tw-intro-screen-displayed' });
      logEvent(
        null,
        {
          feature_used: 'turboCashPresentRewardNotification',
          feature_category: FeatureCategory.MomentumTurboCash,
          feature_subcategory: FeatureSubcategory.SystemEvent,
          feature_location: FeatureLocation.InGameNotification,
        },
        {
          event_name: 'tw-intro-screen-displayed',
          event_details: {
            state: state,
          },
        },
      );
      tidStart.current = setTimeout(() => {
        onCloseStartDialog();
        triggerGTMEvent({ event: 'tw-intro-screen-auto-dismissed' });
        logEvent(
          null,
          {
            feature_used: 'turboCashRewardAutoClose',
            feature_category: FeatureCategory.MomentumTurboCash,
            feature_subcategory: FeatureSubcategory.SystemEvent,
            feature_location: FeatureLocation.InGameNotification,
          },
          {
            event_name: 'tw-intro-screen-auto-dismissed',
            event_details: {
              state: state,
            },
          },
        );
      }, 5000);
    } else {
      clearTimeout(tidStart.current);
    }
  }, [state.showStartDialog]);

  React.useEffect(() => {
    if (state.showWonDialog) {
      triggerGTMEvent({ event: 'tw-won-screen-displayed' });
      logEvent(
        null,
        {
          feature_used: 'turboCashPresentWinNotification',
          feature_category: FeatureCategory.MomentumTurboCash,
          feature_subcategory: FeatureSubcategory.SystemEvent,
          feature_location: FeatureLocation.InGameNotification,
        },
        {
          event_details: {
            state: state,
          },
        },
      );
    }
  }, [state.showWonDialog]);

  const handleExpired = React.useCallback(() => {
    setState((v) => ({
      ...v,
      expired: true,
      showExpired: true,
    }));
  }, []);

  React.useEffect(() => {
    if (state.showExpired) {
      if (tidExpired.current) clearTimeout(tidExpired.current);
      tidExpired.current = setTimeout(() => {
        dispatch(clearMomentumState(true));
      }, expiredTime);
      triggerGTMEvent({ event: 'tw-time-up' });
      logEvent(
        null,
        {
          feature_used: 'time-up',
          feature_category: FeatureCategory.MomentumTurboCash,
          feature_subcategory: FeatureSubcategory.SystemEvent,
          feature_location: FeatureLocation.InGameNotification,
        },
        {
          event_details: {
            progress: starProgress.progress,
            stars: starProgress.stars,
          },
        },
      );
    }

    return () => {
      if (tidExpired.current) clearTimeout(tidExpired.current);
    };
  }, [state.showExpired]); // eslint-disable-line

  const handleProgressAnimationEnd = () => {
    const starProgress = momentumProgressDetails(state.prize?.wager_progress ?? 0, starThresholds);
    setState((v) => {
      if (starProgress.stars === v.starProgress) {
        return v;
      }

      return {
        ...v,
        progressAnimation: false,
        starProgress: starProgress.stars,
      };
    });
    triggerGTMEvent({ event: 'tw-level-done' });
  };

  React.useEffect(() => {
    const starProgress = momentumProgressDetails(state.prize?.wager_progress ?? 0, starThresholds);
    if (starProgress.stars !== state.starProgress && !state.progressAnimation) {
      setState((v) => ({
        ...v,
        progressAnimation: true,
      }));
      logEvent(
        null,
        {
          feature_used: 'turboCashStarLevelUp',
          feature_category: FeatureCategory.MomentumTurboCash,
          feature_subcategory: FeatureSubcategory.SystemEvent,
          feature_location: FeatureLocation.InGameNotification,
        },
        {
          event_details: {
            progress: starProgress.progress,
            stars: starProgress.stars,
          },
        },
      );
    }
    try {
      if (window.location.pathname.startsWith('/play/')) {
        const parts = window.location.pathname.split('/');
        const gameId = parseInt(parts[parts.length - 1], 10);
        localStorage.setItem('momentumGameId', gameId.toString());
      }
    } catch (e) {}
  }, [state.prize?.wager_progress, state.starProgress, state.progressAnimation]);

  const onShowInfoDialog = () => {
    setState((v) => {
      let showInfoDialog = true;
      let showWarningDialog = false;

      if (v.showWarning) {
        showInfoDialog = false;
        showWarningDialog = true;
      }

      return {
        ...v,
        showInfoDialog: showInfoDialog,
        showWarningDialog: showWarningDialog,
      };
    });
    triggerGTMEvent({ event: 'tw-info-click' });
  };
  const onCloseInfoDialog = () => {
    setState((v) => ({
      ...v,
      showInfoDialog: false,
      showWarningDialog: false,
    }));
  };

  const onOpenWonDialog = () => {
    setState((v) => ({
      ...v,
      showWonDialog: true,
    }));
  };

  const onCloseWonDialog = () => {
    dispatch(clearMomentumState(true));
    setState((v) => ({
      ...v,
      showWonDialog: false,
    }));
  };

  const onOpenStartDialog = () => {
    setState((v) => ({
      ...v,
      showStartDialog: true,
    }));
  };
  const onCloseStartDialog = () => {
    dispatch(setMomentumSource(''));
    setState((v) => ({
      ...v,
      showStartDialog: false,
    }));
    triggerGTMEvent({ event: 'tw-main-screen' });
  };

  const onSwitchToWidget = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(setInGameHeaderSelection(''));
  };

  React.useEffect(() => {
    if (prize?.uuid && source !== 'ws') {
      triggerGTMEvent({ event: 'tw-main-screen' });
    }
  }, []); // eslint-disable-line

  const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;

  const closeGame = () => {
    triggerGTMEvent({ event: 'tw-click-close' });

    exitSlotGame(navigate);
  };

  const showDeposit = (e: any) => {
    if (e) e.stopPropagation();
    triggerGTMEvent({ event: 'tw-deposit-click' });
    navigate('/deposit');
  };

  const onCancelBonus = () => {
    if (momentumPrizeLoading) return;
    dispatch(forfeitMomentumBonus(prize?.uuid ?? 0));
    triggerGTMEvent({ event: 'tw-info-cancel-bonus' });
  };

  const starProgress = momentumProgressDetails(state.prize?.wager_progress ?? 0, starThresholds);

  const stars = [...Array(starThresholds.length).keys()].map((index) => {
    return {
      active: index < state.starProgress,
    };
  });

  const value = toFixedWithoutZeros((prize?.reward?.value || 0) / 100, 2);
  const currency = formatCurrency(prize?.reward?.currency || '');
  const progress = starProgress?.progress ?? 0;

  const contextValue = {
    state,
    handleExpired,
    handleProgressAnimationEnd,
    onShowInfoDialog: trackFunction(onShowInfoDialog, {
      feature_used: 'turboCashInfoShow',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),

    onCloseInfoDialog: trackFunction(onCloseInfoDialog, {
      feature_used: 'turboCashInfoClose',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),

    onOpenWonDialog,
    onCloseWonDialog: trackFunction(onCloseWonDialog, {
      feature_used: 'turboCashWinDialogClose',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),
    onOpenStartDialog,
    onCloseStartDialog: trackFunction(onCloseStartDialog, {
      feature_used: 'turboCashStartDialogClose',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),
    onCancelBonus: trackFunction(onCancelBonus, {
      feature_used: 'turboCashCancelBonus',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),
    toFixedWithoutZeros,
    closeGame: trackFunction(closeGame, {
      feature_used: 'turboCashClose',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),

    showDeposit: trackFunction(showDeposit, {
      feature_used: 'turboCashDeposit',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),
    starProgress,
    stars,
    value,
    currency,
    progress,
    isDesktop,
    isNoAnim,
    onSwitchToWidget: trackFunction(onSwitchToWidget, {
      feature_used: 'turboCashWidgetSwitch',
      feature_category: FeatureCategory.MomentumTurboCash,
      feature_subcategory: FeatureSubcategory.SystemEvent,
      feature_location: FeatureLocation.InGameNotification,
    }),
    momentumPrizeLoading,
  };

  // console.log('MomentumGameHeader[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MomentumGameHeader;
