import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
// import { takeEveryWithCooldown } from "./effects";
import { authenticateAppSaga, checkAuthTimeoutSaga, refreshAuthTokenSaga } from './authentication';
import {
  changeLanguageSaga,
  claimBonusSaga,
  getBonusAvailableSaga,
  getBonusCalculatorSaga,
  getBonusesSaga,
  getEligibleBonusesSaga,
  getPromotionsSaga,
  routeListenerSaga,
 generateFingerPrintSaga } from './application';
import {
  loginPasswordSaga,
  loginUsernameSaga,
  logoutSaga,
  requestSmsSaga,
  requestUserAuthentication,
  stepChangeSmsRequest,
  updateSmsEta,
} from './login';
import { authRequestWalletSaga, receivedRemovedBonusSaga, requestRemoveBonusSaga, requestWalletSaga } from './wallet';
import * as actionTypes from './../actions/actionTypes';
import { registerSaga, sendRegisterSMSSaga, setPartialAccountSaga, validateFieldSaga } from './register';
import { resetPasswordSaga, resetPasswordSendEmailSaga } from './resetPassword';
import {
  authRequestAccountSaga,
  changePasswordSaga,
  checkPasswordSaga,
  checkPhoneCodeSaga,
  checkPhoneSaga,
  getChatInfoSaga,
  requestAccountSaga,
  requestLimitsSaga,
  resendVerificationEmailSaga,
  selfExcludeSaga,
  sendSelfExclusionCodesSaga,
  setNewLimitSaga,
  setSelfexclusionSaga,
} from './profile';
import { requestTransactionsHistorySaga } from './transactions';

import {
  requestBPCardTokenSaga,
  requestBPWithdrawSaga,
  requestPaymentLinkSaga,
  requestVivaCardTokenSaga,
  requestVivaChargeTokenSaga,
  requestVivaChargeTokenWithCardSaga,
  requestVivaTokenSaga,
  requestVivaWithdrawSaga,
  sendVivaTokenSaga,
} from './paymentCheckout';

import { requestWithdrawalLinkSaga } from './withdrawalCheckout';
import {
  betlionWithdrawDetailsSaga,
  downloadCSVSaga,
  getPaymentMethodsSaga,
  getWithdrawTaxSaga,
  linkIBANSaga,
  requestCancelWithdrawalSaga,
  requestLocationsSaga,
  requestPendingWithdrawalsSaga,
  requestTaxServiceSaga,
  requestVerificationCodeSaga,
  requestWithdrawHistorySaga,
  withdrawSaga,
} from './withdrawals';
import {
  authRequestDocumentsSaga,
  requestDocumentsMaxFileSizeSaga,
  requestDocumentsSaga,
  requestDocumentsUploadSaga,
} from './documents';
import {
  closeGameLauncherSaga,
  closeRedirectGameLauncherSaga,
  openGameLauncherSaga,
  reloadWalletsDataSaga,
  requestGameCollectionsSaga,
  requestGameUrlSaga,
} from './games';
import { getLocationsSaga, getTaxSaga, sendWithdraw } from './withdraw_wizard';
import { requestGameRulesSaga, sendComplaintFormSaga } from './customer_support';
//import { cycleRequestJackpotSaga } from './jackpot';
import { websocketPlayerLoginSaga, websocketPlayerLogoutSaga } from './ws';
import watchHappyHourSaga from './happy_hour';
import watchConfigSaga from './config';

import { betsSaga } from '../../../bets/store/sagas';
import { virtualsSaga } from '../../../virtuals/store/sagas';
import { lottoSaga } from '../../../lotto/store/sagas';

import { winnerFunSaga } from '../../../winner-fun/store/sagas';

import { liveCasinoSaga } from '../../../live-casino/store/sagas';
import freeBetsSaga from './free_bets';
import freeSpinsSaga from './free_spins';
import druid from './druidSaga';
import alertsSaga from './alerts';

import watchVoucherSaga from './voucherCode';

import { wheelSaga } from '../../../wheel/store/sagas';
import { promoSaga } from '../../../promo/store/sagas';
import { promoCalendarSaga } from '../../../promo-calendar/store/sagas';

import { tournamentsMissionsSaga } from '../../../tournaments-missions/store/sagas';

import { lobbySaga } from '../../../lobby/store/sagas';

import { takeEveryWithCooldown } from './effects';
import { getMarketingOfferSaga } from './marketing_offer';

import { depositTicketSaga } from '../../../deposit-ticket/store/sagas';
import watchDeposit from './deposit';

import { momentumSaga } from '../../../momentum/store/sagas';

import { rootStoreSaga } from '@/store/sagas';
import watchRewardBundle from '@/modules/casino/store/sagas/reward_bundle';

export function* watchAuth() {
  yield takeEvery(actionTypes.authentication.CHECK_TOKEN_EXPIRATION, checkAuthTimeoutSaga);
  yield takeEvery(actionTypes.authentication.REFRESH_AUTH_TOKEN, refreshAuthTokenSaga);
  yield takeEvery(actionTypes.authentication.START_APP_AUTH, authenticateAppSaga);
}

export function* watchMenu() {
  // yield takeEvery(actionTypes.menu.REQUEST_MENU, requestMenuSaga);
  // yield takeEvery(actionTypes.menu.RECEIVED_MENU, receivedMenuSaga);
  // yield call(requestMenuSaga);
}

export function* watchApplication() {
  yield takeEvery(actionTypes.application.ROUTE_CHANGED, routeListenerSaga);
  yield takeEvery(actionTypes.application.CHANGE_ACCOUNT_LANGUAGE, changeLanguageSaga);
  yield takeEvery(actionTypes.application.GET_PROMOTIONS, getPromotionsSaga);
  yield takeEvery(actionTypes.application.GET_ELIGIBLE_BONUSES, getEligibleBonusesSaga);
  yield takeEvery(actionTypes.application.GET_BONUS_AVAILABLE, getBonusAvailableSaga);
  yield takeEvery(actionTypes.bonuses.GET, getBonusesSaga);
  yield takeEvery(actionTypes.bonuses.GET_BONUS_CALCULATOR, getBonusCalculatorSaga);

  yield takeEvery(actionTypes.application.CLAIM_BONUS, claimBonusSaga);
  yield takeEvery(actionTypes.application.INITIALIZE, generateFingerPrintSaga);

}

export function* watchLogin() {
  yield takeEvery(actionTypes.login.USERNAME, loginUsernameSaga);
  yield takeEvery(actionTypes.login.PASSWORD, loginPasswordSaga);
  yield takeEvery(actionTypes.login.REQUEST_AUTHENTICATION, requestUserAuthentication);
  yield takeEvery(actionTypes.login.LOGOUT_USER, logoutSaga);
  yield takeEvery(actionTypes.login.STEP, stepChangeSmsRequest);
  yield takeEvery(actionTypes.login.START_SMS_COUNTDOWN, updateSmsEta);
  yield takeEvery(actionTypes.login.REQUEST_SMS_CODE, requestSmsSaga);
}

export function* watchWallet() {
  yield takeEvery(actionTypes.wallet.REQUEST_WALLET, requestWalletSaga);
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestWalletSaga);
  yield takeEvery(actionTypes.wallet.REQUEST_REMOVE_BONUS, requestRemoveBonusSaga);
  yield takeEvery(actionTypes.wallet.RECEIVED_REMOVE_BONUS, receivedRemovedBonusSaga);
}

export function* watchRegister() {
  yield takeEvery(actionTypes.register.VALIDATE_FIELD, validateFieldSaga);
  yield takeLatest(actionTypes.register.SEND_REGISTER_SMS, sendRegisterSMSSaga);
  yield takeLatest(actionTypes.register.REGISTER, registerSaga);
  yield takeLatest(actionTypes.register.SET_PARTIAL_ACCOUNT, setPartialAccountSaga);
}

export function* watchResetPassword() {
  yield takeEvery(actionTypes.resetPassword.SEND_EMAIL, resetPasswordSendEmailSaga);
  yield takeEvery(actionTypes.resetPassword.SEND_NEW_PASSWORD, resetPasswordSaga);
}

export function* watchProfile() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestAccountSaga);
  yield takeEvery(actionTypes.profile.REQUEST_ACCOUNT, requestAccountSaga);
  yield takeEvery(actionTypes.profile.REQUEST_LIMITS, requestLimitsSaga);
  yield takeEvery(actionTypes.profile.SET_NEW_LIMIT, setNewLimitSaga);
  yield takeEvery(actionTypes.profile.SET_SELFEXCLUSION, setSelfexclusionSaga);
  yield takeEvery(actionTypes.profile.CHECK_PASSWORD, checkPasswordSaga);
  yield takeEvery(actionTypes.profile.CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(actionTypes.profile.CHECK_PHONE, checkPhoneSaga);
  yield takeEvery(actionTypes.profile.CHECK_PHONE_CODE, checkPhoneCodeSaga);
  yield takeEvery(actionTypes.profile.GET_CHAT_INFO, getChatInfoSaga);
  yield takeEvery(actionTypes.profile.RESEND_VERIFICATION_EMAIL, resendVerificationEmailSaga);
  yield takeEvery(actionTypes.profile.SEND_SELF_EXCLUSION_CODES, sendSelfExclusionCodesSaga);
  yield takeEvery(actionTypes.profile.SELF_EXCLUDE, selfExcludeSaga);
}

export function* watchTransactions() {
  yield takeEvery(actionTypes.transactions.REQUEST_TRANSACTIONS_HISTORY, requestTransactionsHistorySaga);
}

export function* watchPaymentCheckout() {
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK, requestPaymentLinkSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN, requestVivaTokenSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN, requestVivaCardTokenSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_WITHDRAW, requestVivaWithdrawSaga);
  yield takeEvery(actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN, sendVivaTokenSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN, requestVivaChargeTokenSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD, requestVivaChargeTokenWithCardSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_BP_CARD_TOKEN, requestBPCardTokenSaga);
  yield takeEvery(actionTypes.paymentCheckout.REQUEST_BP_WITHDRAW, requestBPWithdrawSaga);
}

export function* watchWithdrawalCheckout() {
  yield takeEvery(actionTypes.withdrawalCheckout.REQUEST_WITHDRAWAL_LINK, requestWithdrawalLinkSaga);
}

export function* watchWithdrawals() {
  yield takeEveryWithCooldown(actionTypes.withdrawals.REQUEST_PENDING_WITHDRAWALS, requestPendingWithdrawalsSaga);
  yield takeEveryWithCooldown(actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS, requestPendingWithdrawalsSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_LOCATIONS, requestLocationsSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_TAX_SERVICE, requestTaxServiceSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL, requestCancelWithdrawalSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL, requestCancelWithdrawalSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL_NEW, requestCancelWithdrawalSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_VERIFICATION_CODE, requestVerificationCodeSaga);
  yield takeEvery(actionTypes.withdrawals.DOWNLOAD_CSV, downloadCSVSaga);
  yield takeEvery(actionTypes.withdrawals.GET_PAYMENT_METHODS, getPaymentMethodsSaga);
  yield takeEvery(actionTypes.withdrawals.LINK_IBAN, linkIBANSaga);
  yield takeEvery(actionTypes.withdrawals.GET_BETLION_WITHDRAW_DETAILS, betlionWithdrawDetailsSaga);
  yield takeEvery(actionTypes.withdrawals.WITHDRAW, withdrawSaga);
  yield takeEvery(actionTypes.withdrawals.REQUEST_WITHDRAW_HISTORY, requestWithdrawHistorySaga);
  yield takeEvery(actionTypes.withdrawals.GET_TAX, getWithdrawTaxSaga);
}

export function* watchDocuments() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestDocumentsSaga);
  yield takeEvery(actionTypes.documents.REQUEST_DOCUMENTS, requestDocumentsSaga);
  yield takeEvery(actionTypes.documents.REQUEST_DOCUMENTS_UPLOAD, requestDocumentsUploadSaga);
  yield takeEvery(actionTypes.documents.REQUEST_DOCUMENTS_MAX_FILE_SIZE, requestDocumentsMaxFileSizeSaga);
}

export function* watchGames() {
  //yield takeEvery(actionTypes.games.REQUEST_GAMES_LIST, requestGamesSaga);
  yield takeEvery(actionTypes.games.OPEN_GAME_LAUNCHER, openGameLauncherSaga);
  yield takeEvery(actionTypes.games.REQUEST_GAME_URL, requestGameUrlSaga);
  yield takeEvery(actionTypes.games.CLOSE_GAME_LAUNCHER, closeGameLauncherSaga);
  yield takeEvery(actionTypes.games.RELOAD_WALLETS_DATA, reloadWalletsDataSaga);
  yield takeEvery(actionTypes.games.CLOSE_REDIRECT_GAME_LAUNCHER, closeRedirectGameLauncherSaga);
  yield takeEvery(actionTypes.games.REQUEST_GAME_COLLECTIONS, requestGameCollectionsSaga);
}

export function* watchWithdrawWizard() {
  yield takeEvery(actionTypes.withdraw_wizard.REQUEST_TAXES, getTaxSaga);
  yield takeEvery(actionTypes.withdraw_wizard.GET_LOCATIONS, getLocationsSaga);
  yield takeEvery(actionTypes.withdraw_wizard.SEND_DATA, sendWithdraw);
}

export function* watchCustomerSupport() {
  yield takeEvery(actionTypes.customer_support.SEND_COMPLAINT_FORM, sendComplaintFormSaga);
  yield takeEvery(actionTypes.customer_support.REQUEST_GAME_RULES, requestGameRulesSaga);
}

/*
export function* watchJackpot() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, cycleRequestJackpotSaga);
}
*/

export function* watchPlayerWebsocket() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, websocketPlayerLoginSaga);
  yield takeEvery(actionTypes.authentication.CLEAR, websocketPlayerLogoutSaga);
}

export function* watchMarketingOffer() {
  yield takeLatest(actionTypes.marketingOffer.GET, getMarketingOfferSaga);
}

export function* rootSaga() {
  const sagas = [
    call(watchMenu),
    call(watchAuth),
    call(watchApplication),
    call(watchLogin),
    call(watchWallet),
    call(watchRegister),
    call(watchResetPassword),
    call(watchProfile),
    call(watchTransactions),
    call(watchPaymentCheckout),
    call(watchWithdrawalCheckout),
    call(watchWithdrawals),
    call(watchDocuments),
    call(watchGames),
    call(watchWithdrawWizard),
    call(watchCustomerSupport),
    // we don't use this jakpots api anymore
    //call(watchJackpot),
    call(watchPlayerWebsocket),
    call(watchHappyHourSaga),
    call(watchConfigSaga),
    call(freeBetsSaga),
    call(freeSpinsSaga),
    call(watchVoucherSaga),
    call(alertsSaga),
    call(druid),
    call(watchMarketingOffer),
    call(watchDeposit),
    call(watchRewardBundle),
  ];

  if (window.config && (window.config.betsEnabled === '1' || window.config.nsoftEnabled === '1')) {
    sagas.push(call(betsSaga));
  }

  if (window.config && window.config.virtualsEnabled === '1') {
    sagas.push(call(virtualsSaga));
  }

  if (window.config && window.config.lottoEnabled === '1') {
    sagas.push(call(lottoSaga));
  }

  if (window.config && window.config.winnerFunEnabled === '1') {
    sagas.push(call(winnerFunSaga));
  }

  if (window.config && window.config.liveCasinoEnabled === '1') {
    sagas.push(call(liveCasinoSaga));
  }

  if (window.config && window.config.wheelEnabled === '1') {
    sagas.push(call(wheelSaga));
  }

  if (window.config && window.config.promoEnabled === '1') {
    sagas.push(call(promoSaga));
  }

  if (window.config && window.config.promoCalendarEnabled === '1') {
    sagas.push(call(promoCalendarSaga));
  }

  if (window.config && window.config.depositTicketEnabled === '1') {
    sagas.push(call(depositTicketSaga));
  }

  // if (window.config && window.config.tournamentsMissionsEnabled === '1') {
  // always load this saga since player prizes depends on it
  sagas.push(call(tournamentsMissionsSaga));
  // }

  if (window.config && window.config.lobbyEnabled === '1') {
    sagas.push(call(lobbySaga));
  }

  if (window.config && window.config.momentumEnabled === '1') {
    sagas.push(call(momentumSaga));
  }

  sagas.push(call(rootStoreSaga));

  yield all(sagas);
}
