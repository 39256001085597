import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getBetslipData } from '../../utils/hooks';
import { buildBets, buildSystems } from './utils/build-bets';
import { formatMessage } from '../../utils/functions-betslip';
import {
  betsSlipComputeWin,
  betsSlipRemove,
  betsSlipAcceptOddChange,
  betsSlipSetTicketType,
  betsSlipToggleSystem,
} from '../../../../../modules/bets/store/actions/betsSlip';
import { makeCustomEqual, customMemo } from '@/utils/custom';

import './index.scss';

type BetslipBodyProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetslipBody = (componentProps: BetslipBodyProps) => {
  const { i18n, t } = useTranslation();
  const data = getBetslipData();
  const dispatch = useAppDispatch();
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);

  React.useEffect(() => {
    dispatch(betsSlipComputeWin());
  }, [data.liveMatches, data.prematchMatches]);

  const onAcceptOddChange = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.idmbo) {
      const mType = e.currentTarget.dataset.mtype;
      const idMbo = e.currentTarget.dataset.idmbo;

      if ((mType === 'live' || mType === 'prematch') && idMbo) {
        dispatch(betsSlipAcceptOddChange(mType, idMbo));
      }
    }
  };
  const onRemoveOdd = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.idmbo) {
      const mType = e.currentTarget.dataset.mtype;
      const idMbo = e.currentTarget.dataset.idmbo;

      if ((mType === 'live' || mType === 'prematch') && idMbo) {
        dispatch(betsSlipRemove(mType, idMbo));
      }
    }
  };
  const onChangeTicketType = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.type && ['single', 'system'].includes(e.currentTarget.dataset.type)) {
      const type = e.currentTarget.dataset.type;
      dispatch(betsSlipSetTicketType(type));
    }
  };
  const onSelectSystem = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.system) {
      const system = e.currentTarget.dataset.system;
      dispatch(betsSlipToggleSystem('none', system, 'digitain'));
    }
  };

  const contextValue = customMemo(
    'BetSlipBody',
    () => {
      const liveBets = buildBets(
        data.liveBets,
        data.liveMatches,
        data.bonusEvaluate,
        data.tournamentEvaluate,
        data.walletBonuses,
        t,
        i18n.language,
        isWinnerFun,
      );
      const prematchBets = buildBets(
        data.prematchBets,
        data.prematchMatches,
        data.bonusEvaluate,
        data.tournamentEvaluate,
        data.walletBonuses,
        t,
        i18n.language,
        isWinnerFun,
      );

      let systems: any[] = [];
      if (liveBets.length > 0 || prematchBets.length > 0) {
        systems = buildSystems([...liveBets, ...prematchBets], data.systems);
      }

      const walletBonusesHash: any = {};
      if (data.walletBonuses) {
        data.walletBonuses.forEach((wb) => {
          walletBonusesHash[wb.id] = wb.name;
        });
      }

      const bonusEvaluateMessagesSystem: { message: string }[] = [];
      const bonusEvaluate = data.bonusEvaluate;
      if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data && !bonusEvaluate.data.eligible) {
        bonusEvaluate.data.details &&
          bonusEvaluate.data.details.forEach((ev: any) => {
            if (ev.error && ev.error.type === 'ticket') {
              const args = [...ev.error.args];
              args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message
              if (ev.error.section === 'system') bonusEvaluateMessagesSystem.push({ message: formatMessage(...args) });
            }
          });
      }

      const contextValue = {
        liveBets,
        prematchBets,
        onAcceptOddChange,
        onRemoveOdd,
        ticketType: data.ticketType,
        onChangeTicketType,
        systems: systems,
        onSelectSystem,
        bonusEvaluateMessagesSystem,
        isWinnerFun,
      };

      return contextValue;
      // }, [data, componentProps, t]);
    },
    makeCustomEqual('BetSlipBody', [
      'liveBets',
      'prematchBets',
      'onAcceptOddChange',
      'ticketType',
      'systems',
      'bonusEvaluateMessagesSystem',
    ]),
  );

  //console.log('BetslipBody[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetslipBody;
