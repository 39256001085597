import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useMatches } from 'react-router';

import { useAppSelector } from '../../store';
import getExtraMeta from './extra-meta';

type PageMetaTags = {
  meta?: {
    [key: string]: any;
  };
};

const PageMetaTags = (props: PageMetaTags) => {
  const { meta } = props;
  const config = useAppSelector((state) => state.templatesConfig);
  const matches = useMatches();

  const isNotFound = Boolean(matches?.[matches?.length - 1]?.params?.['*']);
  const extra = getExtraMeta(meta);

  const extraMeta = React.useMemo(() => {
    return {
      ...meta,
      ...extra,
    };
  }, [extra, meta]);

  React.useEffect(() => {
    document.documentElement.classList.remove('ligh-mode', 'dark-mode');

    if (meta && meta['themeMode'] != null && meta['themeMode'] && meta['themeMode'] !== config.themeMode) {
      if (meta['themeMode'] === 'dark') {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.add('light-mode');
      }
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.remove('light-mode');
    }

    const bodyClass = meta?.['className'] ?? '';

    if (bodyClass) {
      document.body.classList.add(...bodyClass.split([' ']));
    }

    return () => {
      if (bodyClass) {
        document.body.classList.remove(...bodyClass.split([' ']));
      }
    };
  }, [meta, config]);

  if (!meta) return null;

  let keys = Object.keys(extraMeta);
  if (!keys || !keys.length) return null;
  keys = keys.filter((key: any) => ['pathname', 'priority', 'lastmod', 'changefreq'].indexOf(key) === -1);

  const list = keys.map((key: any, i) => {
    if (!extraMeta[key]) return null;
    if (key === 'themeMode' || key === 'className') return null;

    if (key === 'canonical') return <link key={key} rel={key} href={extraMeta[key]} />;

    if (key === 'robots' || key === 'googlebot') return <meta key={key} name={key} content={extraMeta[key]} />;

    if (key === 'title') {
      const value = extraMeta[key];
      value.replace('{{NAME}}', '');
      return <title key={key}>{value}</title>;
    }

    if (key === 'description' || key === 'keywords' || key === 'thumbnail')
      return <meta key={key} name={key} content={extraMeta[key]} />;

    if (key === 'json_ld') {
      let text = '';
      try {
        //make sure the content is valid JSON
        const tmp = JSON.parse(extraMeta[key]);
        if (typeof tmp === 'object' && tmp != null) {
          text = JSON.stringify(tmp);
        }
      } catch (e) {
        return null;
      }

      return (
        <script key={key} type="application/ld+json">
          {text}
        </script>
      );
    }

    return <meta key={key} property={key} content={extraMeta[key]} />;
  });

  const cleanedUrl = window.location.href.replace(/\/$/, '');
  const finalUrl = cleanedUrl.split('?')?.[0];

  if (typeof extraMeta['canonical'] === 'undefined' || !extraMeta['canonical']) {
    list.push(<link key={'canonical'} rel={'canonical'} href={finalUrl} />);
  }
  if (typeof extraMeta['og:url'] === 'undefined' || !extraMeta['og:url']) {
    list.push(<link key={'og:url'} rel={'og:url'} href={finalUrl} />);
  }
  if (typeof extraMeta['twitter:url'] === 'undefined' || !extraMeta['twitter:url']) {
    list.push(<link key={'twitter:url'} rel={'twitter:url'} href={finalUrl} />);
  }
  if (isNotFound) {
    list.push(<meta key={'prerender-status-code'} name={'prerender-status-code'} content="404" />);
  }

  if (list.length === 0) return null;

  return <Helmet>{list}</Helmet>;
};

export default PageMetaTags;
