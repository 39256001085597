import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { useAppSelector } from '@/store';

//import './index.scss';

type TimeSpentPopupProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const TSP_KEY = 'timeSpentPopup';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TimeSpentPopup = (componentProps: TimeSpentPopupProps) => {
  const authentication = useAppSelector((state) => state.authentication);
  const [popupActive, setPopupActive] = useState(false);

  React.useEffect(() => {
    // Check if the user is logged in
    if (
      ['user', 'token'].indexOf(authentication.auth_type) === -1 ||
      authentication.access_token === null ||
      window.config.responsibleGaming.isTimeSpentNotificationEnabled !== '1'
    ) {
      setPopupActive(false);
      ExpiringLocalStorage.set(TSP_KEY, 0);
      return;
    }

    const time = ExpiringLocalStorage.get(TSP_KEY);
    if (time === null || time < 60) {
      ExpiringLocalStorage.set(TSP_KEY, 0);
    }

    const timer = setInterval(() => {
      let time = ExpiringLocalStorage.get(TSP_KEY);
      time = parseInt(time) + 1;
      ExpiringLocalStorage.set(TSP_KEY, time);
      if (time >= 60) {
        setPopupActive(true);
      }
    }, 60000); // 1 minute

    return () => clearInterval(timer);
  }, [authentication]);

  const contextValue = React.useMemo(() => {
    const closePopupHandler = () => {
      ExpiringLocalStorage.set(TSP_KEY, 0);
      setPopupActive(false);
    };

    return {
      popupActive: popupActive,
      closePopupHandler: closePopupHandler,
    };
  }, [popupActive]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TimeSpentPopup;
