import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { selectBudgetDistribution } from '@/store/slices/playerStats';


ChartJS.register(ArcElement, Tooltip);

type DoughnutChartProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DoughnutChart = (componentProps: DoughnutChartProps) => {
  let props = componentProps;

  const budgetDistribution = useAppSelector(selectBudgetDistribution);

  const acceptedDataTypes = ['bets', 'wins'];
  let dataType;
  if (props.properties?.dataType) {
    if (!acceptedDataTypes.includes(props.properties.dataType)) {
      console.error('Invalid data type for doughnut chart:', props.properties.dataType);
      return;
    } else {
      if (budgetDistribution) {
        dataType = props.properties.dataType as keyof typeof budgetDistribution.current_month;
      }
    }
  }
  const budgetData = budgetDistribution?.current_month[dataType!];
  
  const graphKeys = budgetData ? Object.keys(budgetData).map(key => 
    key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')) : [];
    let graphData = budgetData ? Object.values(budgetData) : [];

  let chartColors = [];
  const checksum = graphData.reduce((sum, value) => sum + value, 0);
  if (checksum === 0) {
    // colors for no data
    chartColors = [
      '#99999b',
      '#e2e2e2',
      '#d2d2d2',
      '#aaabae',
    ];
      
    graphData = [25, 15,35, 25]; // chart for no data
  } else {
    chartColors = [
      '#10C', // blue
      '#FD0', // yellow
      '#00CD78', // green
      '#FF008C', // pink
    ];
  }
  
  const data = {
    labels: graphKeys,
    datasets: [
      {
        data: graphData,
        backgroundColor: chartColors,
        borderColor: '#fff',
        borderRadius: 7,
        spacing: 0,
        borderWidth: 5,
        hoverOffset: 1,
      },
    ],
  };
  const chartOptions = {
    cutout: '70%',
    responsive: true,
    maintainAspectRatio: false,
  };

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const contextValue = React.useMemo(() => {
    return {};
  }, [dataElementContext, componentProps]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
        <DataElementContext.Provider value={contextValue}>
            <Doughnut data={data} options={chartOptions} />
            {componentProps.children}
        </DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default DoughnutChart;
