import React from 'react';
import styled from 'styled-components';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import useMarketingOffer from '@/components/modules/register/hooks/useMarketingOffer';

type RegisterBonusProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RegisterBonus = (componentProps: RegisterBonusProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);
  
  const { registrationBonusContext } = useMarketingOffer();

  const contextValue = React.useMemo(() => {
    return registrationBonusContext;
  }, [dataElementContext, componentProps]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RegisterBonus;
