import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';

import { logBetRecommendationEvent } from '@/analytics';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useProcessList } from '@/page-components/utils/useProcessList';
import { useAppSelector } from '@/store';
import urlonStringify from '@/utils/urlon';

import {
  crossListsFilter,
  formatMotivations,
  getBetData,
  getBetSlipList,
  getFullList,
  getCommonStateSlice,
  isListEqual,
  RecommendedMatchType,
} from './utils';

type DataSource = {
  data: RecommendedMatchType[];
};

type DataSourceMap = Record<string, DataSource>;

type Market = {
  idBet: string;
  idMb: string;
};

export type RecommendedBetsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

export type RecommendedBetsState = {
  currentPage: number;
  lastPage: number;
  betSlipList: RecommendedMatchType[];
  currentList: RecommendedMatchType[];
  fullList: RecommendedMatchType[];
  originalList: RecommendedMatchType[];
  displayCount: string;
  showLoader: boolean;
};

const RECOMMENDED_BETS_UNIQUE_ID = 'unique-recommended-bets-';
const IS_RECOMMENDED_BETS_ENABLED = window.config && window.config.recommendedBetsEnabled === '1';

export function isBetsRecommendationDataSource(sourceId: string): boolean {
  return sourceId.includes(RECOMMENDED_BETS_UNIQUE_ID);
}

let cached: Promise<DataSource> | undefined;

const requestDataSource = (sourceId: string, authenticationToken: string): Promise<DataSource> => {
  if (cached) {
    return cached;
  }

  cached = new Promise<DataSource>((resolve) => {
    axios
      .get<DataSourceMap>(`${window.config.dataSourceApiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + authenticationToken,
        },
        params: {
          q: urlonStringify({
            ids: [sourceId],
          }),
        },
      })
      .then((response) => {
        const dataSource = response.data[sourceId];

        if (dataSource) {
          resolve({
            ...dataSource,
            data: dataSource.data.filter((match) => match.mType !== 'live').map((match) => formatMotivations(match)),
          });
        } else {
          resolve({
            data: [],
          });
        }
      });
  });

  return cached;
};

const useDataSource = (sourceId: string) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const [state, setState] = React.useState<DataSource>();

  React.useEffect(() => {
    if (authenticationToken) {
      if (!IS_RECOMMENDED_BETS_ENABLED) {
        return;
      }

      requestDataSource(sourceId, authenticationToken).then((response) => setState(response));
    }
  }, [authenticationToken, sourceId]);

  return state;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RecommendedBets = (componentProps: RecommendedBetsProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { sourceId, filterByIdMatch, filterByMatchBets } = props.properties ?? {};
  const pageSize = parseInt(props.properties.pageSize);

  const { i18n } = useTranslation();
  const uriMatches = useMatches();
  const betsSlip = useAppSelector((state) => state.bets.betsSlip);
  const prematchMatches = useAppSelector((state) => state.bets.prematch.matches);
  const key = React.useRef(uniqueId(RECOMMENDED_BETS_UNIQUE_ID));

  const isCurrentMatch = React.useMemo(() => {
    return filterByIdMatch && filterByIdMatch.length && filterByMatchBets && filterByMatchBets.length;
  }, [filterByIdMatch, filterByMatchBets]);

  const isFootballPage = React.useMemo(() => {
    if (uriMatches && uriMatches.length) {
      const params = uriMatches[0].params ?? {};

      return params.idSport === undefined || params.idSport === '1';
    }

    return false;
  }, [uriMatches]);

  const matchBetsMap = React.useMemo(() => {
    const map = new Map<string, Market>();

    if (isCurrentMatch) {
      filterByMatchBets.forEach((filterByMatchBet: Market) => {
        map.set(filterByMatchBet.idMb, filterByMatchBet);
      });
    }

    return map;
  }, [isCurrentMatch, filterByMatchBets]);

  const [state, setState] = React.useState<RecommendedBetsState>({
    currentPage: 0,
    lastPage: 0,
    betSlipList: [],
    currentList: [],
    fullList: [],
    originalList: [],
    displayCount: '(0)',
    showLoader: false,
  });

  const dataSource = useDataSource(sourceId);

  const { data, changed, processed } = useProcessList(
    dataSource,
    i18n.language,
    key.current,
    dataSource?.data?.length ?? 0,
  );

  // This runs only when the data is fetched from server
  React.useEffect(() => {
    if (data.length && processed) {
      let originalList = data.filter((matchType: RecommendedMatchType) => {
        const { match, matchBet, matchBetOutcome } = getBetData(
          prematchMatches,
          matchType.idMatch,
          matchType.brmPeriodIdMatch,
          matchType.brmIdMb,
          matchType.brmIdMbo,
        );

        return !!match && !!matchBet && !!matchBetOutcome;
      });

      // Check if we can display a full page or that we got most of the match data in order to avoid displaying truncated data
      if (originalList.length >= pageSize) {
        if (isCurrentMatch) {
          originalList = originalList.filter(
            (match: RecommendedMatchType) => match.idMatch === filterByIdMatch && matchBetsMap.has(match.brmIdMb),
          );
        }

        // First find if any of bets are in the bet slip
        const betSlipList = getBetSlipList(betsSlip, originalList);

        // Filter available list based on what is found in the bet slip
        const fullList = getFullList(originalList, betSlipList);

        setState((prevState) => {
          if (isListEqual(prevState.originalList, originalList)) {
            return prevState;
          }

          return {
            ...getCommonStateSlice(prevState, fullList, pageSize),
            betSlipList,
            fullList,
            originalList,
          };
        });
      }
    }
  }, [data, changed, processed, pageSize, isCurrentMatch, filterByIdMatch, matchBetsMap, betsSlip, prematchMatches]);

  // This runs whenever bet slip is updated
  React.useEffect(() => {
    setState((prevState) => {
      const betSlipList = getBetSlipList(betsSlip, prevState.originalList);

      const added: RecommendedMatchType[] = [];
      const removed: RecommendedMatchType[] = [];

      // Check for added elements since last state update
      betSlipList.forEach((match) => {
        const exists = prevState.betSlipList.find(crossListsFilter(match));

        if (!exists) {
          added.push(match);
        }
      });

      // Check for removed elements since last state update
      prevState.betSlipList.forEach((match) => {
        const exists = betSlipList.find(crossListsFilter(match));

        if (!exists) {
          removed.push(match);
        }
      });

      // We should process this only when the bet slip has actual changes
      if (added.length || removed.length) {
        // Filter available list based on what is added in and removed from the bet slip
        const fullList = getFullList(prevState.fullList, added, removed);

        return {
          ...getCommonStateSlice(prevState, fullList, pageSize),
          betSlipList,
          fullList,
        };
      }

      return prevState;
    });
  }, [isCurrentMatch, filterByIdMatch, matchBetsMap, pageSize, betsSlip]);

  const onNextPage = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setState((prevState) => {
        logBetRecommendationEvent(event, prevState, props);

        const isLastPage = prevState.currentPage === prevState.lastPage;
        const nextPage = isLastPage ? 0 : prevState.currentPage + 1;
        const pageStartIndex = isLastPage ? 0 : nextPage * pageSize;

        return {
          ...prevState,
          currentPage: nextPage,
          currentList: prevState.fullList.slice(pageStartIndex, pageStartIndex + pageSize),
        };
      });
    },
    [props, pageSize],
  );

  const contextValue = React.useMemo(() => {
    return {
      list: state.currentList,
      displayCount: state.displayCount,
      showLoader: state.showLoader,
      hideHeader: props.properties.hideHeader,
      hideTeams: props.properties.hideTeams,
      onNextPage,
    };
  }, [
    dataElementContext,
    componentProps,
    onNextPage,
    state.currentList,
    state.displayCount,
    state.showLoader,
    props.properties.hideHeader,
    props.properties.hideTeams,
  ]);

  if (!IS_RECOMMENDED_BETS_ENABLED || !isFootballPage || !state.currentList.length) {
    return null;
  }

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RecommendedBets;
