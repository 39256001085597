import React, { useCallback } from 'react';
import styled from 'styled-components';
import { isEqual, set } from 'lodash-es';
import { useAppSelector, useAppDispatch } from '../../../store';
import { useLocation } from 'react-router-dom';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { getStateValue } from '../../../page-components/utils/getStateValue';
import { claimBonus, getBonusAvailable } from '../../../modules/casino/store/actions/application';
import { requestRemoveBonus, requestWallet } from '../../../modules/casino/store/actions/wallet';
import { freeBetRemove, freeBetsFetch } from '../../../modules/casino/store/actions/free_bets';
import { freeSpinsFetch } from '../../../modules/casino/store/actions/free_spins';
import {
  loadPrizes,
  claimPrizeById,
  claimPrizeByIdLoaded,
  resetClaimPrizeError,
} from '@/modules/tournaments-missions/store/actions/history';
import { getMomentumBonuses, forfeitMomentumBonus } from '@/modules/momentum/store/actions/momentum';
import * as actionTypes from '@/modules/casino/store/actions/actionTypes.js';

import './index.scss';
import { FeatureCategory, FeatureSubcategory, logEvent } from '@/analytics';
import { FeatureLocation } from '@/analytics/types/eventData';

type WalletBonusesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const WalletBonuses = (componentProps: WalletBonusesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  const location = useLocation();

  let tab = new URLSearchParams(location.search).get('tab') ?? 'bonus';
  if (!(tab === 'bonus' || tab === 'freespin' || tab === 'freebet' || tab === 'prize')) {
    tab = 'bonus';
  }

  const [selectedTab, setSelectedTab] = React.useState(tab);

  const {
    activeBonuses,
    pendingBonuses,
    availableBonuses,
    pendingBonusesLoader,
    bonusClaimError,
    activeBonusesLoader,
    loadingClaimBonus,
    prizes,
    loadingPrizes,
    claimPrizeByIdError,
  } = useAppSelector<{
    activeBonuses: any[];
    pendingBonuses: any[];
    availableBonuses: any | undefined | null;
    pendingBonusesLoader: any;
    activeBonusesLoader: any;
    bonusClaimError: any;
    loadingClaimBonus: boolean;
    prizes: any[];
    loadingPrizes: boolean;
    claimPrizeByIdError: boolean;
  }>(
    (state) => ({
      activeBonuses: getStateValue(state, 'state.activeBonuses'),
      pendingBonuses: getStateValue(state, 'state.pendingBonuses'),
      availableBonuses: getStateValue(state, 'state.application.availableBonuses'),
      pendingBonusesLoader: getStateValue(state, 'state.pendingBonuses.loader'),
      activeBonusesLoader: getStateValue(state, 'state.activeBonuses.loader'),
      bonusClaimError: state.application.bonusClaimError,
      loadingClaimBonus: state.application.loadingClaimBonus,
      prizes: state.tournamentsMissions.history.prizes,
      loadingPrizes: state.tournamentsMissions.history.loadingPrizes,
      claimPrizeByIdError: state.tournamentsMissions.history.claimPrizeByIdError,
    }),
    isEqual,
  );

  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize.value);
  const momentumLoaded = useAppSelector((state) => state.momentum.prize.loaded);
  const authentication = useAppSelector((state) => state.authentication);

  const [disableButtons, setDisableButtons] = React.useState(true);

  React.useEffect(() => {
    dispatch(getBonusAvailable());
    dispatch(loadPrizes(null));
    dispatch(requestWallet());
    dispatch(freeSpinsFetch());
    dispatch(freeBetsFetch());
    setDisableButtons(false);
    if (!momentumLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [authentication.auth_type, dispatch, momentumLoaded]);

  const [isDialogOpen, setIsDialogOpen] = React.useState({
    open: false,
    deleteId: '',
    deleteType: '',
  });

  const [isActivateDialogOpen, setIsActivateDialogOpen] = React.useState(false);

  const getElementToActivate = useCallback(
    (e: any) => {
      if (!e) return;
      e.preventDefault();
      e.stopPropagation();

      const bonus = e.target.closest('.activate-pending-bonus-action-button');

      if (bonus && bonus.dataset) {
        // 1st reset any claim bonus or prize status
        dispatch({
          type: actionTypes.application.RESET_BONUS_CLAIM_STATUS,
        });
        dispatch(resetClaimPrizeError());

        // console.log('bonus.dataset', bonus.dataset);

        setIsActivateDialogOpen(true);
        switch (bonus.dataset.type.toString()) {
          case 'bonus':
            // console.log('DISPATCH CLAIM BONUS', bonus.dataset.bonusid);
            dispatch(claimBonus(bonus.dataset.bonusid, true));
            break;
          case 'prize':
          case 'prizeBundle':
            // console.log('DISPATCH CLAIM PRIZE', bonus.dataset.bonusid);
            dispatch(claimPrizeById({ id: bonus.dataset.bonusid }));
            break;
          // TODO: add more cases if needed
          default:
            console.error('Unknown delete type', bonus);
        }
      } else {
        setIsActivateDialogOpen(false);
        dispatch(claimPrizeByIdLoaded());
        // reset any claim bonus or prize status
        dispatch({
          type: actionTypes.application.RESET_BONUS_CLAIM_STATUS,
        });
        dispatch(resetClaimPrizeError());
      }
    },
    [dispatch, setIsActivateDialogOpen],
  );

  const getElementToRemove = useCallback((e: any) => {
    if (!e) return;
    e.preventDefault();

    // const testElement = getDOMElementByClassName('delete-active-bonus-action-button');
    const bonus = e.target.closest('.delete-active-bonus-action-button');
    // console.log('DEBUG RUNNING getElementToRemove', { bonus });
    // @ts-ignore
    if (bonus && bonus.dataset) {
      // @ts-ignore
      setIsDialogOpen((ps) => ({
        ...ps,
        open: !ps.open,
        deleteId: bonus.dataset.bonusid,
        deleteType: bonus.dataset.type,
      }));
    } else {
      setIsDialogOpen((ps) => ({ ...ps, open: false, deleteId: '', deleteType: '' }));
    }
  }, []);

  const onDeleteBonus = useCallback(
    (e: any) => {
      e.preventDefault();

      if (isDialogOpen.deleteType && isDialogOpen.deleteId) {
        setDisableButtons(true);
        switch (isDialogOpen.deleteType.toString()) {
          case '2':
            // console.log('DISPATCH REMOVE BONUS', isDialogOpen);
            dispatch(requestRemoveBonus(isDialogOpen.deleteId));
            break;
          case '9':
          case '5':
            // console.log('DISPATCH REMOVE FREEBET', isDialogOpen);
            dispatch(freeBetRemove(isDialogOpen.deleteId));
            break;
          case '1000':
            // console.log('delete bonus', isDialogOpen.deleteId);
            dispatch(forfeitMomentumBonus(isDialogOpen.deleteId ?? 0));
            break;
          // TODO: add more cases if needed
          default:
            console.error('Unknown delete type', isDialogOpen.deleteType);
          // setLoading(true);
        }
      }

      logEvent(
        e,
        {
          feature_used: 'wallet_bonus_delete',
          feature_category: FeatureCategory.Rewards,
          feature_subcategory: FeatureSubcategory.NotApplicable,
          feature_location: FeatureLocation.NotApplicable,
        },
        {
          bonus_id: isDialogOpen.deleteId,
          bonus_type: isDialogOpen.deleteType,
        },
      );

      setIsDialogOpen((ps) => ({ ...ps, open: false, deleteId: '', deleteType: '' }));
    },
    [dispatch, isDialogOpen.deleteId, isDialogOpen.deleteType, setDisableButtons],
  );

  React.useEffect(() => {
    setDisableButtons(false);
  }, [activeBonusesLoader.loading]);

  const contextValue = React.useMemo(() => {
    let ab = activeBonuses;
    if (momentumPrize?.active) {
      let prizeExpired = false;
      if (momentumPrize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
        if (now - prizeExpiresAt > 0) prizeExpired = true;
      }
      if (!prizeExpired) {
        let wager_target = momentumPrize?.wager_target ?? 0;
        wager_target = wager_target / 100;
        let progress = momentumPrize?.wager_progress ?? 0;

        progress = (progress * wager_target) / 100;
        progress = parseFloat(progress.toFixed(2));

        if (progress > wager_target) progress = wager_target;

        ab = [
          {
            type: 'bonus',
            bonus: {
              element_type: 'inner',
              data: {
                logo: 'https://micros-t.b-cdn.net/turbo-wins-1715922446297.png',
                id: momentumPrize?.uuid,
                eligibleProducts: [],
                amountGranted: (momentumPrize?.reward?.value ?? 0) / 100,
                amount: (momentumPrize?.reward?.value ?? 0) / 100,
                name: momentumPrize?.display_name,
                wager: progress,
                wagerTarget: momentumPrize?.wager_target / 100,
                startDate: new Date(momentumPrize?.created_at).valueOf() / 1000,
                endDate: new Date(momentumPrize?.bonus_expires_at).valueOf() / 1000,
                type: 1000,
                cms_data: {
                  terms_url: '/promotions/7f712d82-831d-48c7-b6e8-bb2a03afa536',
                },
                delete_action: 'activeBonuses.deleteBonus',
              },
            },
          },
          ...ab,
        ];
      }
    }

    const filterBonuses = (bonuses: any[]) => {
      const allBonuses: any[] = [];
      const allPrizes: any[] = [];
      const allFreeSpins: any[] = [];
      const allFreeBets: any[] = [];

      if (Array.isArray(bonuses) && bonuses.length > 0) {
        for (const b of bonuses) {
          // pending bonuses:
          if (b.type === 'bonus') {
            switch (b.bonus?.data?.bonus_available_type?.toString?.()) {
              case '2':
                allBonuses.push(b);
                break;
              case '9':
              case '5':
                allFreeBets.push(b);
                break;
              case '1000':
                // console.log('momentum bonus', b);
                allBonuses.push(b);
                break;
              case '8':
                allFreeSpins.push(b);
                break;
              default:
                allBonuses.push(b);
            }
          } else if (b.type === 'prize') {
            /*
          bonusRewardTypes = [
            { id: 'BONUS_FUNDS', name: 'BONUS_FUNDS' },
            { id: 'SPORT_FREE_BET', name: 'SPORT_FREE_BET' },
            { id: 'LOTO_FREE_BET', name: 'LOTO_FREE_BET' },
            { id: 'FREE_SPINS', name: 'FREE_SPINS' },
            { id: 'GOLDEN_CHIP', name: 'GOLDEN_CHIP' },
          ];
          */

            allPrizes.push(b);
            // TODO: uncomment when we have front design for each prize type
            /*
            console.log('DEBUG PRIZE TYPE', { b, type: b.prize?.data?.reward_type });
            switch (b.prize?.data?.reward_type) {
              case 'BONUS_FUNDS':
                allPrizes.push(b);
                break;
              case 'FREE_SPINS':
                allFreeSpins.push(b);
                break;
              case 'FREE_BET':
              case 'SPORT_FREE_BET':
                allFreeBets.push(b);
                break;
              // add more cases here
              default:
                // console.log('Default prize type', b.data?.reward_type);
                allPrizes.push(b);
            }*/
          }
          // active bonuses:
          else if (b.type === 'freebet') {
            allFreeBets.push(b);
          } else if (b.type === 'freespin') {
            allFreeSpins.push(b);
          }
        }
      }
      return {
        allBonuses,
        allPrizes,
        allFreeSpins,
        allFreeBets,
      };
    };

    //console.log('WalletBonuses[pendingBonuses]', pendingBonuses);

    return {
      activeBonuses: ab,
      pendingBonuses,
      availableBonuses,
      isDialogOpen: isDialogOpen.open,
      deleteId: isDialogOpen.deleteId,
      deleteType: isDialogOpen.deleteType,
      toggleDialog: getElementToRemove,
      onDeleteBonus,
      bonusClaimError: bonusClaimError, //|| claimPrizeByIdError,

      // isActivateDialogOpen: isActivateDialogOpen && !loadingClaimBonus,
      isActivateDialogOpen: isActivateDialogOpen,
      toggleActivateDialog: getElementToActivate,
      loading: loadingClaimBonus || loadingPrizes,
      pendingBonusesLoading: pendingBonusesLoader.loading,
      activeBonusesLoading: activeBonusesLoader.loading,
      disableRemoveBonus: disableButtons || activeBonusesLoader.loading,

      // prizes,
      loadingPrizes,
      claimPrizeByIdError,

      allActiveBonuses: [...filterBonuses(ab).allBonuses],
      allActivePrizes: [...filterBonuses(ab).allPrizes],
      allActiveFreeSpins: [...filterBonuses(ab).allFreeSpins],
      allActiveFreeBets: [...filterBonuses(ab).allFreeBets],

      allPendingBonuses: [...filterBonuses(pendingBonuses).allBonuses],
      allPendingPrizes: [...filterBonuses(pendingBonuses).allPrizes],
      allPendingFreeSpins: [...filterBonuses(pendingBonuses).allFreeSpins],
      allPendingFreeBets: [...filterBonuses(pendingBonuses).allFreeBets],

      selectedTab: selectedTab,
      onTabChange: (selectedLayout: string) => {
        setSelectedTab(selectedLayout);
      },
    };
  }, [
    momentumPrize,
    activeBonuses,
    pendingBonuses,
    availableBonuses,
    isDialogOpen,
    onDeleteBonus,
    bonusClaimError,
    isActivateDialogOpen,
    getElementToActivate,
    getElementToRemove,
    loadingClaimBonus,
    pendingBonusesLoader.loading,
    activeBonusesLoader.loading,
    disableButtons,
    loadingPrizes,
    claimPrizeByIdError,
    selectedTab,
  ]);

  // console.log('WalletBonuses', {
  //   contextValue,
  //   prizes,
  //   pendingBonuses,
  //   activeBonuses,
  //   selectedTab,
  // });
  // console.log('WalletBonuses[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default WalletBonuses;
