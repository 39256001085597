import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../index';

type BetWin = {
  casino: number;
  live_casino: number;
  sports: number;
  live_sports: number;
};
type Badge = {
  id?: string;
  image?: string;
  name?: {
    en: string;
    ro: string;
    pt: string;
  };
};

type HallOfFame = {
  selectedBadges: Badge[];
};

export type PlayerStats = {
  budget_distribution?: {
    hasData: {
      bets: boolean;
      wins: boolean;
    };
    current_month: {
      bets: BetWin;
      wins: BetWin;
      hasData: {
        bets: boolean;
        wins: boolean;
      };
    };
    previous_month: {
      bets: BetWin;
      wins: BetWin;
      hasData: {
        bets: boolean;
        wins: boolean;
      };
    };
  },
  player_preferences?: Record<string, never>;
  hall_of_fame?: HallOfFame;
};

interface PlayerStatsState {
  data: PlayerStats | null;
  loading: boolean;
  error: string | null;
  lastFetched: string | null;
}

const initialState: PlayerStatsState = {
  data: null,
  loading: false,
  error: null,
  lastFetched: null
};

const apiUrl = window.config.playerApiUrl;

export const fetchPlayerStats = createAsyncThunk(
  'playerStats/fetchPlayerStats',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      if (state.authentication.access_token && state.authentication.auth_type === 'user') {
        const response = await axios.get<PlayerStats>(`${apiUrl}/player/data/player-stats`, {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        });

        if (response.data) {
          return { data: response.data, success: true };
        }
      }
      return rejectWithValue({
        error: 'Failed to fetch player statistics',
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  }
);

// Helper function to check if data exists in BetWin object (any value > 0)
const hasDataInBetWin = (data: BetWin): boolean => {
  return Object.values(data).some(value => value > 0);
};

const playerStatsSlice = createSlice({
  name: 'playerStats',
  initialState,
  reducers: {
    clearPlayerStats: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
      state.lastFetched = null;
    },
    updateHallOfFame: (state, action: PayloadAction<Partial<HallOfFame>>) => {
      // Initialize hall_of_fame if it doesn't exist
      if (!state.data) {
        state.data = {};
      }

      if (!state.data.hall_of_fame) {
        state.data.hall_of_fame = { selectedBadges: [{}, {}, {}] };
      }

      // Update the hall_of_fame data with the provided payload
      state.data.hall_of_fame = {
        ...state.data.hall_of_fame,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayerStats.fulfilled, (state, action: PayloadAction<{ data: PlayerStats; success: boolean; }>) => {
        state.loading = false;
        state.data = action.payload.data;

        // Calculate hasData values for current and previous months
        if (state.data?.budget_distribution) {
          const { current_month, previous_month } = state.data.budget_distribution;

          state.data.budget_distribution.hasData = {
            bets: hasDataInBetWin(current_month.bets) || hasDataInBetWin(previous_month.bets),
            wins: hasDataInBetWin(current_month.wins) || hasDataInBetWin(previous_month.wins)
          };
        }

        state.lastFetched = new Date().toISOString();
      })
      .addCase(fetchPlayerStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Unknown error occurred';
      });
  }
});

// Export actions
export const {
  clearPlayerStats,
  updateHallOfFame,
} = playerStatsSlice.actions;

// Export selectors
export const selectPlayerStats = (state: { playerStats: PlayerStatsState; }) => state.playerStats.data;
export const selectPlayerStatsLoading = (state: { playerStats: PlayerStatsState; }) => state.playerStats.loading;
export const selectPlayerStatsError = (state: { playerStats: PlayerStatsState; }) => state.playerStats.error;
export const selectLastFetched = (state: { playerStats: PlayerStatsState; }) => state.playerStats.lastFetched;

// Helper selectors to access budget distribution directly
export const selectBudgetDistribution = (state: { playerStats: PlayerStatsState; }) =>
  state.playerStats.data?.budget_distribution;

export const selectHasData = (state: { playerStats: PlayerStatsState; }) =>
  state.playerStats.data?.budget_distribution?.hasData;

export default playerStatsSlice.reducer;
